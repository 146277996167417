
import { Component, Prop, Vue } from "vue-property-decorator"
import Icon from "../icons/Icon.vue"

@Component({
  components: {
    Icon,
  },
})
export default class StateChip extends Vue {
  @Prop({ required: true }) state!: string
  @Prop() readonly color?: string
  @Prop() readonly icon?: string
  @Prop({ default: "outline" }) readonly iconType!: string
}
