import Vue, { provide } from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import vuetify from "./plugins/vuetify"
import VueSimpleMarkdown from "vue-simple-markdown"
import "vue-simple-markdown/dist/vue-simple-markdown.css"
import "@fontsource/inter"
import "./components/globals"
import { DefaultApolloClient } from "@vue/apollo-composable"

// we import modules that need importing once

// this allows us to the prop decorator to infer prop types from type definition
// see https://github.com/kaorun343/vue-property-decorator#if-youd-like-to-set-type-property-of-each-prop-value-from-its-type-definition-you-can-use-reflect-metadata
import "reflect-metadata"
import "@/helpers/validators"

// Vee Validate
import { ValidationProvider, ValidationObserver } from "vee-validate"
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"))
Vue.use(VueSimpleMarkdown)

// Mixins
import { mixins } from "vue-class-component"

import AlerterMixin from "@/mixins/Alerter"
Vue.mixin(mixins(AlerterMixin))

import AuthorizerMixin from "@/mixins/Authorizer"
Vue.mixin(mixins(AuthorizerMixin))

import UtilsMixin from "@/mixins/Utils"
Vue.mixin(mixins(UtilsMixin))

// Add global props
import Routes from "./router/routes"
Vue.prototype.$routes = Routes

// Configure Vue
import { createProvider } from "./vue-apollo"
Vue.config.productionTip = false

const { apolloProvider, apolloClient } = createProvider()

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: apolloProvider,
  setup() {
    provide(DefaultApolloClient, apolloClient)
    provide("vuetify", vuetify.framework)
  },
  render: (h) => h(App),
  beforeCreate() {
    store.dispatch("auth/restoreSession")
  },
}).$mount("#app")

Vue.use(apolloProvider)

export { apolloProvider, apolloClient }
