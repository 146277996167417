import Vue from "vue"
import Vuex, { StoreOptions } from "vuex"
import { apply } from "@/store/apply"
import { alerts } from "@/store/alerts"
import { auth, AuthState } from "@/store/auth"
import { profile, ProfileState } from "@/store/profile"
import { missionLifecycle } from "@/store/missionlifecycle"
import { Namespaces } from "@/constants"
import { sessionHistory } from "@/store/routes"

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {},
  modules: {
    auth,
    apply,
    alerts,
    profile,
    missionLifecycle,
    sessionHistory,
  },
  strict: process.env.NODE_ENV !== "production",
  actions: {
    clearAll({ commit }) {
      // commit(`${Namespaces.Apply}/reset`)
      commit(`${Namespaces.Alerts}/reset`)
      commit(`${Namespaces.Profile}/reset`)
      commit(`${Namespaces.MissionLifecycle}/reset`)
      commit(`${Namespaces.SessionHistory}/reset`)
    },
  },
}

export default new Vuex.Store<RootState>(store)

export interface RootState {
  profile?: ProfileState
  auth?: AuthState
}
