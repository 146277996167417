
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class OutlinedSelect extends Vue {
  @Prop()
  readonly label?: string
  @Prop({ default: "black" }) readonly borderColor!: string
  @Prop({ default: "white" }) readonly backgroundColor!: string

  @Prop()
  readonly required?: boolean

  @Prop()
  readonly labelEndAdornment?: string

  get attrs() {
    const { label, prependIcon, ...attrs } = this.$attrs
    return attrs
  }
}
