import { render, staticRenderFns } from "./SecondaryButton.vue?vue&type=template&id=26eea5c3&scoped=true&"
import script from "./SecondaryButton.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SecondaryButton.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SecondaryButton.vue?vue&type=style&index=0&id=26eea5c3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26eea5c3",
  null
  
)

export default component.exports