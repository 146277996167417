
import { Component, Vue } from "vue-property-decorator"
import Login from "@/components/auth/Login/Login.vue"
import ForgotPassword from "@/components/auth/ForgotPassword.vue"

@Component({ components: { Login, ForgotPassword } })
export default class Auth extends Vue {
  page: "login" | "forgot-password" = "login"

  changePage(page: "login" | "forgot-password") {
    this.page = page
  }
}
