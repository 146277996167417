
import { Namespaces } from "@/constants"
import { Component, Vue, Emit, Watch } from "vue-property-decorator"
import { Action } from "vuex-class"
import { LoginMutation, LoginMutationMutation } from "@/gql"
import { FetchResult } from "apollo-link"
import { StartSessionPayload } from "@/store/auth"
import ContainerCard from "@/components/layout/CardContainer.vue"
import CarouselData from "./carousel_data.json"
import useRouter from "@/router/useRouter"

const namespace = Namespaces.Auth

@Component({
  components: {
    ContainerCard,
  },
})
export default class Login extends Vue {
  readonly LoginMutation = LoginMutation
  loading = false
  carouselData = CarouselData.feedBack
  carouselIndex = 0

  @Action("startSession", { namespace }) startSession!: (payload: StartSessionPayload) => void

  form: { [key: string]: string | null } = {
    email: null,
    password: null,
    mode: "password",
  }

  async authWithSS0() {
    if (!this.$msal.data.accessToken) {
      await this.$msal.signIn()
    }

    if (this.$msal.data.accessToken) {
      this.form.email = this.$msal.data.account.username
      this.form.password = this.$msal.data.accessToken

      this.loading = true
      const result = await this.mutate<LoginMutationMutation>({
        mutation: LoginMutation,
        variables: {
          email: this.normalize(this.form.email),
          password: this.form.password,
          mode: "sso",
        },
        done: () => {
          this.loading = false
        },
      })

      this.mutationDone(result)
    }
  }

  mutationDone(result: FetchResult<LoginMutationMutation>) {
    if (result.data) {
      if (result.data.login.user && result.data.login.token) {
        this.startSession({
          user: result.data.login.user,
          token: `Bearer ${result.data.login.token}`,
          apolloProvider: this.$apolloProvider,
        })
      } else if (result.data.login.error) {
        this.addMutationError(result.data.login.error)
      }
    }
  }

  @Watch("isLoggedIn")
  onLoginChange(): void {
    const { postSignInRedirectRoute, unsetPostSignInRedirectRoute, navigate } = useRouter()

    if (this.isLoggedIn) {
      const redirectRoute = postSignInRedirectRoute()

      if (redirectRoute) {
        unsetPostSignInRedirectRoute()
        return navigate(redirectRoute)
      }

      if (this.$route.name === this.$routes.Login) {
        return navigate("/")
      }
    }
  }

  normalize(input: string) {
    return typeof input === "string" ? input.trim().toLowerCase() : input
  }

  @Emit("change")
  changePage(page: string) {
    return page
  }

  handleCarouselPaginationClick(index: number) {
    if (
      (this.carouselIndex !== 0 && index < 0) ||
      (this.carouselIndex !== this.carouselData.length - 1 && index > 0)
    ) {
      this.carouselIndex += index
    }
  }
}
