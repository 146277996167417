import { Namespaces } from "@/constants"
import { Component, Vue } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { UserAdvancedFragmentFragment, PermissionEnum } from "@/gql"

export interface Authorizer {
  isLoggedIn: boolean
  currentUser?: UserAdvancedFragmentFragment
  can(...permission: string[]): boolean

  isConsultant(): boolean
  isCoreTeam(): boolean
  isFinanceTeam(): boolean
  isTalentTeam(): boolean
  isBizDevTeam(): boolean
  isAdmin(): boolean
}

const namespace = Namespaces.Auth

export function can(
  currentUser: UserAdvancedFragmentFragment | undefined,
  permissions: PermissionEnum[]
): boolean {
  if (!currentUser) return false
  else {
    // any permission included in user permissions
    return [PermissionEnum.Admin]
      .concat(permissions)
      .some((permission) => currentUser.permissions.map((p) => p.slug).includes(permission))
  }
}

export function isConsultant(currentUser: UserAdvancedFragmentFragment | undefined): boolean {
  return can(currentUser, [PermissionEnum.Foresighter])
}

export function isClient(currentUser: UserAdvancedFragmentFragment | undefined): boolean {
  return !!currentUser?.clientsAsAdmin?.length
}

export function isCoreTeam(currentUser: UserAdvancedFragmentFragment | undefined): boolean {
  return can(currentUser, [PermissionEnum.CoreTeam])
}

@Component
export default class AuthorizerMixin extends Vue implements Authorizer {
  @Getter("isLoggedIn", { namespace }) isLoggedIn!: boolean

  @Getter("getCurrentUser", { namespace })
  currentUser?: UserAdvancedFragmentFragment

  can(...permissions: PermissionEnum[]): boolean {
    return can(this.currentUser, permissions)
  }

  isConsultant(): boolean {
    return !!this.currentUser?.consultant
  }

  isCoreTeam(): boolean {
    return this.can(PermissionEnum.CoreTeam)
  }

  isFinanceTeam(): boolean {
    return this.can(PermissionEnum.FinanceTeam)
  }

  isAdmin(): boolean {
    return this.can(PermissionEnum.Admin)
  }

  isBizDevTeam(): boolean {
    return this.can(PermissionEnum.BizdevTeam)
  }

  hasAnyRole(...roles: string[]): boolean {
    if (!this.isLoggedIn || !this.currentUser) return false

    // any roles included in user active roles
    return roles.some((role) =>
      this.currentUser
        ? this.currentUser.roles
            .filter((r) => r.active)
            .map((p) => p.name)
            .includes(role)
        : false
    )
  }
}
