
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Draggable = require("@shopify/draggable")
import { Vue, Component, Prop, Provide } from "vue-property-decorator"
// This is a functional component
@Component
export default class MultiSortableList extends Vue {
  @Prop({ required: true }) readonly value!: Record<string, any[]>
  @Prop({ default: "draggable-item" }) readonly itemClass!: string
  @Prop({ default: "draggable-list" }) readonly containerClass!: string
  @Prop({ default: "draggable-container" }) readonly scrollableContainerClass!: string
  @Prop({ default: false }) readonly isSuccessful!: boolean

  @Provide("itemClass") iClass = this.itemClass
  @Provide("containerClass") cClass = this.containerClass

  boundaryContainers: HTMLElement[] | null = null
  allowedContainers: HTMLElement[] | null = null

  updateList(sourceKey: string, targetKey: string, oldIndex: number, newIndex: number) {
    const proxyValue = JSON.parse(JSON.stringify(this.value))

    const sourceList = proxyValue[sourceKey]
    const targetList = proxyValue[targetKey]

    if (sourceList && targetList) {
      const removedItem = sourceList.splice(oldIndex, 1)[0]

      targetList.splice(newIndex, 0, removedItem)

      this.$emit("input", proxyValue)
      this.$emit("sort", {
        item: removedItem,
        prevEvent: sourceKey,
        nextEvent: targetKey,
        oldIndex,
        newIndex,
      })
    }
  }

  render() {
    return this.$scopedSlots.default!({
      items: this.value,
    })
  }

  mounted() {
    const containers = document.querySelectorAll(`.${this.containerClass}`)
    const draggable = new Draggable.Sortable(containers, {
      draggable: `.${this.itemClass}`,
      mirror: {
        constrainDimensions: true,
      },
      delay: 100,
    })

    draggable.on("sortable:sort", (evt: any) => {
      const targetKey = evt.data.dragEvent.data.overContainer.dataset.containerKey
      const sourceKey = evt.data.dragEvent.data.sourceContainer.dataset.containerKey

      // get target container
      const targetContainer = document.querySelector(
        `[data-container-key="${targetKey}"]`
      ) as HTMLElement

      if (targetContainer) {
        const scrollableContainer = targetContainer.closest(
          `.${this.scrollableContainerClass}`
        ) as HTMLElement
        const scrollLeft = scrollableContainer.scrollLeft

        const offsetLeft = targetContainer.offsetLeft,
          parentWidth = targetContainer.offsetParent?.clientWidth

        if (offsetLeft / parentWidth! > 0.9) {
          scrollableContainer.scrollLeft += targetContainer.clientWidth
        } else if (offsetLeft < scrollLeft) {
          scrollableContainer.scrollLeft -= targetContainer.clientWidth
        }

        scrollableContainer.style.scrollBehavior = "smooth"
      }

      const sourceList = this.value[sourceKey]
      if (sourceKey === targetKey) {
        return
      }

      const draggedItem = sourceList.find((item: any, index: number) => {
        return index === evt.data.currentIndex
      })

      // remove red border while dragging to other containers if exists
      if (this.boundaryContainers) {
        this.boundaryContainers.forEach((container: any) => {
          container.classList.remove("draggable-list--not-allowed")
        })
      }

      // remove green border while dragging to other containers if exists
      if (this.allowedContainers) {
        this.allowedContainers.forEach((container: any) => {
          container.classList.remove("draggable-list--allowed")
        })
      }

      if (draggedItem && !draggedItem.nextStates.map((s: any) => s.state).includes(targetKey)) {
        evt.data.dragEvent.data.overContainer.classList.add("draggable-list--not-allowed")
        this.$emit("error")

        this.boundaryContainers = draggable.containers.filter((container: any) => {
          return container.classList.contains("draggable-list--not-allowed")
        })
        return
      }

      evt.data.dragEvent.data.overContainer.classList.add("draggable-list--allowed")

      this.allowedContainers = draggable.containers.filter((container: any) => {
        return container.classList.contains("draggable-list--allowed")
      })
    })

    draggable.on(
      "sortable:stop",
      ({ data: { oldContainer, newContainer, oldIndex, newIndex } }: any): void => {
        const sourceKey = oldContainer.dataset.containerKey
        const targetKey = newContainer.dataset.containerKey

        if (this.boundaryContainers) {
          this.boundaryContainers.forEach((container: any) => {
            container.classList.remove("draggable-list--not-allowed")
          })
        }

        if (this.allowedContainers) {
          this.allowedContainers.forEach((container: any) => {
            container.classList.remove("draggable-list--allowed")
          })
        }

        this.updateList(sourceKey, targetKey, oldIndex, newIndex)
      }
    )
  }
}
