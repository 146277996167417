var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',_vm._b({staticClass:"v-dialog",attrs:{"value":_vm.value,"max-width":_vm.$attrs.hasOwnProperty('large')
      ? 1200
      : _vm.$attrs.hasOwnProperty('small')
      ? 600
      : _vm.$attrs.hasOwnProperty('x-small')
      ? 500
      : _vm.$attrs.hasOwnProperty('medium')
      ? 700
      : 800,"persistent":_vm.persistent},on:{"input":_vm.onInput}},'v-dialog',_vm.$attrs,false),[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[(_vm.title || _vm.showClose)?_c('div',{staticClass:"card-title-wrap d-flex pa-5"},[_c('div',[(_vm.title)?_c('v-card-title',{staticClass:"headline pa-0 flex-grow-1 font-weight-bold"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subtitle)?_c('v-card-subtitle',{staticClass:"p-0 m-0 font-size-16"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()],1),(_vm.showClose)?_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.onInput(false)}}},[_c('v-icon',[_vm._v("la-times")])],1):_vm._e()],1):_vm._e(),_vm._t("header"),_c('div',{staticClass:"pa-4"},[_vm._t("default")],2),(_vm.$slots && _vm.$slots.actions)?_c('v-card-actions',{staticClass:"pa-5 pt-0 d-block text-center"},[_vm._t("actions")],2):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }