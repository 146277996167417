
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class Checkbox extends Vue {
  @Prop() readonly value!: any
  @Prop() readonly label!: string
  @Prop() readonly loading?: boolean
  @Prop({ default: false }) readonly reversed?: boolean
  @Prop({ default: false }) readonly primary?: boolean
  @Prop({ default: false }) readonly outlined?: boolean
  @Prop({ default: false }) readonly disabled?: boolean

  checked = false

  onChange() {
    if (!this.disabled && !this.loading) {
      this.$emit("input", this.checked)
      this.$emit("change", this.checked)
    }
  }

  @Watch("value", { immediate: true })
  onValueChanged() {
    this.checked = this.value
  }

  get inputId() {
    return this.getId()
  }

  getId() {
    return `app_checkbox_${this.generateRand(8)}`
  }
}
