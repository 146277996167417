
import { Component, Prop, Vue, Emit } from "vue-property-decorator"
import { DocumentNode } from "graphql"
import { validate } from "vee-validate"
import moment from "moment"
import SearchInput from "./SearchInput.vue"

export type ActionType = { [key: string]: (arg: any) => any }

export type FilterType = { [key: string]: any }

export type ListFilter = {
  field: string
  label: string
  position?: string
  filters: { [value: string]: string }
  default?: string
  hidden?: boolean
  queryFilter?: {
    query: DocumentNode
    itemValue: string
    per: number
    variables: { [key: string]: any }
    listKey: string
    multiple?: boolean
    maxlength?: number
    returnObject: boolean
  }
  multiple?: boolean
}

export type ToggleFilter = {
  field: string
  label: string
}

export type ValidatedType = "string" | "numeric" | "object"

export type SearchFilter = {
  field: string
  icon?: string
  label: string
  type: ValidatedType
  expanded: boolean
  clearable?: boolean
  searchOnChange?: boolean
  block?: boolean
}

export type DateFilter = {
  field: string
  label: string
}

export type ExportOptions = {
  query: DocumentNode
  queryKey: string
  variables: { [key: string]: any }
  headers: {
    text: string
    value: string
  }[]
}

export type NumericFilter = {
  field: string
  label: string
  comparison: "<" | ">" | "=" | "!="
}

enum NumericComparisonEnum {
  LessThan = "is less than",
  GreaterThan = "is greater than",
  EqualTo = "is equal to",
  NotEqualTo = "is not equal to",
}

@Component({
  components: {
    SearchInput,
  },
})
export default class FilterBar extends Vue {
  @Prop({ default: false })
  readonly outlined?: boolean

  @Prop({ required: true })
  readonly value?: FilterType

  @Prop()
  readonly actions?: ActionType

  @Prop()
  readonly listFilters!: ListFilter[]

  @Prop()
  readonly searchFilters?: SearchFilter[]

  @Prop()
  readonly dateFilters?: DateFilter[]

  @Prop()
  readonly toggleFilters?: ToggleFilter[]

  @Prop()
  readonly numericFilters?: NumericFilter[]

  @Prop({ default: false })
  readonly flat?: boolean

  @Prop({ default: false })
  readonly dense?: boolean

  @Prop()
  readonly reverse?: boolean

  @Prop({ default: true })
  readonly sticky?: boolean

  @Prop({ default: false })
  readonly isBordered?: boolean

  @Prop() readonly defaultFilter?: FilterType

  defaultForm: { [key: string]: any } = {
    searches: {},
    dates: [],
    lists: {},
    toggles: {},
  }

  form = { ...this.defaultForm }

  errors: {
    [key: string]: boolean
  } = {}

  filteredLabels: FilterType = {}

  showMenu: any[] = []

  searches: { [key: string]: string | undefined } = {}
  exportProgress = 0
  dateListGroup: number | null = null
  numericComparisonMapping = {
    "<": NumericComparisonEnum.LessThan,
    ">": NumericComparisonEnum.GreaterThan,
    "=": NumericComparisonEnum.EqualTo,
    "!=": NumericComparisonEnum.NotEqualTo,
  }

  showDatePicker = false

  get filtered() {
    return this.value || {}
  }

  getNumericFilterIcon(comparator: "<" | ">" | "=" | "!=") {
    const iconMap = {
      "<": "la-angle-left",
      ">": "la-angle-right",
      "=": "la-equals",
      "!=": "la-not-equal",
    }

    return iconMap[comparator]
  }

  mounted() {
    // Set default filters
    if (this.listFilters) {
      this.listFilters.forEach(async (f) => {
        if (f.default) await this.setFilter(f.field, f.default)
      })
    }
  }

  async created() {
    this.maybeSetDefaultFilter()

    if (Object.keys(this.$route.query).length) {
      // route has query filters
      const filter = this.decodeURL(this.$route.query)

      // TODO validate fields against passed filters via props
      // TODO validate values

      for (const key in filter) {
        // loop thru decoded url and set filter value
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
          await this.setFilter(key, filter[key], {
            queryStrings: false,
          })
        }
      }
    }
  }

  maybeSetDefaultFilter() {
    if (this.defaultFilter) {
      for (const key in this.defaultFilter) {
        if (Object.prototype.hasOwnProperty.call(this.defaultFilter, key)) {
          this.setFilter(key, this.defaultFilter[key])
        }
      }
    }
  }

  listFilterLabel(filter: ListFilter): string {
    if (this.filtered[filter.field] == undefined) return "All"
    else {
      let key = this.filtered[filter.field],
        count = 0

      if (this.filtered[filter.field].constructor.name == "Array") {
        // if array, get the first filtered key, get value and add count of array -1
        count = this.filtered[filter.field].length - 1
        key = this.filtered[filter.field][0]
      }

      let display = filter.filters[key],
        label = count ? `${display} +${count}` : display

      return display ? label : "Unknown"
    }
  }

  /**
   * Sets filter value
   *
   * @param key         Field to filter by
   * @param filterValue Value to filter
   *
   * @returns {FilterType}
   */
  async setFilter(
    key: string,
    filterValue: any,
    options?: { queryStrings: boolean }
  ): Promise<FilterType> {
    const value = { ...(this.value || {}) }
    value[key] = filterValue

    options = Object.assign(
      {
        queryStrings: true,
      },
      options
    )

    if (options.queryStrings) {
      this.addQueryStringsToRoute(value)
    }

    this.$emit("input", value)

    return new Promise((resolve) => {
      this.$watch("value", () => {
        resolve(value) // resolve when value is changed
      })
    })
  }

  decodeURL(data: FilterType): FilterType {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        try {
          data[key] = JSON.parse(data[key])
        } catch (error) {
          // couldn't parse
        }
      }
    }
    return data
  }

  addQueryStringsToRoute(value: FilterType) {
    let query: { [key: string]: string } = {}

    for (const valueKey in value) {
      // loop thru @value and stringify object values
      if (Object.prototype.hasOwnProperty.call(value, valueKey)) {
        const filterVal = value[valueKey],
          queryValue =
            typeof filterVal === "object" && filterVal !== null
              ? JSON.stringify(filterVal)
              : filterVal

        // set query value if not (null or undefined or <empty string>)
        if (queryValue) {
          query[valueKey] = queryValue
        }
      }
    }

    const currentFilter = this.decodeURL(this.$route.query)
    if (JSON.stringify(currentFilter) !== JSON.stringify(query)) {
      // this prevents a route from being pushed if selected filter is same as applied filter
      this.$router.push({
        query: { ...query },
      })
    }
  }

  setDateFilter(key: string, filterValue: any, label: string) {
    this.filteredLabels[key] = label
    this.setFilter(key, filterValue)
    this.showDatePicker = false
  }
  initiateCustomFilter() {
    this.showDatePicker = true
    this.form.dates = []
  }

  @Emit("action")
  onAction(action: string) {
    if (this.actions && typeof this.actions[action] === "function") {
      this.actions[action].call(this, undefined)
      return action
    }

    this.addError(`Unknown action: ${action}`)
  }

  async onSearch(field: string, type?: ValidatedType) {
    if (type && !(await this.validateValue(this.form.searches[field], type))) {
      this.$set(this.errors, field, true)
      return
    } else {
      this.errors[field] = false
    }

    // Set filter value
    this.setFilter(field, this.form.searches[field])

    // Push value into searches
    this.searches[field] = this.form.searches[field]

    // Hide filter
    this.showMenu[field as any] = false
  }

  async validateValue(value: any, type: ValidatedType) {
    const result = await validate(value, type)
    if (result.errors.length) {
      return false
    }

    return true
  }

  onToggle(field: string) {
    // Set filter value if checked else undefined
    this.setFilter(field, this.form.toggles[field] || undefined)
  }

  clearFilter(e: Event, field: string) {
    this.setFilter(field, undefined)
    this.searches[field] = undefined

    // Reset forms
    if (this.form.lists[field]) this.form.lists[field] = null
    else this.form.searches[field] = ""

    e.preventDefault()
    e.stopImmediatePropagation()

    this.searches = { ...this.searches }
  }

  clearDateFilter(e: Event, field: string) {
    this.showDatePicker = false
    this.dateListGroup = null // Clear date selection

    this.filteredLabels[field] = undefined // Clear labels
    this.clearFilter(e, field)
  }

  setListFilter(field: string, value: any) {
    this.showMenu[field as any] = false
    this.setFilter(field, value)
  }

  getDates(date: string): { [key: string]: string } {
    let start, end

    switch (date) {
      case "week":
        start = moment().startOf("week")
        end = moment().endOf("week")
        break

      case "month":
        start = moment().startOf("month")
        end = moment().endOf("month")
        break

      case "year":
        start = moment().startOf("year")
        end = moment().endOf("year")
        break

      case "custom": {
        const d1 = moment(this.form.dates[0]),
          d2 = moment(this.form.dates[1]).endOf("day")
        start = d1.isBefore(d2) ? d1 : d2
        end = start === d1 ? d2 : d1.endOf("day")
        break
      }
    }

    return {
      start: start ? start.toISOString() : "",
      end: end ? end.toISOString() : "",
    }
  }

  /**
   * Get value from object using string property accessor
   *
   * @param obj Object from which to get property
   * @param str String property accessor
   *
   * @returns {any}
   */
  ref(obj: { [key: string]: any }, str: string) {
    return str.split(".").reduce(function (o: { [key: string]: any }, x) {
      return o ? o[x] : ""
    }, obj)
  }

  onSearchChange(field: string, type: ValidatedType, searchOnChange?: boolean) {
    if (searchOnChange) {
      this.debouncedSearch(field, type)
    }
  }

  debouncedSearch(field: string, type?: ValidatedType) {
    this.debounceCall(() => {
      this.onSearch(field, type)
    }, 1000)
  }

  async export(options: ExportOptions) {
    this.addInfo("Exporting data... Please wait")

    // Fetch data
    const result = await this.$apollo.query({
      query: options.query,
      variables: options.variables,
    })

    if (result.data && result.data[options.queryKey]) {
      try {
        const items: { [key: string]: any }[] = result.data[options.queryKey].data
        const headers = options.headers

        let csv = items.map((row) => {
          // Get only values present in headers
          // We get an array of all 'field keys' then fetch the actual values as an array
          let filtered = headers
            .map((header) => header.value)
            .map((key) => {
              return `"${this.ref(row, key) || ""}"` // return value if present else string quotes
            })

          return filtered.join(",")
        })

        // Get header text as comma separated values then prepend to csv
        csv.unshift(headers.map((h) => h.text).join(","))

        const stringCSV = csv.join("\r\n") // Add new line to end of csv

        const blob = new Blob([stringCSV], { type: "text/csv;charset=utf-8;" }) // Create blob from string csv

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, `${options.queryKey}Export.csv`)
        } else {
          let link = document.createElement("a")
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob) //  Get the file url

            link.setAttribute("href", url)
            link.setAttribute("download", `${options.queryKey}Export.csv`)
            document.body.appendChild(link)

            link.click() // Trigger download
            document.body.removeChild(link)
          }
        }
      } catch (error) {
        this.addError("An error occured, please again")
        console.error(error)
      }
    }
  }

  reset() {
    this.form = { ...this.defaultForm }
    this.errors = {}
    this.filteredLabels = {}
    this.showMenu = []
    this.searches = {}
    this.form.searches = {}

    this.$router.replace({ query: {} })

    this.$emit("input", {})
  }
}
