
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Icon from "@/components/widgets/icons/Icon.vue"
import Placeholder from "@tiptap/extension-placeholder"

@Component({
  components: {
    EditorContent,
    BubbleMenu,
    Icon,
  },
})
export default class Tiptap extends Vue {
  editor: any = null
  @Prop({ default: "" }) readonly content!: string
  @Prop({ default: "" }) readonly value?: string
  @Prop({ default: "200px" }) readonly height!: string
  @Prop({ default: false }) readonly autofocus!: boolean
  @Prop() readonly placeholder?: string

  mounted() {
    this.editor = new Editor({
      content: (this.$refs.content as HTMLElement).innerHTML,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholder,
          emptyEditorClass: "is-editor-empty",
        }),
      ],
      autofocus: this.autofocus,
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML())
      },
    })
    ;((this.$refs.editor as Vue).$el as HTMLElement).addEventListener("keydown", this.handleKeydown)

    setTimeout(() => {
      this.setEditorHeight()
    }, 100)
  }

  setEditorHeight() {
    const proseMirror = this.$el.querySelector(".ProseMirror") as HTMLElement
    if (proseMirror) {
      proseMirror.style.minHeight = this.height
    }
  }

  @Watch("value")
  onValueChange() {
    // HTML
    const isSame = this.editor.getHTML() === this.value

    if (isSame) {
      return
    }

    this.editor.commands.setContent(this.value, false)
  }

  beforeDestroy() {
    this.editor.destroy()
    ;((this.$refs.editor as Vue).$el as HTMLElement).removeEventListener(
      "keydown",
      this.handleKeydown
    )
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      return this.editor.commands.insertContent("\t")
    }

    if (event.key === "Enter") {
      event.preventDefault()

      this.$emit("enter", this.editor.getHTML())
    }
  }
}
