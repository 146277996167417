
import { Component, Vue, Prop, Emit } from "vue-property-decorator"
import { BankAccountsListQuery } from "@/gql"

@Component
export default class BankAccountSelect extends Vue {
  readonly query = BankAccountsListQuery

  @Prop() value!: any

  @Emit("input")
  onInput(value: any) {
    return value
  }
}
