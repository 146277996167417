
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { DocumentNode } from "graphql"

@Component
export default class QuerySelect extends Vue {
  @Prop({ required: false, default: "" })
  readonly placeholder!: string

  @Prop({ required: true })
  readonly query!: DocumentNode

  @Prop({ required: true })
  readonly valueKey!: string

  @Prop({
    default: () => ({}),
  })
  readonly variables!: Record<string, any>

  @Prop()
  readonly searchable?: boolean

  @Prop({ default: true })
  readonly returnObject!: boolean

  @Prop({ default: "id" })
  readonly itemValue!: string

  @Prop({ default: "name" })
  readonly itemText?: string

  // @Prop()
  // readonly required?: boolean

  items: Array<any> = []

  search = ""
  // selected = null

  get loading() {
    return this.$apollo.loading
  }

  @Watch("variables", { deep: true })
  refresh() {
    this.getItems()
  }

  @Watch("search")
  async onSearchChange() {
    if (
      this.searchable &&
      this.search &&
      this.$refs.autocomplete &&
      !(this.$refs.autocomplete as any).hasDisplayedItems
    ) {
      await this.getItems({ search: this.search })
    }
  }

  performSearch(name: string) {
    // Without this check, it goes into an infinite loop
    if (name !== null && this.variables.name !== name && this.variables.name !== undefined)
      this.$emit("search", name)
  }

  async getItems(variables?: Record<string, any>) {
    const { data } = await this.$apollo.query({
      query: this.query,
      variables: Object.assign(this.variables, variables),
      fetchPolicy: "no-cache",
    })

    if (data) this.items = data[this.valueKey].data || data[this.valueKey]
  }

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, required, disabled, ...attrs } = this.$attrs

      return attrs
    } else return this.$attrs
  }

  created() {
    this.getItems()
  }
}
