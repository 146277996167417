
import { Component, Prop, Vue } from "vue-property-decorator"
import Icon from "../icons/Icon.vue"

@Component({
  components: {
    Icon,
  },
})
export default class SecondaryIconButton extends Vue {
  @Prop() readonly icon?: string
  @Prop() readonly vIcon?: string
  @Prop({ default: "black" }) readonly color?: string
  @Prop({ default: "32px" }) readonly size?: string
  @Prop({ default: "24px" }) readonly iconSize?: string
  @Prop({ default: true }) readonly outlined?: boolean
}
