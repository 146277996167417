var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary-button text-capitalize px-5",style:({
      float: _vm.$attrs.hasOwnProperty('right')
        ? 'right'
        : _vm.$attrs.hasOwnProperty('left')
        ? 'left'
        : 'none',
      fontWeight: '600',
      background: _setup.backgroundHandler(hover),
      color: _setup.textColorHandler(),
    }),attrs:{"color":_vm.theme.primaryColor,"min-height":"40px","min-width":"90px","elevation":"0"}},'v-btn',_vm.$attrs,false),_vm.$listeners),[(_vm.vIcon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.vIcon))]):_vm._e(),(_vm.icon)?_c(_setup.Icon,{staticClass:"mr-2",attrs:{"name":_vm.icon,"size":"24"}}):_vm._e(),_vm._t("default")],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }