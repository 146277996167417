import Vue from "vue"
import VueApollo from "vue-apollo"
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client"
import { SESSION_TOKEN_KEY, AppDomain } from "@/constants"
import router from "./router"
import store from "./store"
import { onError } from "apollo-link-error"
import { IntrospectionFragmentMatcher, InMemoryCache } from "apollo-cache-inmemory"
import introspectionQueryResultData from "../graphql.schema.json"

Vue.use(VueApollo)

const AUTH_TOKEN = SESSION_TOKEN_KEY

let httpEndpoint = ""
switch (location.hostname) {
  case "peralta-staging.netlify.app":
    httpEndpoint = "https://peralta-api-staging.herokuapp.com/graphql"
    break

  case "peralta.app":
  case "peralta.work":
  case "peralta.netlify.app":
    httpEndpoint = "https://peralta-api-prod.herokuapp.com/graphql"
    break

  default:
    httpEndpoint = "https://peralta-api-staging.herokuapp.com/graphql"
    break
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
  cache = new InMemoryCache({ fragmentMatcher })

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.GRAPHQL_ENDPOINT_WS || null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  cache: cache,

  // Override the way the Authorization header is set
  getAuth: () => {
    return localStorage && localStorage.getItem(SESSION_TOKEN_KEY)
  },

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, extensions }) => {
      if (extensions && extensions.code == "UNAUTHENTICATED") {
        store.dispatch("auth/endSession")

        const storeSession = [...store.getters["sessionHistory/getHistory"]]
        const lastSession = storeSession.pop()

        if ([AppDomain.Apply].includes(lastSession.domain)) {
          return
        }

        router.push("/login")
      }

      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        message
      )
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
    link: errorLink,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
  })

  return { apolloProvider, apolloClient }
}

// // Manually call this when user log in
// export async function onLogin(apolloClient, token) {
//   if (typeof localStorage !== "undefined" && token) {
//     localStorage.setItem(AUTH_TOKEN, token)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log("%cError on cache reset (login)", "color: orange;", e.message)
//   }
// }

// // Manually call this when user log out
// export async function onLogout(apolloClient) {
//   if (typeof localStorage !== "undefined") {
//     localStorage.removeItem(AUTH_TOKEN)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log("%cError on cache reset (logout)", "color: orange;", e.message)
//   }
// }
