var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"box-shadow outlined-card",attrs:{"headers":_vm.itemActions
      ? _vm.headers.concat([
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ])
      : _vm.headers,"items":_vm.customData || _vm.items,"items-per-page":5,"loading":_vm.$apollo.queries.queryKey.loading,"server-items-length":_vm.totalSize || undefined,"options":_vm.options,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [5, 10, 15, 25],
    firstIcon: 'la-step-backward',
    lastIcon: 'la-step-forward',
    nextIcon: 'la-angle-right',
    prevIcon: 'la-angle-left',
  },"multi-sort":""},on:{"click:row":_vm.onRowClick,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top"),(_vm.actions || _vm.listFilters || _vm.searchFilters || _vm.dateFilters || _vm.toggleFilters)?_c('FilterBar',{ref:"filterBar",staticClass:"mb-5",attrs:{"actions":_vm.tableActions,"listFilters":_vm.listFilters,"searchFilters":_vm.searchFilters,"dateFilters":_vm.dateFilters,"numericFilters":_vm.numericFilters,"toggleFilters":_vm.toggleFilters,"defaultFilter":_vm.defaultFilter},scopedSlots:_vm._u([{key:"append-filters",fn:function(){return [_vm._t("append-filters")]},proxy:true}],null,true),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e()]},proxy:true},_vm._l((_vm.headers.filter((h) => h.custom)),function(slot){return {key:`item.${slot.value}`,fn:function({ item }){return [_vm._t(`item.${slot.value}`,null,{"item":item})]}}}),(_vm.itemActions)?{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("la-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-1"},_vm._l((_vm.itemActions),function(action,key){return _c('v-list-item',{key:key,on:{"click":function($event){return _vm.onItemAction(key, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(key))])],1)}),1)],1)]}}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }