
import { UploadFileInput } from "@/gql"
import { Component, Prop, Vue, Emit } from "vue-property-decorator"

@Component
export default class UploadDialog extends Vue {
  @Prop({ required: true }) readonly name!: string // Document name, used for file upload and modal name
  @Prop({ required: true }) readonly value!: boolean
  @Prop() readonly folder!: string
  @Prop() readonly category!: string
  @Prop() readonly accept?: string

  uploadFile: UploadFileInput | null = null

  @Emit("upload")
  onUploadComplete() {
    return this.uploadFile
  }

  reset() {
    this.uploadFile = null
  }

  @Emit("input")
  onInput(value: any) {
    return value
  }
}
