import { Module } from "vuex"
import { RootState } from "../index"
import { application } from "./application"
import { ApplicantInput, ConsultantApplicationFragmentFragment } from "@/gql"

const namespaced = true

export const state: ApplyState = {
  application: undefined,
  applicant: undefined,
}

export const apply: Module<ApplyState, RootState> = {
  namespaced,
  state,
  getters: {
    ...application.getters,
  },
  actions: {
    ...application.actions,
  },
  mutations: {
    ...application.mutations,
  },
}

// types

export interface ApplyState {
  application?: ConsultantApplicationFragmentFragment
  applicant?: ApplicantInput
}
