
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Icon from "@/components/widgets/icons/Icon.vue"

@Component({
  components: {
    Icon,
  },
})
export default class SearchInput extends Vue {
  @Prop() readonly value!: string
  @Prop({ default: "Search" }) readonly placeholder?: string
  @Prop({ default: false }) readonly filled?: boolean

  form = {
    search: "",
  }

  mounted() {
    this.form.search = this.value
  }

  @Watch("value")
  onValuePropsChange() {
    this.form.search = this.value
  }

  onValueChange() {
    this.$emit("input", this.form.search)
    this.$emit("change", this.form.search)
  }

  clearInput = (e: Event) => {
    this.form.search = ""
    this.$emit("input", "")
    this.$emit("change", "")
    this.$emit("click:append", e)
  }

  get inputListeners() {
    return {
      ...this.$listeners,
      input: (event: Event) => {
        const target = event.target as HTMLInputElement
        this.$emit("input", target.value)
      },
    }
  }
}
