var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center"},[_vm._l((_vm.users.slice(0, _vm.AVATAR_GROUP_MAX_LENGTH)),function(user,i){return _c('UserAvatar',{key:i,class:{
      'ml-n2': i !== 0,
    },attrs:{"user":user,"size":_vm.size,"outline":_vm.outline,"color":_vm.color}})}),(_vm.users.length > _vm.AVATAR_GROUP_MAX_LENGTH)?[(_vm.label)?_c('h1',{staticClass:"title ml-2 font-grey",style:({
        fontSize: `${_vm.size / 2}px !important`,
      })},[_vm._v(" +"+_vm._s(_vm.users.length - _vm.AVATAR_GROUP_MAX_LENGTH)+" "+_vm._s(_vm.label)+" ")]):_c('v-avatar',_vm._b({staticClass:"ml-n1",style:({
        border: `${_vm.outline ? `1px solid var(--v-primary-base) !important` : ''}`,
      }),attrs:{"size":_vm.size,"color":_vm.outline ? `#FFF` : `var(--v-primaryGray-lighten2)`}},'v-avatar',_vm.$attrs,false),[_c('h1',{staticClass:"title",style:({
          fontSize: `${_vm.size / 2.5}px !important`,
        })},[_vm._v(" +"+_vm._s(_vm.users.length - _vm.AVATAR_GROUP_MAX_LENGTH)+" ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }