import Routes from "@/router/routes"

const useClientRoutes = (): any[] => {
  return [
    {
      path: "missions",
      component: { render: (h: any) => h("router-view") },
      children: [
        {
          path: "",
          name: Routes.Projects,
          component: () =>
            import(
              /* webpackChunkName: "client-projects" */ "../views/client/projects/Projects.vue"
            ),
        },
        {
          path: ":id",
          name: Routes.ClientProjectDetails,
          component: { render: (h: any) => h("router-view") },

          children: [
            {
              path: "",
              name: Routes.ClientProjectDetails,
              component: () =>
                import(
                  /* webpackChunkName: "client-project-details" */ "../views/missions/MissionDetail/MissionDetail.vue"
                ),
            },
            {
              path: "conversation",
              name: Routes.MissionDetailConversation,
              component: () =>
                import(
                  /* webpackChunkName: "client-project-details" */ "../views/missions/MissionDetail/MissionDetail.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "tasks",
      name: Routes.ClientTasks,
      component: () => import("../views/client/Tasks.vue"),
    },
    {
      path: "billing",
      name: Routes.ClientBilling,
      component: () => import("../views/client/Billing.vue"),
    },
    {
      path: "settings",
      name: Routes.Settings,
      component: () => import("../views/Settings.vue"),
    },
  ]
}

export default useClientRoutes
