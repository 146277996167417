import Vue from "vue"
import DatePicker from "@/components/widgets/input/DatePicker.vue"
import FileUpload from "@/components/widgets/input/FileUpload.vue"
import QuerySelect from "@/components/widgets/input/QuerySelect.vue"
import OutlinedTextField from "@/components/fields/OutlinedTextField.vue"
import OutlinedSelect from "@/components/fields/OutlinedSelect.vue"
import OutlinedTextarea from "@/components/fields/OutlinedTextarea.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import BlockDocument from "@/components/missions/BlockDocument.vue"
import * as path from "path"

// All components in the following directories are global
const dir = ["button", "common", "data", "typography"].join("|"),
  // eslint-disable-next-line no-useless-escape
  regex = `\.\/(${dir})`,
  // Context module of ./widgets and subdirectories
  requireComponent = require.context("./widgets", true, /\.vue$/)

requireComponent.keys().forEach((file) => {
  //  If component file path name matches dir regex
  if (new RegExp(regex).test(file)) {
    // Module
    const component = requireComponent(file)

    // Get component name from filename
    const componentName = path.basename(file, ".vue")

    // Register component
    Vue.component(componentName, component.default)
  }
})

Vue.component("DatePicker", DatePicker)
Vue.component("FileUpload", FileUpload)
Vue.component("QuerySelect", QuerySelect)
Vue.component("OutlinedTextField", OutlinedTextField)
Vue.component("OutlinedSelect", OutlinedSelect)
Vue.component("OutlinedTextarea", OutlinedTextarea)
Vue.component("FeedBackMessage", FeedBackMessage)
Vue.component("BlockDocument", BlockDocument)
