import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from ".."

const state: MissionLifecycleState = {
  currentSection: "",
  loading: false,
}

const mutations: MutationTree<MissionLifecycleState> = {
  setCurrentPage(state, payload: MissionLifecycleGotoPayload) {
    state.currentSection = payload.section
  },

  setLoading(state, payload: SetLoadingPayload) {
    state.loading = payload.loading
  },

  reset(state) {
    state.currentSection = ""
    state.loading = false
  },
}

const actions: ActionTree<MissionLifecycleState, RootState> = {
  /**
   * Jump to a section the mission detail page
   *
   * @param payload MissionLifecycleGotoPayload
   * @returns Promise<unknown>
   */
  goto({ commit }, payload: MissionLifecycleGotoPayload) {
    return new Promise((resolve) => {
      commit("setCurrentPage", payload)
      resolve(payload)
    })
  },

  setLoading({ commit }, payload: SetLoadingPayload) {
    commit("setLoading", payload)
  },
}

const getters: GetterTree<MissionLifecycleState, RootState> = {
  currentSection(state) {
    return state.currentSection
  },
}

export const missionLifecycle: Module<MissionLifecycleState, RootState> = {
  namespaced: true,
  state,
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
}

export interface SetLoadingPayload {
  loading: boolean
}

export interface MissionLifecycleState {
  currentSection: string
  loading: boolean
}

export interface MissionLifecycleGotoPayload {
  section: string
}
