import { CountryCode } from "libphonenumber-js"

export interface ICountry {
  en: string
  flag: string
  code: CountryCode
  dialCode: string
  mask: string
  currency: string
  symbol: string
  currencyCode: string
}

export interface ICurrency {
  en: string
  flag: string
  currency: string
  symbol: string
  currencyCode: string
}

export const COUNTRIES: ICountry[] = [
  {
    en: "Afghanistan",
    flag: "🇦🇫",
    code: "AF",
    dialCode: "+93",
    mask: "999 999 9999",
    currency: "Afghan afghani",
    symbol: "؋",
    currencyCode: "AFN",
  },
  {
    en: "Åland Islands",
    flag: "🇦🇽",
    code: "AX",
    dialCode: "+358",
    mask: "999 9999999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Albania",
    flag: "🇦🇱",
    code: "AL",
    dialCode: "+355",
    mask: "999 999 9999",
    currency: "Albanian lek",
    symbol: "Lek",
    currencyCode: "ALL",
  },
  {
    en: "Algeria",
    flag: "🇩🇿",
    code: "DZ",
    dialCode: "+213",
    mask: "9999 99 99 99",
    currency: "Algerian dinar",
    symbol: "د.ج",
    currencyCode: "DZD",
  },
  {
    en: "American Samoa",
    flag: "🇦🇸",
    code: "AS",
    dialCode: "+1684",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Andorra",
    flag: "🇦🇩",
    code: "AD",
    dialCode: "+376",
    mask: "999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Angola",
    flag: "🇦🇴",
    code: "AO",
    dialCode: "+244",
    mask: "999 999 999",
    currency: "Angolan kwanza",
    symbol: "Kz",
    currencyCode: "AOA",
  },
  {
    en: "Anguilla",
    flag: "🇦🇮",
    code: "AI",
    dialCode: "+1264",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Antigua and Barbuda",
    flag: "🇦🇬",
    code: "AG",
    dialCode: "+1268",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Argentina",
    flag: "🇦🇷",
    code: "AR",
    dialCode: "+54",
    mask: "999 99-9999-9999",
    currency: "Argentine peso",
    symbol: "$",
    currencyCode: "ARS",
  },
  {
    en: "Armenia",
    flag: "🇦🇲",
    code: "AM",
    dialCode: "+374",
    mask: "999 999999",
    currency: "Armenian dram",
    symbol: "֏",
    currencyCode: "AMD",
  },
  {
    en: "Aruba",
    flag: "🇦🇼",
    code: "AW",
    dialCode: "+297",
    mask: "999 9999",
    currency: "Aruban florin",
    symbol: "ƒ",
    currencyCode: "AWG",
  },
  {
    en: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dialCode: "+61",
    mask: "9999 999 999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Austria",
    flag: "🇦🇹",
    code: "AT",
    dialCode: "+43",
    mask: "9999 999999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Azerbaijan",
    flag: "🇦🇿",
    code: "AZ",
    dialCode: "+994",
    mask: "999 999 99 99",
    currency: "Azerbaijani manat",
    symbol: "₼",
    currencyCode: "AZN",
  },
  {
    en: "Bahamas",
    flag: "🇧🇸",
    code: "BS",
    dialCode: "+1242",
    mask: "(999) 999-999",
    currency: "Bahamian dollar",
    symbol: "$",
    currencyCode: "BSD",
  },
  {
    en: "Bahrain",
    flag: "🇧🇭",
    code: "BH",
    dialCode: "+973",
    mask: "9999 9999",
    currency: "Bahraini dinar",
    symbol: ".د.ب",
    currencyCode: "BHD",
  },
  {
    en: "Bangladesh",
    flag: "🇧🇩",
    code: "BD",
    dialCode: "+880",
    mask: "99999-999999",
    currency: "Bangladeshi taka",
    symbol: "৳",
    currencyCode: "BDT",
  },
  {
    en: "Barbados",
    flag: "🇧🇧",
    code: "BB",
    dialCode: "+1246",
    mask: "(999) 999-9999",
    currency: "Barbadian dollar",
    symbol: "$",
    currencyCode: "BBD",
  },
  {
    en: "Belarus",
    flag: "🇧🇾",
    code: "BY",
    dialCode: "+375",
    mask: "9 999 999-99-99",
    currency: "Belarusian ruble",
    symbol: "Br",
    currencyCode: "BYN",
  },
  {
    en: "Belgium",
    flag: "🇧🇪",
    code: "BE",
    dialCode: "+32",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Belize",
    flag: "🇧🇿",
    code: "BZ",
    dialCode: "+501",
    mask: "999-9999",
    currency: "Belize dollar",
    symbol: "$",
    currencyCode: "BZD",
  },
  {
    en: "Benin",
    flag: "🇧🇯",
    code: "BJ",
    dialCode: "+229",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Bermuda",
    flag: "🇧🇲",
    code: "BM",
    dialCode: "+1441",
    mask: "(999) 999-9999",
    currency: "Bermudian dollar",
    symbol: "$",
    currencyCode: "BMD",
  },
  {
    en: "Bhutan",
    flag: "🇧🇹",
    code: "BT",
    dialCode: "+975",
    mask: "99 99 99 99",
    currency: "Bhutanese ngultrum",
    symbol: "Nu.",
    currencyCode: "BTN",
  },
  {
    en: "Bolivia, Plurinational State of bolivia",
    flag: "🇧🇴",
    code: "BO",
    dialCode: "+591",
    mask: "99999999",
    currency: "Bolivian boliviano",
    symbol: "Bs.",
    currencyCode: "BOB",
  },
  {
    en: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    code: "BA",
    dialCode: "+387",
    mask: "999 999 999",
    currency: "Bosnia and Herzegovina convertible mark",
    symbol: "KM",
    currencyCode: "BAM",
  },
  {
    en: "Botswana",
    flag: "🇧🇼",
    code: "BW",
    dialCode: "+267",
    mask: "99 999 999",
    currency: "Botswana pula",
    symbol: "P",
    currencyCode: "BWP",
  },
  {
    en: "Brazil",
    flag: "🇧🇷",
    code: "BR",
    dialCode: "+55",
    mask: "(99) 99999 9999",
    currency: "Brazilian real",
    symbol: "R$",
    currencyCode: "BRL",
  },
  {
    en: "British Indian Ocean Territory",
    flag: "🇮🇴",
    code: "IO",
    dialCode: "+246",
    mask: "(999) 999-9999",
    currency: "British pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "Brunei Darussalam",
    flag: "🇧🇳",
    code: "BN",
    dialCode: "+673",
    mask: "999 9999",
    currency: "Brunei dollar",
    symbol: "$",
    currencyCode: "BND",
  },
  {
    en: "Bulgaria",
    flag: "🇧🇬",
    code: "BG",
    dialCode: "+359",
    mask: "999 999 999",
    currency: "Bulgarian lev",
    symbol: "лв",
    currencyCode: "BGN",
  },
  {
    en: "Burkina Faso",
    flag: "🇧🇫",
    code: "BF",
    dialCode: "+226",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Burundi",
    flag: "🇧🇮",
    code: "BI",
    dialCode: "+257",
    mask: "99 99 99 99",
    currency: "Burundian franc",
    symbol: "Fr",
    currencyCode: "BIF",
  },
  {
    en: "Cambodia",
    flag: "🇰🇭",
    code: "KH",
    dialCode: "+855",
    mask: "999 999 999",
    currency: "Cambodian riel",
    symbol: "៛",
    currencyCode: "KHR",
  },
  {
    en: "Cameroon",
    flag: "🇨🇲",
    code: "CM",
    dialCode: "+237",
    mask: "9 99 99 99 99",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Canada",
    flag: "🇨🇦",
    code: "CA",
    dialCode: "+1",
    mask: "(999) 999-9999",
    currency: "Canadian dollar",
    symbol: "$",
    currencyCode: "CAD",
  },
  {
    en: "Cape Verde",
    flag: "🇨🇻",
    code: "CV",
    dialCode: "+238",
    mask: "999 99 99",
    currency: "Cape Verdean escudo",
    symbol: "Esc",
    currencyCode: "CVE",
  },
  {
    en: "Cayman Islands",
    flag: "🇰🇾",
    code: "KY",
    dialCode: "+345",
    mask: "999 9999",
    currency: "Cayman Islands dollar",
    symbol: "$",
    currencyCode: "KYD",
  },
  {
    en: "Central African Republic",
    flag: "🇨🇫",
    code: "CF",
    dialCode: "+236",
    mask: "99 99 99 99",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Chad",
    flag: "🇹🇩",
    code: "TD",
    dialCode: "+235",
    mask: "99 99 99 99",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Chile",
    flag: "🇨🇱",
    code: "CL",
    dialCode: "+56",
    mask: "(9) 9999 9999",
    currency: "Chilean peso",
    symbol: "$",
    currencyCode: "CLP",
  },
  {
    en: "China",
    flag: "🇨🇳",
    code: "CN",
    dialCode: "+86",
    mask: "999 9999 9999",
    currency: "Chinese yuan",
    symbol: "¥",
    currencyCode: "CNY",
  },
  {
    en: "Christmas Island",
    flag: "🇨🇽",
    code: "CX",
    dialCode: "+61",
    mask: "9999 999 999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    code: "CC",
    dialCode: "+61",
    mask: "9999 999 999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Colombia",
    flag: "🇨🇴",
    code: "CO",
    dialCode: "+57",
    mask: "999 9999999",
    currency: "Colombian peso",
    symbol: "$",
    currencyCode: "COP",
  },
  {
    en: "Comoros",
    flag: "🇰🇲",
    code: "KM",
    dialCode: "+269",
    mask: "999 99 99",
    currency: "Comorian franc",
    symbol: "Fr",
    currencyCode: "KMF",
  },
  {
    en: "Congo",
    flag: "🇨🇬",
    code: "CG",
    dialCode: "+242",
    mask: "9999 999 999",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Congo, The Democratic Republic of the Congo",
    flag: "🇨🇩",
    code: "CD",
    dialCode: "+243",
    mask: "99 999 9999",
    currency: "Congolese franc",
    symbol: "Fr",
    currencyCode: "CDF",
  },
  {
    en: "Cook Islands",
    flag: "🇨🇰",
    code: "CK",
    dialCode: "+682",
    mask: "99 999",
    currency: "New Zealand dollar",
    symbol: "$",
    currencyCode: "NZD",
  },
  {
    en: "Costa Rica",
    flag: "🇨🇷",
    code: "CR",
    dialCode: "+506",
    mask: "9999 9999",
    currency: "Costa Rican colón",
    symbol: "₡",
    currencyCode: "CRC",
  },
  {
    en: "Cote d'Ivoire",
    flag: "🇨🇮",
    code: "CI",
    dialCode: "+225",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Croatia",
    flag: "🇭🇷",
    code: "HR",
    dialCode: "+385",
    mask: "999 999 9999",
    currency: "Croatian kuna",
    symbol: "kn",
    currencyCode: "HRK",
  },
  {
    en: "Cuba",
    flag: "🇨🇺",
    code: "CU",
    dialCode: "+53",
    mask: "99 9999999",
    currency: "Cuban peso",
    symbol: "$",
    currencyCode: "CUP",
  },
  {
    en: "Cyprus",
    flag: "🇨🇾",
    code: "CY",
    dialCode: "+357",
    mask: "99 999999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Czech Republic",
    flag: "🇨🇿",
    code: "CZ",
    dialCode: "+420",
    mask: "999 999 999",
    currency: "Czech koruna",
    symbol: "Kč",
    currencyCode: "CZK",
  },
  {
    en: "Denmark",
    flag: "🇩🇰",
    code: "DK",
    dialCode: "+45",
    mask: "99 99 99 99",
    currency: "Danish krone",
    symbol: "kr",
    currencyCode: "DKK",
  },
  {
    en: "Djibouti",
    flag: "🇩🇯",
    code: "DJ",
    dialCode: "+253",
    mask: "99 99 99 99",
    currency: "Djiboutian franc",
    symbol: "Fr",
    currencyCode: "DJF",
  },
  {
    en: "Dominica",
    flag: "🇩🇲",
    code: "DM",
    dialCode: "+1767",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Ecuador",
    flag: "🇪🇨",
    code: "EC",
    dialCode: "+593",
    mask: "999 999 9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Egypt",
    flag: "🇪🇬",
    code: "EG",
    dialCode: "+20",
    mask: "9999 999 9999",
    currency: "Egyptian pound",
    symbol: "£",
    currencyCode: "EGP",
  },
  {
    en: "El Salvador",
    flag: "🇸🇻",
    code: "SV",
    dialCode: "+503",
    mask: "9999 9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Equatorial Guinea",
    flag: "🇬🇶",
    code: "GQ",
    dialCode: "+240",
    mask: "999 999 999",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Eritrea",
    flag: "🇪🇷",
    code: "ER",
    dialCode: "+291",
    mask: "99 999 999",
    currency: "Eritrean nakfa",
    symbol: "Nfk",
    currencyCode: "ERN",
  },
  {
    en: "Estonia",
    flag: "🇪🇪",
    code: "EE",
    dialCode: "+372",
    mask: "9999 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Ethiopia",
    flag: "🇪🇹",
    code: "ET",
    dialCode: "+251",
    mask: "999 999 9999",
    currency: "Ethiopian birr",
    symbol: "Br",
    currencyCode: "ETB",
  },
  {
    en: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    code: "FK",
    dialCode: "+500",
    mask: "99999",
    currency: "Falkland Islands pound",
    symbol: "£",
    currencyCode: "FKP",
  },
  {
    en: "Faroe Islands",
    flag: "🇫🇴",
    code: "FO",
    dialCode: "+298",
    mask: "999999",
    currency: "Danish krone",
    symbol: "kr",
    currencyCode: "DKK",
  },
  {
    en: "Fiji",
    flag: "🇫🇯",
    code: "FJ",
    dialCode: "+679",
    mask: "999 9999",
    currency: "Fijian dollar",
    symbol: "$",
    currencyCode: "FJD",
  },
  {
    en: "Finland",
    flag: "🇫🇮",
    code: "FI",
    dialCode: "+358",
    mask: "999 9999999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "France",
    flag: "🇫🇷",
    code: "FR",
    dialCode: "+33",
    mask: "99 99 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "French Guiana",
    flag: "🇬🇫",
    code: "GF",
    dialCode: "+594",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "French Polynesia",
    flag: "🇵🇫",
    code: "PF",
    dialCode: "+689",
    mask: "99 99 99 99",
    currency: "CFP franc",
    symbol: "Fr",
    currencyCode: "XPF",
  },
  {
    en: "Gabon",
    flag: "🇬🇦",
    code: "GA",
    dialCode: "+241",
    mask: "99 99 99 99",
    currency: "Central African CFA franc",
    symbol: "Fr",
    currencyCode: "XAF",
  },
  {
    en: "Gambia",
    flag: "🇬🇲",
    code: "GM",
    dialCode: "+220",
    mask: "999 9999",
    currency: "Gambian dalasi",
    symbol: "D",
    currencyCode: "GMD",
  },
  {
    en: "Georgia",
    flag: "🇬🇪",
    code: "GE",
    dialCode: "+995",
    mask: "999 99 99 99",
    currency: "Georgian lari",
    symbol: "₾",
    currencyCode: "GEL",
  },
  {
    en: "Germany",
    flag: "🇩🇪",
    code: "DE",
    dialCode: "+49",
    mask: "99999 9999999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Ghana",
    flag: "🇬🇭",
    code: "GH",
    dialCode: "+233",
    mask: "999 999 9999",
    currency: "Ghanaian cedi",
    symbol: "₵",
    currencyCode: "GHS",
  },
  {
    en: "Gibraltar",
    flag: "🇬🇮",
    code: "GI",
    dialCode: "+350",
    mask: "99999999",
    currency: "Gibraltar pound",
    symbol: "£",
    currencyCode: "GIP",
  },
  {
    en: "Greece",
    flag: "🇬🇷",
    code: "GR",
    dialCode: "+30",
    mask: "999 999 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Greenland",
    flag: "🇬🇱",
    code: "GL",
    dialCode: "+299",
    mask: "99 99 99",
    currency: "Danish krone",
    symbol: "kr",
    currencyCode: "DKK",
  },
  {
    en: "Grenada",
    flag: "🇬🇩",
    code: "GD",
    dialCode: "+1473",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Guadeloupe",
    flag: "🇬🇵",
    code: "GP",
    dialCode: "+590",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Guam",
    flag: "🇬🇺",
    code: "GU",
    dialCode: "+1671",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Guatemala",
    flag: "🇬🇹",
    code: "GT",
    dialCode: "+502",
    mask: "9999 9999",
    currency: "Guatemalan quetzal",
    symbol: "Q",
    currencyCode: "GTQ",
  },
  {
    en: "Guernsey",
    flag: "🇬🇬",
    code: "GG",
    dialCode: "+44",
    mask: "99999 999999",
    currency: "British pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "Guinea",
    flag: "🇬🇳",
    code: "GN",
    dialCode: "+224",
    mask: "999 99 99 99",
    currency: "Guinean franc",
    symbol: "Fr",
    currencyCode: "GNF",
  },
  {
    en: "Guinea-Bissau",
    flag: "🇬🇼",
    code: "GW",
    dialCode: "+245",
    mask: "999 999 999",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Guyana",
    flag: "🇬🇾",
    code: "GY",
    dialCode: "+592",
    mask: "999 9999",
    currency: "Guyanese dollar",
    symbol: "$",
    currencyCode: "GYD",
  },
  {
    en: "Haiti",
    flag: "🇭🇹",
    code: "HT",
    dialCode: "+509",
    mask: "99 99 9999",
    currency: "Haitian gourde",
    symbol: "G",
    currencyCode: "HTG",
  },
  {
    en: "Honduras",
    flag: "🇭🇳",
    code: "HN",
    dialCode: "+504",
    mask: "9999-9999",
    currency: "Honduran lempira",
    symbol: "L",
    currencyCode: "HNL",
  },
  {
    en: "Hong Kong",
    flag: "🇭🇰",
    code: "HK",
    dialCode: "+852",
    mask: "9999 9999",
    currency: "Hong Kong dollar",
    symbol: "$",
    currencyCode: "HKD",
  },
  {
    en: "Hungary",
    flag: "🇭🇺",
    code: "HU",
    dialCode: "+36",
    mask: "(99) 999 9999",
    currency: "Hungarian forint",
    symbol: "Ft",
    currencyCode: "HUF",
  },
  {
    en: "Iceland",
    flag: "🇮🇸",
    code: "IS",
    dialCode: "+354",
    mask: "999 9999",
    currency: "Icelandic króna",
    symbol: "kr",
    currencyCode: "ISK",
  },
  {
    en: "India",
    flag: "🇮🇳",
    code: "IN",
    dialCode: "+91",
    mask: "99999 99999",
    currency: "Indian rupee",
    symbol: "₹",
    currencyCode: "INR",
  },
  {
    en: "Indonesia",
    flag: "🇮🇩",
    code: "ID",
    dialCode: "+62",
    mask: "9999-999-999",
    currency: "Indonesian rupiah",
    symbol: "Rp",
    currencyCode: "IDR",
  },
  {
    en: "Iran, Islamic Republic of Persian Gulf",
    flag: "🇮🇷",
    code: "IR",
    dialCode: "+98",
    mask: "9999 999 9999",
    currency: "Iranian rial",
    symbol: "﷼",
    currencyCode: "IRR",
  },
  {
    en: "Iraq",
    flag: "🇮🇶",
    code: "IQ",
    dialCode: "+964",
    mask: "9999 999 9999",
    currency: "Iraqi dinar",
    symbol: "ع.د",
    currencyCode: "IQD",
  },
  {
    en: "Ireland",
    flag: "🇮🇪",
    code: "IE",
    dialCode: "+353",
    mask: "999 999 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Isle of Man",
    flag: "🇮🇲",
    code: "IM",
    dialCode: "+44",
    mask: "99999 999999",
    currency: "British pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "Israel",
    flag: "🇮🇱",
    code: "IL",
    dialCode: "+972",
    mask: "999-999-9999",
    currency: "Israeli new shekel",
    symbol: "₪",
    currencyCode: "ILS",
  },
  {
    en: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dialCode: "+39",
    mask: "999 999 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Jamaica",
    flag: "🇯🇲",
    code: "JM",
    dialCode: "+1876",
    mask: "(999) 999-9999",
    currency: "Jamaican dollar",
    symbol: "$",
    currencyCode: "JMD",
  },
  {
    en: "Japan",
    flag: "🇯🇵",
    code: "JP",
    dialCode: "+81",
    mask: "999-9999-9999",
    currency: "Japanese yen",
    symbol: "¥",
    currencyCode: "JPY",
  },
  {
    en: "Jersey",
    flag: "🇯🇪",
    code: "JE",
    dialCode: "+44",
    mask: "99999 999999",
    currency: "British pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "Jordan",
    flag: "🇯🇴",
    code: "JO",
    dialCode: "+962",
    mask: "99 9999 9999",
    currency: "Jordanian dinar",
    symbol: "د.ا",
    currencyCode: "JOD",
  },
  {
    en: "Kazakhstan",
    flag: "🇰🇿",
    code: "KZ",
    dialCode: "+7",
    mask: "9 (999) 999 9999",
    currency: "Kazakhstani tenge",
    symbol: "лв",
    currencyCode: "KZT",
  },
  {
    en: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dialCode: "+254",
    mask: "9999 999999",
    currency: "Kenyan shilling",
    symbol: "Sh",
    currencyCode: "KES",
  },
  {
    en: "Kiribati",
    flag: "🇰🇮",
    code: "KI",
    dialCode: "+686",
    mask: "99999999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Korea, Democratic People's Republic of Korea",
    flag: "🇰🇵",
    code: "KP",
    dialCode: "+850",
    mask: "(9) 99999",
    currency: "North Korean won",
    symbol: "₩",
    currencyCode: "KPW",
  },
  {
    en: "Korea, Republic of South Korea",
    flag: "🇰🇷",
    code: "KR",
    dialCode: "+82",
    mask: "999-9999-9999",
    currency: "South Korean won",
    symbol: "₩",
    currencyCode: "KRW",
  },
  {
    en: "Kosovo",
    flag: "🇽🇰",
    code: "XK",
    dialCode: "+383",
    mask: "999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Kuwait",
    flag: "🇰🇼",
    code: "KW",
    dialCode: "+965",
    mask: "999 99999",
    currency: "Kuwaiti dinar",
    symbol: "د.ك",
    currencyCode: "KWD",
  },
  {
    en: "Kyrgyzstan",
    flag: "🇰🇬",
    code: "KG",
    dialCode: "+996",
    mask: "9999 999 999",
    currency: "kyrgystani Som",
    symbol: "Лв",
    currencyCode: "KGS",
  },
  {
    en: "Laos",
    flag: "🇱🇦",
    code: "LA",
    dialCode: "+856",
    mask: "999 99 999 999",
    currency: "Lao kip",
    symbol: "₭",
    currencyCode: "LAK",
  },
  {
    en: "Latvia",
    flag: "🇱🇻",
    code: "LV",
    dialCode: "+371",
    mask: "99 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Lebanon",
    flag: "🇱🇧",
    code: "LB",
    dialCode: "+961",
    mask: "99 999 999",
    currency: "Lebanese pound",
    symbol: "ل.ل",
    currencyCode: "LBP",
  },
  {
    en: "Lesotho",
    flag: "🇱🇸",
    code: "LS",
    dialCode: "+266",
    mask: "9999 9999",
    currency: "Lesotho loti",
    symbol: "L",
    currencyCode: "LSL",
  },
  {
    en: "Liberia",
    flag: "🇱🇷",
    code: "LR",
    dialCode: "+231",
    mask: "999 999 9999",
    currency: "Liberian dollar",
    symbol: "$",
    currencyCode: "LRD",
  },
  {
    en: "Libyan Arab Jamahiriya",
    flag: "🇱🇾",
    code: "LY",
    dialCode: "+218",
    mask: "999-9999999",
    currency: "Libyan dinar",
    symbol: "ل.د",
    currencyCode: "LYD",
  },
  {
    en: "Liechtenstein",
    flag: "🇱🇮",
    code: "LI",
    dialCode: "+423",
    mask: "999 999 999",
    currency: "Swiss franc",
    symbol: "Fr",
    currencyCode: "CHF",
  },
  {
    en: "Lithuania",
    flag: "🇱🇹",
    code: "LT",
    dialCode: "+370",
    mask: "(9-999) 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Luxembourg",
    flag: "🇱🇺",
    code: "LU",
    dialCode: "+352",
    mask: "999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Macao",
    flag: "🇲🇴",
    code: "MO",
    dialCode: "+853",
    mask: "9999 9999",
    currency: "Macanese pataca",
    symbol: "P",
    currencyCode: "MOP",
  },
  {
    en: "Macedonia",
    flag: "🇲🇰",
    code: "MK",
    dialCode: "+389",
    mask: "999 999 999",
    currency: "Macedonian denar",
    symbol: "ден",
    currencyCode: "MKD",
  },
  {
    en: "Madagascar",
    flag: "🇲🇬",
    code: "MG",
    dialCode: "+261",
    mask: "999 99 999 99",
    currency: "Malagasy ariary",
    symbol: "Ar",
    currencyCode: "MGA",
  },
  {
    en: "Malawi",
    flag: "🇲🇼",
    code: "MW",
    dialCode: "+265",
    mask: "9999 99 99 99",
    currency: "Malawian kwacha",
    symbol: "MK",
    currencyCode: "MWK",
  },
  {
    en: "Malaysia",
    flag: "🇲🇾",
    code: "MY",
    dialCode: "+60",
    mask: "999-999 9999",
    currency: "Malaysian ringgit",
    symbol: "RM",
    currencyCode: "MYR",
  },
  {
    en: "Maldives",
    flag: "🇲🇻",
    code: "MV",
    dialCode: "+960",
    mask: "999-9999",
    currency: "Maldivian rufiyaa",
    symbol: ".ރ",
    currencyCode: "MVR",
  },
  {
    en: "Mali",
    flag: "🇲🇱",
    code: "ML",
    dialCode: "+223",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Malta",
    flag: "🇲🇹",
    code: "MT",
    dialCode: "+356",
    mask: "9999 9999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Marshall Islands",
    flag: "🇲🇭",
    code: "MH",
    dialCode: "+692",
    mask: "999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Martinique",
    flag: "🇲🇶",
    code: "MQ",
    dialCode: "+596",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Mauritania",
    flag: "🇲🇷",
    code: "MR",
    dialCode: "+222",
    mask: "99 99 99 99",
    currency: "Mauritanian ouguiya",
    symbol: "UM",
    currencyCode: "MRO",
  },
  {
    en: "Mauritius",
    flag: "🇲🇺",
    code: "MU",
    dialCode: "+230",
    mask: "9999 9999",
    currency: "Mauritian rupee",
    symbol: "₨",
    currencyCode: "MUR",
  },
  {
    en: "Mayotte",
    flag: "🇾🇹",
    code: "YT",
    dialCode: "+262",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Mexico",
    flag: "🇲🇽",
    code: "MX",
    dialCode: "+52",
    mask: "999 999 999 9999",
    currency: "Mexican peso",
    symbol: "$",
    currencyCode: "MXN",
  },
  {
    en: "Micronesia, Federated States of Micronesia",
    flag: "🇫🇲",
    code: "FM",
    dialCode: "+691",
    mask: "999 9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Moldova",
    flag: "🇲🇩",
    code: "MD",
    dialCode: "+373",
    mask: "9999 99 999",
    currency: "Moldovan leu",
    symbol: "L",
    currencyCode: "MDL",
  },
  {
    en: "Monaco",
    flag: "🇲🇨",
    code: "MC",
    dialCode: "+377",
    mask: "99 99 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Mongolia",
    flag: "🇲🇳",
    code: "MN",
    dialCode: "+976",
    mask: "9999 9999",
    currency: "Mongolian tögrög",
    symbol: "₮",
    currencyCode: "MNT",
  },
  {
    en: "Montenegro",
    flag: "🇲🇪",
    code: "ME",
    dialCode: "+382",
    mask: "999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Montserrat",
    flag: "🇲🇸",
    code: "MS",
    dialCode: "+1664",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Morocco",
    flag: "🇲🇦",
    code: "MA",
    dialCode: "+212",
    mask: "9999-999999",
    currency: "Moroccan dirham",
    symbol: "د.م.",
    currencyCode: "MAD",
  },
  {
    en: "Mozambique",
    flag: "🇲🇿",
    code: "MZ",
    dialCode: "+258",
    mask: "99 999 9999",
    currency: "Mozambican metical",
    symbol: "MT",
    currencyCode: "MZN",
  },
  {
    en: "Myanmar",
    flag: "🇲🇲",
    code: "MM",
    dialCode: "+95",
    mask: "99 999 9999",
    currency: "Burmese kyat",
    symbol: "Ks",
    currencyCode: "MMK",
  },
  {
    en: "Namibia",
    flag: "🇳🇦",
    code: "NA",
    dialCode: "+264",
    mask: "999 999 99999",
    currency: "Namibian dollar",
    symbol: "$",
    currencyCode: "NAD",
  },
  {
    en: "Nauru",
    flag: "🇳🇷",
    code: "NR",
    dialCode: "+674",
    mask: "999 9999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Nepal",
    flag: "🇳🇵",
    code: "NP",
    dialCode: "+977",
    mask: "999-9999999",
    currency: "Nepalese rupee",
    symbol: "₨",
    currencyCode: "NPR",
  },
  {
    en: "Netherlands",
    flag: "🇳🇱",
    code: "NL",
    dialCode: "+31",
    mask: "99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "New Caledonia",
    flag: "🇳🇨",
    code: "NC",
    dialCode: "+687",
    mask: "99.99.99",
    currency: "CFP franc",
    symbol: "₣",
    currencyCode: "XPF",
  },
  {
    en: "New Zealand",
    flag: "🇳🇿",
    code: "NZ",
    dialCode: "+64",
    mask: "999 999 9999",
    currency: "New Zealand dollar",
    symbol: "$",
    currencyCode: "NZD",
  },
  {
    en: "Nicaragua",
    flag: "🇳🇮",
    code: "NI",
    dialCode: "+505",
    mask: "9999 9999",
    currency: "Nicaraguan córdoba",
    symbol: "C$",
    currencyCode: "NIO",
  },
  {
    en: "Niger",
    flag: "🇳🇪",
    code: "NE",
    dialCode: "+227",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Nigeria",
    flag: "🇳🇬",
    code: "NG",
    dialCode: "+234",
    mask: "9999 999 9999",
    currency: "Nigerian naira",
    symbol: "₦",
    currencyCode: "NGN",
  },
  {
    en: "Niue",
    flag: "🇳🇺",
    code: "NU",
    dialCode: "+683",
    mask: "999 9999",
    currency: "New Zealand dollar",
    symbol: "$",
    currencyCode: "NZD",
  },
  {
    en: "Norfolk Island",
    flag: "🇳🇫",
    code: "NF",
    dialCode: "+672",
    mask: "9 99999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Northern Mariana Islands",
    flag: "🇲🇵",
    code: "MP",
    dialCode: "+1670",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Norway",
    flag: "🇳🇴",
    code: "NO",
    dialCode: "+47",
    mask: "999 99 999",
    currency: "Norwegian krone",
    symbol: "kr",
    currencyCode: "NOK",
  },
  {
    en: "Oman",
    flag: "🇴🇲",
    code: "OM",
    dialCode: "+968",
    mask: "9999 9999",
    currency: "Omani rial",
    symbol: "ر.ع.",
    currencyCode: "OMR",
  },
  {
    en: "Pakistan",
    flag: "🇵🇰",
    code: "PK",
    dialCode: "+92",
    mask: "9999 9999999",
    currency: "Pakistani rupee",
    symbol: "₨",
    currencyCode: "PKR",
  },
  {
    en: "Palau",
    flag: "🇵🇼",
    code: "PW",
    dialCode: "+680",
    mask: "999 9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Palestinian Territory, Occupied",
    flag: "🇵🇸",
    code: "PS",
    dialCode: "+970",
    mask: "9999 999 999",
    currency: "Israeli new shekel",
    symbol: "₪",
    currencyCode: "ILS",
  },
  {
    en: "Panama",
    flag: "🇵🇦",
    code: "PA",
    dialCode: "+507",
    mask: "9999-9999",
    currency: "Panamanian balboa",
    symbol: "B/.",
    currencyCode: "PAB",
  },
  {
    en: "Papua New Guinea",
    flag: "🇵🇬",
    code: "PG",
    dialCode: "+675",
    mask: "9999 9999",
    currency: "Papua New Guinean kina",
    symbol: "K",
    currencyCode: "PGK",
  },
  {
    en: "Paraguay",
    flag: "🇵🇾",
    code: "PY",
    dialCode: "+595",
    mask: "9999 999999",
    currency: "Paraguayan guaraní",
    symbol: "₲",
    currencyCode: "PYG",
  },
  {
    en: "Peru",
    flag: "🇵🇪",
    code: "PE",
    dialCode: "+51",
    mask: "999 999 999",
    currency: "Peruvian sol",
    symbol: "S/.",
    currencyCode: "PEN",
  },
  {
    en: "Philippines",
    flag: "🇵🇭",
    code: "PH",
    dialCode: "+63",
    mask: "9999 999 9999",
    currency: "Philippine peso",
    symbol: "₱",
    currencyCode: "PHP",
  },
  {
    en: "Poland",
    flag: "🇵🇱",
    code: "PL",
    dialCode: "+48",
    mask: "999 999 999",
    currency: "Polish złoty",
    symbol: "zł",
    currencyCode: "PLN",
  },
  {
    en: "Portugal",
    flag: "🇵🇹",
    code: "PT",
    dialCode: "+351",
    mask: "999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Puerto Rico",
    flag: "🇵🇷",
    code: "PR",
    dialCode: "+1939",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Qatar",
    flag: "🇶🇦",
    code: "QA",
    dialCode: "+974",
    mask: "9999 9999",
    currency: "Qatari riyal",
    symbol: "ر.ق",
    currencyCode: "QAR",
  },
  {
    en: "Romania",
    flag: "🇷🇴",
    code: "RO",
    dialCode: "+40",
    mask: "9999 999 999",
    currency: "Romanian leu",
    symbol: "lei",
    currencyCode: "RON",
  },
  {
    en: "Russia",
    flag: "🇷🇺",
    code: "RU",
    dialCode: "+7",
    mask: "9 (999) 999-99-99",
    currency: "Russian ruble",
    symbol: "₽",
    currencyCode: "RUB",
  },
  {
    en: "Rwanda",
    flag: "🇷🇼",
    code: "RW",
    dialCode: "+250",
    mask: "9999 999 999",
    currency: "Rwandan franc",
    symbol: "Fr",
    currencyCode: "RWF",
  },
  {
    en: "Saint Barthelemy",
    flag: "🇧🇱",
    code: "BL",
    dialCode: "+590",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "🇸🇭",
    code: "SH",
    dialCode: "+290",
    mask: "99999",
    currency: "Saint Helena pound",
    symbol: "£",
    currencyCode: "SHP",
  },
  {
    en: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    code: "KN",
    dialCode: "+1869",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Saint Lucia",
    flag: "🇱🇨",
    code: "LC",
    dialCode: "+1758",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Saint Martin",
    flag: "🇲🇫",
    code: "MF",
    dialCode: "+590",
    mask: "9999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    code: "PM",
    dialCode: "+508",
    mask: "999 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "VC",
    dialCode: "+1784",
    mask: "(999) 999-9999",
    currency: "East Caribbean dollar",
    symbol: "$",
    currencyCode: "XCD",
  },
  {
    en: "Samoa",
    flag: "🇼🇸",
    code: "WS",
    dialCode: "+685",
    mask: "99 99999",
    currency: "Samoan tālā",
    symbol: "T",
    currencyCode: "WST",
  },
  {
    en: "San Marino",
    flag: "🇸🇲",
    code: "SM",
    dialCode: "+378",
    mask: "99 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Sao Tome and Principe",
    flag: "🇸🇹",
    code: "ST",
    dialCode: "+239",
    mask: "999 9999",
    currency: "São Tomé and Príncipe dobra",
    symbol: "Db",
    currencyCode: "STN",
  },
  {
    en: "Saudi Arabia",
    flag: "🇸🇦",
    code: "SA",
    dialCode: "+966",
    mask: "999 999 9999",
    currency: "Saudi riyal",
    symbol: "ر.س",
    currencyCode: "SAR",
  },
  {
    en: "Senegal",
    flag: "🇸🇳",
    code: "SN",
    dialCode: "+221",
    mask: "99 999 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Serbia",
    flag: "🇷🇸",
    code: "RS",
    dialCode: "+381",
    mask: "999 9999999",
    currency: "Serbian dinar",
    symbol: "дин.",
    currencyCode: "RSD",
  },
  {
    en: "Seychelles",
    flag: "🇸🇨",
    code: "SC",
    dialCode: "+248",
    mask: "9 999 999",
    currency: "Seychellois rupee",
    symbol: "₨",
    currencyCode: "SCR",
  },
  {
    en: "Sierra Leone",
    flag: "🇸🇱",
    code: "SL",
    dialCode: "+232",
    mask: "(999) 999999",
    currency: "Sierra Leonean leone",
    symbol: "Le",
    currencyCode: "SLL",
  },
  {
    en: "Singapore",
    flag: "🇸🇬",
    code: "SG",
    dialCode: "+65",
    mask: "9999 9999",
    currency: "Singapore dollar",
    symbol: "$",
    currencyCode: "SGD",
  },
  {
    en: "Slovakia",
    flag: "🇸🇰",
    code: "SK",
    dialCode: "+421",
    mask: "9999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Slovenia",
    flag: "🇸🇮",
    code: "SI",
    dialCode: "+386",
    mask: "999 999 999",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Solomon Islands",
    flag: "🇸🇧",
    code: "SB",
    dialCode: "+677",
    mask: "99 99999",
    currency: "Solomon Islands dollar",
    symbol: "$",
    currencyCode: "SBD",
  },
  {
    en: "Somalia",
    flag: "🇸🇴",
    code: "SO",
    dialCode: "+252",
    mask: "9 9999999",
    currency: "Somali shilling",
    symbol: "Sh",
    currencyCode: "SOS",
  },
  {
    en: "South Africa",
    flag: "🇿🇦",
    code: "ZA",
    dialCode: "+27",
    mask: "999 999 9999",
    currency: "South African rand",
    symbol: "R",
    currencyCode: "ZAR",
  },
  {
    en: "South Sudan",
    flag: "🇸🇸",
    code: "SS",
    dialCode: "+211",
    mask: "9999 999 999",
    currency: "South Sudanese pound",
    symbol: "£",
    currencyCode: "SSP",
  },
  {
    en: "Spain",
    flag: "🇪🇸",
    code: "ES",
    dialCode: "+34",
    mask: "999 99 99 99",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Sri Lanka",
    flag: "🇱🇰",
    code: "LK",
    dialCode: "+94",
    mask: "999 999 9999",
    currency: "Sri Lankan rupee",
    symbol: "₨",
    currencyCode: "LKR",
  },
  {
    en: "Sudan",
    flag: "🇸🇩",
    code: "SD",
    dialCode: "+249",
    mask: "999 999 9999",
    currency: "Sudanese pound",
    symbol: "£",
    currencyCode: "SDG",
  },
  {
    en: "Suriname",
    flag: "🇸🇷",
    code: "SR",
    dialCode: "+597",
    mask: "999-9999",
    currency: "Surinamese dollar",
    symbol: "$",
    currencyCode: "SRD",
  },
  {
    en: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    code: "SJ",
    dialCode: "+47",
    mask: "999 99 999",
    currency: "Norwegian krone",
    symbol: "kr",
    currencyCode: "NOK",
  },
  {
    en: "Swaziland",
    flag: "🇸🇿",
    code: "SZ",
    dialCode: "+268",
    mask: "9999 9999",
    currency: "Swazi lilangeni",
    symbol: "L",
    currencyCode: "SZL",
  },
  {
    en: "Sweden",
    flag: "🇸🇪",
    code: "SE",
    dialCode: "+46",
    mask: "999-999 99 99",
    currency: "Swedish krona",
    symbol: "kr",
    currencyCode: "SEK",
  },
  {
    en: "Switzerland",
    flag: "🇨🇭",
    code: "CH",
    dialCode: "+41",
    mask: "999 999 99 99",
    currency: "Swiss franc",
    symbol: "Fr",
    currencyCode: "CHF",
  },
  {
    en: "Syrian Arab Republic",
    flag: "🇸🇾",
    code: "SY",
    dialCode: "+963",
    mask: "9999 999 999",
    currency: "Syrian pound",
    symbol: "£",
    currencyCode: "SYP",
  },
  {
    en: "Taiwan",
    flag: "🇹🇼",
    code: "TW",
    dialCode: "+886",
    mask: "9999 999 999",
    currency: "New Taiwan dollar",
    symbol: "$",
    currencyCode: "TWD",
  },
  {
    en: "Tajikistan",
    flag: "🇹🇯",
    code: "TJ",
    dialCode: "+992",
    mask: "999 99 9999",
    currency: "Tajikistani somoni",
    symbol: "ЅМ",
    currencyCode: "TJS",
  },
  {
    en: "Tanzania, United Republic of Tanzania",
    flag: "🇹🇿",
    code: "TZ",
    dialCode: "+255",
    mask: "9999 999 999",
    currency: "Tanzanian shilling",
    symbol: "Sh",
    currencyCode: "TZS",
  },
  {
    en: "Thailand",
    flag: "🇹🇭",
    code: "TH",
    dialCode: "+66",
    mask: "999 999 9999",
    currency: "Thai baht",
    symbol: "฿",
    currencyCode: "THB",
  },
  {
    en: "Timor-Leste",
    flag: "🇹🇱",
    code: "TL",
    dialCode: "+670",
    mask: "9999 9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Togo",
    flag: "🇹🇬",
    code: "TG",
    dialCode: "+228",
    mask: "99 99 99 99",
    currency: "West African CFA franc",
    symbol: "Fr",
    currencyCode: "XOF",
  },
  {
    en: "Tokelau",
    flag: "🇹🇰",
    code: "TK",
    dialCode: "+690",
    mask: "9999",
    currency: "New Zealand dollar",
    symbol: "$",
    currencyCode: "NZD",
  },
  {
    en: "Tonga",
    flag: "🇹🇴",
    code: "TO",
    dialCode: "+676",
    mask: "999 9999",
    currency: "Tongan paʻanga",
    symbol: "T$",
    currencyCode: "TOP",
  },
  {
    en: "Trinidad and Tobago",
    flag: "🇹🇹",
    code: "TT",
    dialCode: "+1868",
    mask: "(999) 999-9999",
    currency: "Trinidad and Tobago dollar",
    symbol: "$",
    currencyCode: "TTD",
  },
  {
    en: "Tunisia",
    flag: "🇹🇳",
    code: "TN",
    dialCode: "+216",
    mask: "99 999 999",
    currency: "Tunisian dinar",
    symbol: "د.ت",
    currencyCode: "TND",
  },
  {
    en: "Turkey",
    flag: "🇹🇷",
    code: "TR",
    dialCode: "+90",
    mask: "(999) 999 9999",
    currency: "Turkish lira",
    symbol: "₺",
    currencyCode: "TRY",
  },
  {
    en: "Turkmenistan",
    flag: "🇹🇲",
    code: "TM",
    dialCode: "+993",
    mask: "9 99 999999",
    currency: "Turkmenistan manat",
    symbol: "m",
    currencyCode: "TMT",
  },
  {
    en: "Turks and Caicos Islands",
    flag: "🇹🇨",
    code: "TC",
    dialCode: "+1649",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Tuvalu",
    flag: "🇹🇻",
    code: "TV",
    dialCode: "+688",
    mask: "999999",
    currency: "Australian dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Uganda",
    flag: "🇺🇬",
    code: "UG",
    dialCode: "+256",
    mask: "9999 999999",
    currency: "Ugandan shilling",
    symbol: "Sh",
    currencyCode: "UGX",
  },
  {
    en: "Ukraine",
    flag: "🇺🇦",
    code: "UA",
    dialCode: "+380",
    mask: "999 999 9999",
    currency: "Ukrainian hryvnia",
    symbol: "₴",
    currencyCode: "UAH",
  },
  {
    en: "United Arab Emirates",
    flag: "🇦🇪",
    code: "AE",
    dialCode: "+971",
    mask: "999 999 9999",
    currency: "United Arab Emirates dirham",
    symbol: "د.إ",
    currencyCode: "AED",
  },
  {
    en: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dialCode: "+44",
    mask: "99999 999999",
    currency: "British pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "United States",
    flag: "🇺🇸",
    code: "US",
    dialCode: "+1",
    mask: "(999) 999-9999",
    currency: "United States dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Uruguay",
    flag: "🇺🇾",
    code: "UY",
    dialCode: "+598",
    mask: "999 999 999",
    currency: "Uruguayan peso",
    symbol: "$",
    currencyCode: "UYU",
  },
  {
    en: "Uzbekistan",
    flag: "🇺🇿",
    code: "UZ",
    dialCode: "+998",
    mask: "9 99 999 99 99",
    currency: "Uzbekistani soʻm",
    symbol: "so'm",
    currencyCode: "UZS",
  },
  {
    en: "Vanuatu",
    flag: "🇻🇺",
    code: "VU",
    dialCode: "+678",
    mask: "999 9999",
    currency: "Vanuatu vatu",
    symbol: "Vt",
    currencyCode: "VUV",
  },
  {
    en: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "🇻🇪",
    code: "VE",
    dialCode: "+58",
    mask: "9999-9999999",
    currency: "Venezuelan bolívar",
    symbol: "Bs",
    currencyCode: "VEF",
  },
  {
    en: "Vietnam",
    flag: "🇻🇳",
    code: "VN",
    dialCode: "+84",
    mask: "999 999 99 99",
    currency: "Vietnamese đồng",
    symbol: "₫",
    currencyCode: "VND",
  },
  {
    en: "Wallis and Futuna",
    flag: "🇼🇫",
    code: "WF",
    dialCode: "+681",
    mask: "99 99 99",
    currency: "CFP franc",
    symbol: "₣",
    currencyCode: "XPF",
  },
  {
    en: "Western Sahara",
    flag: "",
    code: "EH",
    dialCode: "+732",
    mask: "99 99 99",
    currency: "Moroccan dirham",
    symbol: "د.م.",
    currencyCode: "MAD",
  },
  {
    en: "Yemen",
    flag: "🇾🇪",
    code: "YE",
    dialCode: "+967",
    mask: "999 999 999",
    currency: "Yemeni rial",
    symbol: "﷼",
    currencyCode: "YER",
  },
  {
    en: "Zambia",
    flag: "🇿🇲",
    code: "ZM",
    dialCode: "+260",
    mask: "999 9999999",
    currency: "Zambian kwacha",
    symbol: "ZK",
    currencyCode: "ZMW",
  },
  {
    en: "Zimbabwe",
    flag: "🇿🇼",
    code: "ZW",
    dialCode: "+263",
    mask: "999 999 9999",
    currency: "Zimbabwean dollar",
    symbol: "$",
    currencyCode: "ZWL",
  },
]

export const CURRENCIES: ICurrency[] = [
  {
    en: "Algeria",
    flag: "🇩🇿",
    currency: "Algerian Dinar",
    symbol: "د.ج",
    currencyCode: "DZD",
  },
  {
    en: "Angola",
    flag: "🇦🇴",
    currency: "Angolan Kwanza",
    symbol: "Kz",
    currencyCode: "AOA",
  },
  {
    en: "Argentina",
    flag: "🇦🇷",
    currency: "Argentine Peso",
    symbol: "$",
    currencyCode: "ARS",
  },
  {
    en: "Australia",
    flag: "🇦🇺",
    currency: "Australian Dollar",
    symbol: "$",
    currencyCode: "AUD",
  },
  {
    en: "Bangladesh",
    flag: "🇧🇩",
    currency: "Bangladeshi Taka",
    symbol: "৳",
    currencyCode: "BDT",
  },
  {
    en: "Botswana",
    flag: "🇧🇼",
    currency: "Botswana Pula",
    symbol: "P",
    currencyCode: "BWP",
  },
  {
    en: "Brazil",
    flag: "🇧🇷",
    currency: "Brazilian Real",
    symbol: "R$",
    currencyCode: "BRL",
  },
  {
    en: "Canada",
    flag: "🇨🇦",
    currency: "Canadian Dollar",
    symbol: "$",
    currencyCode: "CAD",
  },
  {
    en: "Chile",
    flag: "🇨🇱",
    currency: "Chilean Peso",
    symbol: "$",
    currencyCode: "CLP",
  },
  {
    en: "China",
    flag: "🇨🇳",
    currency: "Chinese Yuan",
    symbol: "¥",
    currencyCode: "CNY",
  },
  {
    en: "Colombia",
    flag: "🇨🇴",
    currency: "Colombian Peso",
    symbol: "$",
    currencyCode: "COP",
  },
  {
    en: "Czech Republic",
    flag: "🇨🇿",
    currency: "Czech Koruna",
    symbol: "Kč",
    currencyCode: "CZK",
  },
  {
    en: "Denmark",
    flag: "🇩🇰",
    currency: "Danish Krone",
    symbol: "kr",
    currencyCode: "DKK",
  },
  {
    en: "Egypt",
    flag: "🇪🇬",
    currency: "Egyptian Pound",
    symbol: "£",
    currencyCode: "EGP",
  },
  {
    en: "Ethiopia",
    flag: "🇪🇹",
    currency: "Ethiopian Birr",
    symbol: "Br",
    currencyCode: "ETB",
  },
  {
    en: "Eurozone",
    flag: "🇪🇺",
    currency: "Euro",
    symbol: "€",
    currencyCode: "EUR",
  },
  {
    en: "Fiji",
    flag: "🇫🇯",
    currency: "Fijian Dollar",
    symbol: "$",
    currencyCode: "FJD",
  },
  {
    en: "Ghana",
    flag: "🇬🇭",
    currency: "Ghanaian Cedi",
    symbol: "₵",
    currencyCode: "GHS",
  },
  {
    en: "Hong Kong",
    flag: "🇭🇰",
    currency: "Hong Kong Dollar",
    symbol: "$",
    currencyCode: "HKD",
  },
  {
    en: "Hungary",
    flag: "🇭🇺",
    currency: "Hungarian Forint",
    symbol: "Ft",
    currencyCode: "HUF",
  },
  {
    en: "Iceland",
    flag: "🇮🇸",
    currency: "Icelandic Krona",
    symbol: "kr",
    currencyCode: "ISK",
  },
  {
    en: "India",
    flag: "🇮🇳",
    currency: "Indian Rupee",
    symbol: "₹",
    currencyCode: "INR",
  },
  {
    en: "Indonesia",
    flag: "🇮🇩",
    currency: "Indonesian Rupiah",
    symbol: "Rp",
    currencyCode: "IDR",
  },
  {
    en: "Israel",
    flag: "🇮🇱",
    currency: "Israeli Shekel",
    symbol: "₪",
    currencyCode: "ILS",
  },
  {
    en: "Japan",
    flag: "🇯🇵",
    currency: "Japanese Yen",
    symbol: "¥",
    currencyCode: "JPY",
  },
  {
    en: "Kenya",
    flag: "🇰🇪",
    currency: "Kenyan Shilling",
    symbol: "KSh",
    currencyCode: "KES",
  },
  {
    en: "Kuwait",
    flag: "🇰🇼",
    currency: "Kuwaiti Dinar",
    symbol: "د.ك",
    currencyCode: "KWD",
  },
  {
    en: "Malaysia",
    flag: "🇲🇾",
    currency: "Malaysian Ringgit",
    symbol: "RM",
    currencyCode: "MYR",
  },
  {
    en: "Mauritius",
    flag: "🇲🇺",
    currency: "Mauritian Rupee",
    symbol: "₨",
    currencyCode: "MUR",
  },
  {
    en: "Mexico",
    flag: "🇲🇽",
    currency: "Mexican Peso",
    symbol: "$",
    currencyCode: "MXN",
  },
  {
    en: "Mongolia",
    flag: "🇲🇳",
    currency: "Mongolian Tugrik",
    symbol: "₮",
    currencyCode: "MNT",
  },
  {
    en: "Morocco",
    flag: "🇲🇦",
    currency: "Moroccan Dirham",
    symbol: "د.م.",
    currencyCode: "MAD",
  },
  {
    en: "Nepal",
    flag: "🇳🇵",
    currency: "Nepalese Rupee",
    symbol: "₨",
    currencyCode: "NPR",
  },
  {
    en: "New Zealand",
    flag: "🇳🇿",
    currency: "New Zealand Dollar",
    symbol: "$",
    currencyCode: "NZD",
  },
  {
    en: "Nigeria",
    flag: "🇳🇬",
    currency: "Nigerian Naira",
    symbol: "₦",
    currencyCode: "NGN",
  },
  {
    en: "Norway",
    flag: "🇳🇴",
    currency: "Norwegian Krone",
    symbol: "kr",
    currencyCode: "NOK",
  },
  {
    en: "Oman",
    flag: "🇴🇲",
    currency: "Omani Rial",
    symbol: "ر.ع.",
    currencyCode: "OMR",
  },
  {
    en: "Pakistan",
    flag: "🇵🇰",
    currency: "Pakistani Rupee",
    symbol: "₨",
    currencyCode: "PKR",
  },
  {
    en: "Peru",
    flag: "🇵🇪",
    currency: "Peruvian Sol",
    symbol: "S/",
    currencyCode: "PEN",
  },
  {
    en: "Philippines",
    flag: "🇵🇭",
    currency: "Philippine Peso",
    symbol: "₱",
    currencyCode: "PHP",
  },
  {
    en: "Poland",
    flag: "🇵🇱",
    currency: "Polish Zloty",
    symbol: "zł",
    currencyCode: "PLN",
  },
  {
    en: "Qatar",
    flag: "🇶🇦",
    currency: "Qatari Riyal",
    symbol: "﷼",
    currencyCode: "QAR",
  },
  {
    en: "Russia",
    flag: "🇷🇺",
    currency: "Russian Ruble",
    symbol: "₽",
    currencyCode: "RUB",
  },
  {
    en: "Saudi Arabia",
    flag: "🇸🇦",
    currency: "Saudi Riyal",
    symbol: "﷼",
    currencyCode: "SAR",
  },
  {
    en: "Singapore",
    flag: "🇸🇬",
    currency: "Singapore Dollar",
    symbol: "$",
    currencyCode: "SGD",
  },
  {
    en: "South Africa",
    flag: "🇿🇦",
    currency: "South African Rand",
    symbol: "R",
    currencyCode: "ZAR",
  },
  {
    en: "South Korea",
    flag: "🇰🇷",
    currency: "South Korean Won",
    symbol: "₩",
    currencyCode: "KRW",
  },
  {
    en: "Sri Lanka",
    flag: "🇱🇰",
    currency: "Sri Lankan Rupee",
    symbol: "₨",
    currencyCode: "LKR",
  },
  {
    en: "Sweden",
    flag: "🇸🇪",
    currency: "Swedish Krona",
    symbol: "kr",
    currencyCode: "SEK",
  },
  {
    en: "Switzerland",
    flag: "🇨🇭",
    currency: "Swiss Franc",
    symbol: "CHF",
    currencyCode: "CHF",
  },
  {
    en: "Taiwan",
    flag: "🇹🇼",
    currency: "Taiwan Dollar",
    symbol: "NT$",
    currencyCode: "TWD",
  },
  {
    en: "Thailand",
    flag: "🇹🇭",
    currency: "Thai Baht",
    symbol: "฿",
    currencyCode: "THB",
  },
  {
    en: "Tunisia",
    flag: "🇹🇳",
    currency: "Tunisian Dinar",
    symbol: "د.ت",
    currencyCode: "TND",
  },
  {
    en: "Turkey",
    flag: "🇹🇷",
    currency: "Turkish Lira",
    symbol: "₺",
    currencyCode: "TRY",
  },
  {
    en: "Uganda",
    flag: "🇺🇬",
    currency: "Ugandan Shilling",
    symbol: "USh",
    currencyCode: "UGX",
  },
  {
    en: "United Arab Emirates",
    flag: "🇦🇪",
    currency: "UAE Dirham",
    symbol: "د.إ",
    currencyCode: "AED",
  },
  {
    en: "United Kingdom",
    flag: "🇬🇧",
    currency: "British Pound",
    symbol: "£",
    currencyCode: "GBP",
  },
  {
    en: "United States",
    flag: "🇺🇸",
    currency: "US Dollar",
    symbol: "$",
    currencyCode: "USD",
  },
  {
    en: "Vietnam",
    flag: "🇻🇳",
    currency: "Vietnamese Dong",
    symbol: "₫",
    currencyCode: "VND",
  },
]

export const CONSULTANT_ROLES: { [key: string]: string } = {
  AN: "Analyst",
  AS: "Associate",
  BAN: "Business Analyst",
  BS: "Business Associate",
  ML: "Mission Lead",
  KE: "Key Expert",
  PR: "Mission Principal",
  MP: "Partner",
  ME: "Mission Executive",
  BW: "Business Writer",
  TR: "Translator",
  PF: "Proof Reader",
}
export const getCountryName = (code: string): string | undefined =>
  COUNTRIES.find((countries) => countries.code === code)?.en

const Data = {
  COUNTRIES,
  CONSULTANT_ROLES,
}

export default Data
