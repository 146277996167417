
import { Vue, Component, Inject } from "vue-property-decorator"

@Component
export default class SortableItem extends Vue {
  @Inject() readonly itemClass!: string

  render() {
    return this.$slots.default![0]
  }

  mounted() {
    this.$el.classList.add(this.itemClass)
  }
}
