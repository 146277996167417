
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class ViewToggle extends Vue {
  @Prop({ default: 40 }) readonly size!: number
  @Prop({ default: "primaryBlue" }) readonly color!: number
  @Prop({ default: "white" }) readonly iconColor!: number
  @Prop({ default: () => [] }) readonly views!: {
    name: string
    icon: string
    route: string
  }[]
}
