
import { UserAdvanced } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class UserAvatar extends Vue {
  @Prop() readonly user!: UserAdvanced
  @Prop({ default: 100 }) readonly size?: string
  @Prop({ default: 60 }) readonly fontSize?: number
  @Prop({ required: false }) readonly color?: string
  @Prop({ default: false }) readonly outline?: boolean

  backgroundColor = "primaryGray-lighten2"

  get border() {
    if (!this.user.avatar) {
      return `1px solid var(--v-${this.generateRandomColor()})`
    }

    if (this.color && this.outline) {
      return `1px solid ${this.color}`
    }

    return null
  }

  mounted() {
    this.backgroundColor = this.generateRandomColor()
  }

  colors = [
    "primary-base",
    "primary-lighten3",
    "accent-lighten2",
    "info-darken1",
    "success-lighten2",
    "warning-lighten1",
    "error-lighten1",
    "primaryBlue-darken1",
    "background-darken4",
  ]

  generateRandomColor() {
    return this.colors[Math.floor(Math.random() * this.colors.length)]
  }

  get initials() {
    return (
      this.user?.firstName?.[0]?.toUpperCase() + this.user?.lastName?.[0]?.toUpperCase() || "OO"
    )
  }

  get fullName() {
    return `${this.user?.firstName} ${this.user?.lastName}`
  }
}
