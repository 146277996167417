
import { Component, Vue } from "vue-property-decorator"
import Nav from "@/components/layout/Nav.vue"
import NavItem from "@/components/layout/NavItem.vue"
import LogoSvg from "@/components/widgets/icons/LogoSvg.vue"
import { useEnvironment } from "@/hooks/useEnvironment"

@Component({
  components: {
    Nav,
    NavItem,
    LogoSvg,
  },
})
export default class ConsultantNav extends Vue {
  get isProduction() {
    const { isProduction } = useEnvironment()
    return isProduction
  }
}
