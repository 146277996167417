import { Namespaces } from "@/constants"
import { Component, Vue } from "vue-property-decorator"
import { Action } from "vuex-class"
import { AlertPayload, Alert } from "@/store/alerts/index"
import { ErrorV2 } from "@/gql"

export interface Alerter {
  addAlert(msg: string, type: Alert["type"], autohide?: boolean, prominent?: boolean): void
  addInfo(msg: string, autohide?: boolean, prominent?: boolean): void
  addSuccess(msg: string, autohide?: boolean, prominent?: boolean): void
  addWarning(msg: string, autohide?: boolean, prominent?: boolean): void
  addError(msg: string, autohide?: boolean, prominent?: boolean): void
  addGraphQLError(error: Error, autohide?: boolean): void
  addMutationError(error: ErrorV2, autohide?: boolean): void
}

const namespace = Namespaces.Alerts

@Component
export default class AlerterMixin extends Vue implements Alerter {
  @Action("addAlert", { namespace }) _addAlert!: (payload: AlertPayload) => Promise<Alert>
  @Action("showAlert", { namespace }) _showAlert!: (payload: Alert) => Promise<void>
  @Action("hideAlert", { namespace }) _hideAlert!: (payload: Alert) => Promise<void>
  @Action("removeAlert", { namespace }) _removeAlert!: (payload: Alert) => Promise<void>

  alertTimeouts!: { [key: string]: ReturnType<typeof setTimeout> }

  created(): void {
    this.alertTimeouts = {}
  }

  destroyed(): void {
    // make sure we clear any pending timeouts
    const alertIds = Object.keys(this.alertTimeouts)
    for (const alertId of alertIds) this.clearAlertTimeout(alertId)
  }

  async addAlert(
    msg: string,
    type: Alert["type"],
    autohide = true,
    prominent?: boolean
  ): Promise<Alert> {
    const alert = await this._addAlert({
      msg,
      type,
      prominent,
    })
    this.setupAlert(alert, autohide)
    return alert
  }

  async addInfo(msg: string, autohide = true, prominent?: boolean): Promise<Alert> {
    return await this.addAlert(msg, "info", autohide, prominent)
  }

  async addSuccess(msg: string, autohide = true, prominent?: boolean): Promise<Alert> {
    return await this.addAlert(msg, "success", autohide, prominent)
  }

  async addError(msg: string, autohide = true, prominent?: boolean): Promise<Alert> {
    return await this.addAlert(msg, "error", autohide, prominent)
  }

  async addWarning(msg: string, autohide = true, prominent?: boolean): Promise<Alert> {
    return await this.addAlert(msg, "warning", autohide, prominent)
  }

  async addGraphQLError(error: Error, autohide = true): Promise<Alert> {
    return await this.addError(error.message, autohide, true)
  }

  async addMutationError(error: ErrorV2, autohide = true): Promise<void> {
    if (error.fields && error.fields.length) {
      for (const field of error.fields) {
        const fieldNameArray = field.field.split(".")
        this.addError(`${fieldNameArray[fieldNameArray.length - 1]} ${field.message}`, autohide)
      }
    } else this.addError(error.message, autohide)
  }

  async showAlert(alert: Alert, autohide = true): Promise<void> {
    await this._showAlert(alert)
    this.clearAlertTimeout(alert.id)

    if (autohide)
      this.alertTimeouts[alert.id] = setTimeout(() => {
        this.hideAlert(alert)
      }, 5000) // hide the alert after 5s
  }

  async hideAlert(alert: Alert): Promise<void> {
    await this._hideAlert(alert)
    this.clearAlertTimeout(alert.id)

    // remove it from the dom after a while
    this.alertTimeouts[alert.id] = setTimeout(() => {
      this.removeAlert(alert)
    }, 2000) // remove the alert after 2s
  }

  async removeAlert(alert: Alert): Promise<void> {
    await this._removeAlert(alert)
    this.clearAlertTimeout(alert.id)
  }

  private setupAlert(alert: Alert, autohide: boolean) {
    this.alertTimeouts[alert.id] = setTimeout(() => {
      this.showAlert(alert, autohide)
    }, 100) // show the alert after 100ms
  }

  private clearAlertTimeout(alertId: string) {
    const timeout = this.alertTimeouts[alertId]
    if (timeout) clearTimeout(timeout)
    delete this.alertTimeouts[alertId]
  }
}
