export enum Namespaces {
  Apply = "apply",
  Auth = "auth",
  Alerts = "alerts",
  Profile = "profile",
  MissionLifecycle = "missionLifecycle",
  SessionHistory = "sessionHistory",
}

export const proficiencies = ["Beginner", "Conversational", "Business", "Fluent"]

export const SESSION_USER_KEY = "SESSION_USER"
export const SESSION_TOKEN_KEY = "SESSION_TOKEN"
export const APPLICANT_KEY = "APPLY_APPLICANT"
export const SESSION_HISTORY_KEY = "SESSION_HISTORY"
export const REDIRECT_ROUTE_KEY = "REDIRECT_ROUTE"
export const ACCOUNT_ASPECT_KEY = "ACCOUNT_ASPECT"

export const ConsultantStatuses = [
  "Active in the family",
  "Please staff me",
  "It has been a while",
  "Foresighter forever",
  "It's been complicated",
  "Archived",
]

import { CandidateRejectionTypeEnum, ContractingEntity } from "@/gql"
// rejection reasons
export const CandidateRejectionTypeEnumReasons = [
  {
    value: CandidateRejectionTypeEnum.ConsiderDifferentRole,
    text: "Consider for different role",
  },
  { value: CandidateRejectionTypeEnum.SeeYouLater, text: "See you later" },
  {
    value: CandidateRejectionTypeEnum.IncompleteAssessment,
    text: "Reject due to incomplete assessment",
  },
  {
    value: CandidateRejectionTypeEnum.InsufficientAssessment,
    text: "Reject due to insufficient assessment results",
  },
  {
    value: CandidateRejectionTypeEnum.ProfileNotMatching,
    text: "Reject due to profile not matching",
  },
]

import { EmploymentStatus } from "@/gql"
export const EmploymentStatuses: { value: EmploymentStatus; text: string }[] = [
  { value: EmploymentStatus.Unemployed, text: "Unemployed" },
  { value: EmploymentStatus.Employed, text: "Employed full-time" },
  { value: EmploymentStatus.SelfEmployed, text: "Self employed" },
  { value: EmploymentStatus.Freelancer, text: "Freelancer" },
  {
    value: EmploymentStatus.Enrolled,
    text: "Enrolled in an educational program that requires work experience",
  },
]

import { JobFunction } from "@/gql"
export const JobFunctions: { value: JobFunction; text: string }[] = [
  { value: JobFunction.Strategy, text: "Strategy" },
  { value: JobFunction.Implementation, text: "Implementation​" },
  { value: JobFunction.DueDiligence, text: "Due diligence​" },
  { value: JobFunction.Research, text: "Research​" },
  { value: JobFunction.StakeholderEngagement, text: "Stakeholder engagement" },
  { value: JobFunction.ItInfrastructure, text: "IT infrastructure" },
  {
    value: JobFunction.PeopleAndOrganisation,
    text: "People and organisation",
  },
]

import { ConsultantAvailability } from "@/gql"
export const ConsultantAvailabilities: { value: ConsultantAvailability; text: string }[] = [
  { value: ConsultantAvailability.AvailableFullTime, text: "Available (full-time)" },
  { value: ConsultantAvailability.AvailablePartTime, text: "Available (part-time)" },
  { value: ConsultantAvailability.NotAvailable, text: "Not available" },
  { value: ConsultantAvailability.Other, text: "Other" },
]

export const ConsultantAvailabilityStatuses = [
  {
    value: ConsultantAvailability.AvailableFullTime,
    text: "Available (Full-time)",
    icon: "check-circle",
    color: "green",
  },
  {
    value: ConsultantAvailability.AvailablePartTime,
    text: "Available (Part-time)",
    icon: "clock-circle",
    color: "#1c274c",
  },

  {
    value: ConsultantAvailability.NotAvailable,
    text: "Not Available",
    icon: "minus-circle",
    color: "#8c8c8c",
  },
]

export const ContractingEntities: { value: ContractingEntity; text: string; address: string }[] = [
  {
    value: ContractingEntity.AfgUk,
    text: "AFG UK",
    address: "7 Bell Yard, London, WC2A 2JR, United Kingdom",
  },
  {
    value: ContractingEntity.AfgGh,
    text: "AFG Ghana",
    address: "Ako Adjei Park, F 393, Fourth Otswe St, Accra",
  },
  {
    value: ContractingEntity.AfgSa,
    text: "AFG South Africa",
    address: "9, 10th Avenue, Parktown North, 2193, South Africa",
  },
]

export enum PageState {
  Idle = "idle",
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
  Empty = "empty",
  NetworkError = "networkError",
}

export enum AppDomain {
  Apply = "apply",
}

export const THEMES = {
  AHEAD_PRIMARY: "aheadPrimary",
  PERALTA_PRIMARY: "primary",
  PERALTA_DARK_GRAY: "var(--light-dark)",
  WHITE_TEXT_COLOR: "white",
  AHEAD_PRIMARY_COLOR: "var(--ahead-primary-color)",
  PERALTA_PRIMARY_RED: "var(--primary-color)",
} as const

export const AHEAD_THEME = {
  primaryColor: THEMES.AHEAD_PRIMARY,
  textColor: THEMES.WHITE_TEXT_COLOR,
  iconColor: THEMES.AHEAD_PRIMARY_COLOR,
  logoUrl: "/ahead_logo.svg",
} as const

export const PERALTA_THEME = {
  textColor: THEMES.WHITE_TEXT_COLOR,
  primaryColor: THEMES.PERALTA_PRIMARY,
  iconColor: THEMES.PERALTA_PRIMARY,
} as const

export enum ProductTypeEnum {
  Ahead = "ahead_business",
  Peralta = "peralta",
}

export const Industries = [
  "Finance and Banking",
  "Healthcare and Pharmaceuticals",
  "Technology and Software Development",
  "Telecommunications",
  "Energy and Utilities",
  "Manufacturing and Industrial",
  "Retail and Consumer Goods",
  "Transportation and Logistics",
  "Real Estate and Construction",
  "Education and Training",
  "Hospitality and Tourism",
  "Media and Entertainment",
  "Public Sector and Government",
  "Nonprofit and NGOs",
  "Aerospace and Defense",
  "Agriculture and Food Production",
  "Automotive",
  "Insurance",
  "Legal Services",
  "Marketing and Advertising",
  "Mining and Natural Resources",
  "Professional Services",
  "Sports and Recreation",
  "Waste Management and Recycling",
  "Fashion and Apparel",
  "Chemical Industry",
  "Environmental Services",
  "E-commerce",
  "Biotechnology",
  "Cybersecurity",
]

import { CompanySizeRangeEnum, OwnershipStructureEnum } from "@/gql"
export const CompanySizeRange = [
  {
    text: "0 - 10",
    value: CompanySizeRangeEnum.LessThan_10,
  },
  {
    text: "11 - 50",
    value: CompanySizeRangeEnum.LessThan_50,
  },
  {
    text: "> 50",
    value: CompanySizeRangeEnum.GreaterThan_50,
  },
]

export const OwnershipStructure = [
  {
    text: "Majority African-owned",
    value: OwnershipStructureEnum.MajorityAfricanOwned,
  },
  {
    text: "Majority Foreign-owned",
    value: OwnershipStructureEnum.MajorityForeignOwned,
  },
  {
    text: "Prefer not to disclose",
    value: OwnershipStructureEnum.PreferNotToDisclose,
  },
]
