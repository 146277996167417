
import { Component, Prop, Vue } from "vue-property-decorator"
import { Theme } from "@/types"
import { THEMES } from "@/constants"

type IPresetType = "loadingError" | "networkError" | "notFound" | "offline" | "emptyState"

interface IFeedBackType {
  icon?: string
  header: string
  message: string
  button?: PredefinedButtonOptions
}

type IPresetTypesGroups = {
  [key in IPresetType]: IFeedBackType
}

// For predefined button names it should map with FeedBackTypeButtonGroupKeys
type PredefinedButtonOptions = "tryAgain" | "backHome"
type CustomButtonOptions = string & PredefinedButtonOptions

@Component
export default class FeedBackMessage extends Vue {
  @Prop({ required: false, default: "loadingError" }) readonly preset?: IPresetType

  @Prop({ default: "" }) readonly icon!: string

  @Prop({ default: "" }) readonly resource!: string

  @Prop({ default: "" }) readonly customHeader!: string

  @Prop({ default: "" }) readonly customMessage!: string

  @Prop({ default: "" }) readonly customButton!: CustomButtonOptions

  @Prop({ default: null }) readonly refetchQueryName?: string
  @Prop({ default: true }) readonly showActionButton?: boolean

  @Prop() readonly buttonOnClick!: () => void

  @Prop({
    default: () => ({
      iconColor: THEMES.PERALTA_PRIMARY_RED,
    }),
  })
  readonly theme!: Theme

  get presetButton() {
    return this.preset && this.PresetTypesGroups[this.preset].button
  }

  get PresetTypesGroups(): IPresetTypesGroups {
    return {
      emptyState: {
        icon: this.icon || "la-box",
        header: this.customHeader || `No ${this.resource}`,
        message: this.customMessage || `There are no ${this.resource} to show at the moment.`,
      },
      loadingError: {
        icon: this.icon || "la-frown",
        header: this.customHeader || `Oh no! We coudn't load the ${this.resource}`,
        message:
          this.customMessage ||
          `There was a problem fetching the resource. Check your input and try again`,
        button: this.customButton || "tryAgain",
      },
      notFound: {
        icon: this.icon || "la-frown",
        header: this.customHeader || `Oh no! We couldn't find this ${this.resource}`,
        message:
          this.customMessage ||
          "The resource may be deleted, please double check the link. If you believe this is a mistake please contact support",
        button: this.customButton || "backHome",
      },
      offline: {
        header: this.customHeader || `Looks like you are offline`,
        message:
          this.customMessage ||
          "We could not complete that because you are not connected to the internet.  Check your internet connection and try again.",
        button: this.customButton || "tryAgain",
      },
      networkError: {
        header: this.customHeader || `There’s been a glitch…`,
        message:
          this.customMessage ||
          "We’re not quite sure what went wrong. You can go back, or try contact technical Support if you need a hand.",
        button: this.customButton || "backHome",
      },
    }
  }

  readonly FeedBackTypeButtonGroupKeys: { [key in PredefinedButtonOptions]: string } = {
    tryAgain: "tryAgain",
    backHome: "backHome",
  }

  handleButtonOnClick() {
    if (this.buttonOnClick) {
      this.buttonOnClick()
      return true
    }
    return false
  }
}
