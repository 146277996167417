var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('div',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.approval.user.id === _vm.currentUser.id ? "You" : _vm.approval.user.name)+" "),_c('v-chip',{staticClass:"text-capitalize ml-3",class:{
          'grey--text': _vm.approval.state === 'pending',
          'teal--text': _vm.approval.state === 'approved',
          'error--text': _vm.approval.state === 'rejected',
        },attrs:{"color":_vm.approval.state === 'approved'
            ? 'green lighten-5'
            : _vm.approval.state === 'rejected'
            ? 'error lighten-5'
            : 'grey lighten-5',"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.approval.state)+" ")])],1),_c('v-card-text',{staticClass:"mt-1"},[(_vm.approval.state === 'approved')?_c('v-card-subtitle',{staticClass:"pa-0"},[_vm._v(" Approved on "+_vm._s(_vm.$moment(_vm.approval.approvedAt).format("Do MMMM YYYY"))+" ")]):(_vm.approval.state === 'rejected')?[_c('v-card-subtitle',{staticClass:"pa-0 pb-2"},[_vm._v(" Rejected on "+_vm._s(_vm.$moment(_vm.approval.rejectedAt).format("Do MMMM YYYY"))+" ")]),(_vm.approval.rejectionReason)?_c('v-card-subtitle',{staticClass:"px-0 py-1 font-italic"},[_vm._v(_vm._s(_vm.approval.rejectionReason))]):_vm._e()]:_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }