
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class SquareIconButton extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: false, default: "white" }) readonly color?: string
  @Prop({ default: "black" }) readonly iconColor?: string
  @Prop({ default: "28" }) readonly iconSize?: string
}
