import { RootState } from "../index"
import {
  ConsultantProfileFragmentFragment,
  ConsultantProfileQueryQuery,
  ConsultantProfileQuery,
} from "@/gql"
import { Module, GetterTree, ActionTree, MutationTree } from "vuex"
import { ApolloProvider } from "vue-apollo"

const namespaced = true

export const state: ProfileState = {
  profile: undefined,
}

const actions: ActionTree<ProfileState, RootState> = {
  async requireProfile({ commit, state }, payload: RequireProfilePayload) {
    if (!state.profile || payload.reload) {
      // Fetch consultant profile
      const result = await payload.provider.defaultClient.query<ConsultantProfileQueryQuery>({
        query: ConsultantProfileQuery,
        fetchPolicy: "no-cache",
      })

      if (result && result.data && result.data.consultantProfile) {
        const payload: StoreProfilePayload = {
          profile: result.data.consultantProfile,
        }

        commit("loadProfile", payload)
      }
    }
  },
  storeProfile({ commit }, payload: StoreProfilePayload) {
    commit("loadProfile", payload)
  },
}

const mutations: MutationTree<ProfileState> = {
  loadProfile(state, payload: StoreProfilePayload) {
    state.profile = { ...payload.profile }
  },

  reset(state) {
    state.profile = undefined
  },
}

const getters: GetterTree<ProfileState, RootState> = {
  getProfile(state) {
    return state.profile
  },
}

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
}

export interface ProfileState {
  profile: ConsultantProfileFragmentFragment | undefined
}

export interface RequireProfilePayload {
  provider: ApolloProvider
  reload?: boolean
}

export interface StoreProfilePayload {
  profile: ConsultantProfileFragmentFragment
}
