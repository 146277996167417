import Routes from "@/router/routes"

const useTalentRoutes = (): any[] => {
  // Routes here are prefixed with "/talent"
  return [
    {
      path: "dashboard",
      name: Routes.TalentDashboard,
      component: () => import("../views/talent/Dashboard.vue"),
      meta: {
        navigatesTo: "Talent Dashboard",
      },
    },
    {
      path: "pool",
      name: Routes.TalentPool,
      component: () => import("../views/talent/Pool.vue"),
      meta: {
        navigatesTo: "Talent Pool",
      },
    },
    {
      path: "pool/:id",
      name: Routes.ConsultantDetail,
      component: () => import("../views/talent/ConsultantDetail.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "applications/:id",
      name: Routes.TalentApplicantsConsultantDetail,
      component: () => import("../views/talent/ApplicationDetailPage.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "applications",
      name: Routes.TalentApplications,
      component: () => import("../views/talent/Applications.vue"),
      meta: {
        navigatesTo: "Applications",
      },
    },
    {
      path: "split-view",
      name: Routes.TalentApplicationsSplitView,
      component: () => import("../views/talent/ApplicationsSplitView.vue"),
      meta: {
        navigatesTo: "Applications Split View",
        fullScreen: true,
      },
    },
    {
      path: "applicants-manager",
      name: Routes.ApplicantsManager,
      component: () => import("../views/talent/ApplicationsManager.vue"),
      meta: {
        navigatesTo: "Applicants Manager",
      },
    },
    {
      path: "performance-appraisals",
      name: Routes.TalentPerformanceAppraisals,
      component: () => import("../views/talent/Appraisals.vue"),
      meta: {
        navigatesTo: "Performance Data",
      },
    },
    {
      path: "peraltee-points",
      name: Routes.TalentPeralteePoints,
      component: () => import("../views/talent/PeralteePoints.vue"),
      meta: {
        navigatesTo: "Peraltee Points",
      },
    },
    {
      path: "appraisal-schedule",
      name: Routes.AppraisalSchedule,
      component: () => import("../views/talent/AppraisalSchedule.vue"),
      meta: {
        navigatesTo: "Appraisal Schedule",
      },
    },
  ]
}

export default useTalentRoutes
