
import { Component, Prop, Vue } from "vue-property-decorator"
import Icon from "../icons/Icon.vue"

@Component({
  components: {
    Icon,
  },
})
export default class TextButton extends Vue {
  @Prop()
  display!: string

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: "primary" }) readonly color!: string
  @Prop() readonly icon?: string
}
