var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"color":"var(--primary-color) !important","border-color":"var(--primary-color) !important"}},[_c('v-list',{staticClass:"section-list",staticStyle:{"margin-top":"10px !important"},attrs:{"light":false,"dense":""}},[(false)?_c('v-list-item',{attrs:{"dense":false,"link":"","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("la-list-alt")])],1),_c('v-list-item-title',[_vm._v("Tasks")])],1):_vm._e(),_c(_setup.NavItem,{attrs:{"icon":'home-2',"to":_vm.$routes.Home,"name":"Home"}}),(_setup.can('view_consultant_contracts'))?_c(_setup.NavItem,{attrs:{"icon":'document-add',"to":_vm.$routes.ConsultantContracts,"name":"Contracts"}}):_vm._e(),(_setup.can('view_clients'))?_c(_setup.NavItem,{attrs:{"icon":'user-heart',"to":_vm.$routes.Clients,"name":"Clients"}}):_vm._e(),(false)?_c(_setup.NavItem,{attrs:{"to":_vm.$routes.BrandResources,"name":"Brand Resources"},scopedSlots:_vm._u([{key:"svgNavIcon",fn:function({ active }){return [_c(_setup.LogoSvg,{attrs:{"iconColor":active ? '#FF0008' : '#8C8C8C'}})]}}],null,false,1907757719)}):_vm._e()],1),(_setup.can('view_missions'))?_c('v-list',{staticClass:"section-list",attrs:{"dense":"","ripple":"false"}},[_c('v-list-item-title',{staticClass:"text-uppercase",class:{
        'pl-3': !_setup.collapseDrawer(),
      },staticStyle:{"color":"#8c8c8c !important","font-size":"13px !important","opacity":"0.7","margin-bottom":"18px !important"}},[_vm._v("Missions")]),_c(_setup.NavItem,{attrs:{"icon":'chart',"to":_vm.$routes.MissionDashboard,"name":"Dashboard"}}),_c(_setup.NavItem,{attrs:{"icon":'rocket',"to":_vm.$routes.Missions,"name":"View Missions"}}),_c(_setup.NavItem,{attrs:{"icon":'book',"to":_vm.$routes.ScopeLibrary,"name":"Scope Library"}})],1):_vm._e(),_c('v-list',{staticClass:"section-list",attrs:{"light":false,"dense":""}},[_c('v-list-item-title',{staticClass:"text-uppercase",class:{
        'pl-3': !_setup.collapseDrawer(),
      },staticStyle:{"color":"#8c8c8c !important","font-size":"13px !important","opacity":"0.7","margin-bottom":"18px !important"}},[_vm._v("Talent")]),(_setup.policy.canManageTalent)?_c(_setup.NavItem,{attrs:{"icon":'chart',"to":_vm.$routes.TalentDashboard,"name":"Dashboard"}}):_vm._e(),_c(_setup.NavItem,{attrs:{"icon":'checklist-minimalistic',"to":_vm.$routes.Surveys,"name":"Surveys"}}),(_setup.policy.canManageTalent)?_c(_setup.NavItem,{attrs:{"icon":'users-group-rounded',"to":_vm.$routes.TalentPool,"name":"Pool"}}):_vm._e(),(_setup.policy.canManageTalent)?_c(_setup.NavItem,{attrs:{"icon":'clapperboard-edit',"to":_vm.$routes.TalentApplications,"name":"Applications"}}):_vm._e(),(_setup.can('view_performance_data'))?_c(_setup.NavItem,{attrs:{"icon":'graph-up',"to":_vm.$routes.TalentPerformanceAppraisals,"name":"Performance Data"}}):_vm._e(),(_setup.policy.canManageTalent)?_c(_setup.NavItem,{attrs:{"icon":'database',"to":_vm.$routes.TalentPeralteePoints,"name":"Peraltee Points"}}):_vm._e(),(false)?_c(_setup.NavItem,{attrs:{"icon":'la-certificate',"to":_vm.$routes.TalentPeralteePoints,"name":"Promotions"}}):_vm._e()],1),(_setup.can('view_consultant_invoices'))?_c('v-list',{staticClass:"section-list",attrs:{"dense":"","ripple":"false"}},[_c('v-list-item-title',{staticClass:"text-uppercase",class:{
        'pl-3': !_setup.collapseDrawer(),
      },staticStyle:{"color":"#8c8c8c !important","font-size":"13px !important","opacity":"0.7","margin-bottom":"18px !important"}},[_vm._v("Finance")]),(_setup.can('view_consultant_invoices'))?_c(_setup.NavItem,{attrs:{"icon":'file-text',"to":_vm.$routes.Invoices,"name":"Invoices"}}):_vm._e()],1):_vm._e(),(_vm.isAdmin())?_c('v-list',{staticClass:"section-list",attrs:{"dense":""}},[_c('v-list-item-title',{staticClass:"text-uppercase",class:{
        'pl-3': !_setup.collapseDrawer(),
      },staticStyle:{"color":"#8c8c8c !important","font-size":"13px !important","opacity":"0.7","margin-bottom":"18px !important"}},[_vm._v("Admin")]),_c(_setup.NavItem,{attrs:{"icon":'users-group-rounded',"to":_vm.$routes.Users,"name":"Users"}}),_c(_setup.NavItem,{attrs:{"icon":'user-check',"to":_vm.$routes.UserRoles,"name":"Roles"}}),_c(_setup.NavItem,{attrs:{"icon":'shield-keyhole',"to":_vm.$routes.Permissions,"name":"Permissions"}})],1):_vm._e(),(false)?_c('v-list',{staticClass:"section-list",staticStyle:{"margin-top":"20px !important"},attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("la-info-circle")])],1),_c('v-list-item-title',[_vm._v("Help")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("la-cog")])],1),_c('v-list-item-title',[_vm._v("Settings")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }