
import { Vue, Component, Inject } from "vue-property-decorator"

@Component
export default class SortableHandle extends Vue {
  @Inject() readonly handleClass!: string

  mounted() {
    this.$el.classList.add(this.handleClass)
  }
}
