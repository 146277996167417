import { ActionTree, MutationTree, GetterTree } from "vuex"
import { RootState } from "../index"
import { APPLICANT_KEY } from "@/constants"

import { ApplicantInput, ConsultantApplicationFragmentFragment } from "@/gql"
import { ApplyState } from "."

const actions: ActionTree<ApplyState, RootState> = {
  startApplication({ commit }, payload: SaveApplicationPayload) {
    commit("startApplication", payload)
    // Persist applicant
    localStorage.setItem(APPLICANT_KEY, JSON.stringify(payload.applicant))
  },
  saveApplication({ commit }, payload: SaveApplicationPayload) {
    commit("saveApplication", payload)
  },
  completeApplication() {
    localStorage.removeItem(APPLICANT_KEY)
  },
}

const mutations: MutationTree<ApplyState> = {
  startApplication(state, payload: SaveApplicationPayload) {
    state.application = { ...payload.application }
    state.applicant = { ...payload.applicant }
  },
  saveApplication(state, payload: SaveApplicationPayload) {
    state.application = { ...payload.application }
    state.applicant = { ...payload.applicant }
  },
  completeApplication(state) {
    state.application = undefined
    state.applicant = undefined
  },
}

const getters: GetterTree<ApplyState, RootState> = {
  hasApplication(state) {
    return state.application !== undefined || !!localStorage.getItem(APPLICANT_KEY)
  },
  getApplication(state) {
    return state.application
  },
  getApplicant(state) {
    if (state.applicant) return state.applicant

    const applicant = localStorage.getItem(APPLICANT_KEY)
    return applicant ? JSON.parse(applicant) : undefined
  },
}

export const application = {
  getters,
  actions,
  mutations,
}

export interface SaveApplicationPayload {
  application: ConsultantApplicationFragmentFragment
  applicant: ApplicantInput
}
