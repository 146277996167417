var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.value && _vm.showSelectedFile)?_c('div',[(_vm.$attrs.label)?_c('div',{staticClass:"mb-2 label random",class:{
      required: _vm.required,
    }},[_vm._v(" "+_vm._s(_vm.$attrs.label)+" ")]):_vm._e(),_c('v-file-input',{class:{
      'form-element--outlined': _vm.$attrs.hasOwnProperty('outlined'),
    },attrs:{"type":"hidden","color":"black","value":_vm.value,"clearable":false,"hide-details":"","flat":"","outlined":_vm.$attrs.hasOwnProperty('outlined'),"solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(){return [(!_vm.mini)?_c('v-chip',{staticClass:"text-uppercase file-upload-input-chip",attrs:{"color":"secondary","label":""}},[_vm._v(" "+_vm._s(_vm.extension)+" ")]):_vm._e(),_c('v-chip',{attrs:{"color":"grey lighten-3","label":""}},[_vm._v(" "+_vm._s(_vm.fileNameLength > 0 ? _vm.truncate(_vm.value.name, _vm.fileNameLength) : _vm.value.name)+" ")]),(!_vm.disabled)?_c('v-icon',{on:{"click":_vm.clearSelection}},[_vm._v("la-times")]):_vm._e()]},proxy:true},(!_vm.mini)?{key:"append",fn:function(){return [_c('v-chip',{staticClass:"pa-1 mr-2",attrs:{"target":"_blank","color":"grey lighten-2","label":""},on:{"click":function($event){_vm.showPreviewable = true}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("la-download")])],1)]},proxy:true}:null],null,true)}),_c('Previewable',{attrs:{"title":_vm.name,"editable":false,"url":_vm.url},model:{value:(_vm.showPreviewable),callback:function ($$v) {_vm.showPreviewable=$$v},expression:"showPreviewable"}})],1):_c('div',[(_vm.$attrs.hasOwnProperty('outlined') && _vm.$attrs.label)?_c('div',{staticClass:"mb-2 label",class:{
      required: _vm.required,
    }},[_vm._v(" "+_vm._s(_vm.$attrs.label)+" ")]):_vm._e(),_c('v-file-input',_vm._b({staticClass:"m-0 p-0",class:{ 'form-element--outlined': _vm.$attrs.hasOwnProperty('outlined') },attrs:{"color":"black","type":"file","id":_vm.id,"loading":_vm.isUploading,"disabled":_vm.isUploading || _vm.disabled,"accept":_vm.accepts,"prepend-icon":_vm.isUploading ? 'la-spinner' : 'la-paperclip',"show-size":"","hide-input":_vm.hideInput},on:{"change":function($event){return _vm.onChange($event)}},scopedSlots:_vm._u([(_vm.dataURL)?{key:"selection",fn:function({ index, text }){return [(index < 2)?_c('v-chip',{attrs:{"color":"primary","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}:null],null,true),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},'v-file-input',_vm.attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }