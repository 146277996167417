
import { ActivityLog } from "@/gql"
import { Component, Vue, Prop } from "vue-property-decorator"
import { VTimelineItem, VIcon, VImg, VChip, VAvatar } from "vuetify/lib"
import ActivityItem from "./ActivityItem.vue"

@Component({
  components: {
    "v-timeline-item": VTimelineItem,
    "v-icon": VIcon,
    "v-img": VImg,
    "v-chip": VChip,
    "v-avatar": VAvatar,
    ActivityItem,
  },
})
export default class ConsultantActivityItem extends Vue {
  @Prop() readonly icon?: string
  @Prop({ default: "" }) readonly color?: string
  @Prop() readonly activity!: ActivityLog
  @Prop({ default: true }) readonly hideDot!: boolean
}
