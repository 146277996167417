import { extend } from "vee-validate"
import { ref, computed, watch, Ref } from "vue"

type PasswordValidationReturn = {
  strengthFeedback: Ref<number>
  strengthCriteria: Ref<string[]>
  passwordStrengthLabel: Ref<string>
  passwordStrengthColor: Ref<string>
}

const PASSWORD_CRITERIA = {
  minLength: 8,
  hasLowercase: /[a-z]/,
  hasUppercase: /[A-Z]/,
  hasNumber: /[0-9]/,
}

const createValidationRule = (criterion: RegExp, message: string) => {
  return (value: string) => criterion.test(value) || message
}

extend("password", {
  params: ["target"],
  validate(value: string, { target }: Record<string, any>) {
    return value === target
  },
  message: "Passwords do not match",
})

extend(
  "minLength",
  (value) =>
    value.length >= PASSWORD_CRITERIA.minLength ||
    `Password should be at least ${PASSWORD_CRITERIA.minLength} characters long`
)

extend(
  "has_lowercase",
  createValidationRule(
    PASSWORD_CRITERIA.hasLowercase,
    "Password should contain at least one lowercase letter"
  )
)

extend(
  "has_uppercase",
  createValidationRule(
    PASSWORD_CRITERIA.hasUppercase,
    "Password should contain at least one uppercase letter"
  )
)

extend(
  "has_number",
  createValidationRule(PASSWORD_CRITERIA.hasNumber, "Password should contain at least one number")
)

interface StrengthCriterion {
  name: string
  regex: RegExp
  score: number
}

const STRENGTH_CRITERIA: StrengthCriterion[] = [
  { name: "Minimum length", regex: new RegExp(`.{${PASSWORD_CRITERIA.minLength},}`), score: 25 },
  { name: "Lowercase letter", regex: PASSWORD_CRITERIA.hasLowercase, score: 25 },
  { name: "Uppercase letter", regex: PASSWORD_CRITERIA.hasUppercase, score: 25 },
  { name: "Number", regex: PASSWORD_CRITERIA.hasNumber, score: 25 },
]

export function usePasswordValidation(password: Ref<string>): PasswordValidationReturn {
  const strengthFeedback = ref(0)
  const strengthCriteria = ref<string[]>([])

  const checkPasswordStrength = () => {
    let score = 0
    strengthCriteria.value = []

    STRENGTH_CRITERIA.forEach((criterion) => {
      if (criterion.regex.test(password.value)) {
        score += criterion.score
        strengthCriteria.value.push(criterion.name)
      }
    })

    strengthFeedback.value = Math.min(100, (score / 100) * 100)
  }

  const passwordStrengthLabel = computed(() => {
    if (strengthFeedback.value <= 20) return "Very Weak"
    if (strengthFeedback.value <= 40) return "Weak"
    if (strengthFeedback.value <= 60) return "Moderate"
    if (strengthFeedback.value <= 80) return "Strong"
    return "Very Strong"
  })

  const passwordStrengthColor = computed(() => {
    if (strengthFeedback.value <= 20) return "red darken-4"
    if (strengthFeedback.value <= 40) return "red"
    if (strengthFeedback.value <= 60) return "orange"
    if (strengthFeedback.value <= 80) return "light-green"
    return "green darken-2"
  })

  watch(
    () => password.value,
    (newPassword) => {
      if (newPassword) {
        checkPasswordStrength()
      } else {
        strengthFeedback.value = 0
        strengthCriteria.value = []
      }
    },
    { immediate: true }
  )

  return {
    strengthFeedback,
    strengthCriteria,
    passwordStrengthLabel,
    passwordStrengthColor,
  }
}
