
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

export type Paginate = {
  page: number
  per: number
  length: number
}

@Component
export default class Pagination extends Vue {
  @Prop({ required: true }) readonly value!: Paginate
  form = { page: 1, per: 10, length: 1 }

  @Prop({ default: true }) readonly inline!: boolean

  @Prop({ default: "Projects" }) readonly resource!: string

  @Watch("form", { deep: true })
  onFormChange() {
    this.$emit("input", this.form)
  }

  get cols() {
    return this.inline ? 6 : 12
  }

  mounted() {
    this.form = { ...this.value }
  }
}
