
import { Component, Vue, Prop } from "vue-property-decorator"
import { CurrenciesQuery } from "@/gql"

@Component
export default class CurrencySelect extends Vue {
  readonly query = CurrenciesQuery

  @Prop({ default: true })
  readonly returnObject!: boolean
}
