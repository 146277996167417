
import { Namespaces } from "@/constants"
import { Component, Vue } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { Alert } from "@/store/alerts/index"

const namespace = Namespaces.Alerts

@Component
export default class Alerts extends Vue {
  @Getter("getAlerts", { namespace }) alerts!: Alert[]

  get alertList() {
    return [...this.alerts].reverse()
  }

  getBorder(alert: Alert) {
    if (alert.prominent) return "right"
    return "left"
  }

  alertIconsHandler(alertType: string) {
    switch (alertType) {
      case "info":
        return "la-info-circle"
      case "success":
        return "la-check-circle"
      case "warning":
        return "la-exclamation-triangle"
      case "error":
        return "la-exclamation-circle"
    }
  }
}
