import {
  required,
  email,
  max,
  min,
  numeric,
  double,
  is,
  max_value,
  required_if,
} from "vee-validate/dist/rules"
import { extend, setInteractionMode } from "vee-validate"
import moment from "moment"
import { isValidNumber } from "libphonenumber-js"
import { PhoneInput as PhoneInputType } from "@/gql"

setInteractionMode("eager")

extend("required", {
  ...required,
  message: "{_field_} is required",
})

extend("required_if_not", {
  ...required_if,
  // params: ['target']
  validate: (value, args: any) => {
    const target_value = args.target
    return Boolean(target_value || value)
  },
  message: "{_field_} is required",
})

extend("max", {
  ...max,
  message: "{_field_} cannot be longer than {length} characters",
})

extend("max_value", {
  ...max_value,
  message: "{_field_} cannot be greater than {max}",
  params: ["max"],
})

extend("min", {
  ...min,
  message: "{_field_} cannot be shorter than {length} characters",
})

extend("decimal", {
  validate: (value: string) => {
    return !!parseFloat(value)
  },
  message: "This {_field_} must be a number",
})

// check if youtube url
extend("youtube", {
  validate: (value: string) => {
    const pattern = new RegExp("^(https?://)?(www.youtube.com|youtu.be)/.+$") // fragment locator
    return !!pattern.test(value)
  },
  message: "{_field_} must be a valid youtube url",
})

extend("double", {
  ...double,
  message: "{_field_} must be a decimal number. example: 100.00",
})
extend("email", {
  ...email,
  message: "{_field_} must be a valid email",
})

extend("url", {
  validate: (value) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator
    return !!pattern.test(value)
  },
  message: "{_field_} must be a valid url",
})

extend("websiteOrEmail", {
  validate: (value) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator

    return !!urlPattern.test(value) || email.validate(value)
  },
  message: "{_field_} must be a valid website or email",
})

extend("aged18", {
  validate(value) {
    if (!value) return false
    const date = moment(value)
    return moment().diff(date, "years") >= 18
  },
  message: "You must be at least 18 years old",
})

extend("numeric", {
  ...numeric,
  message: "{_field_} must be a whole number",
})

extend("float", {
  validate: (value) => {
    return !isNaN(parseFloat(value))
  },
  message: "{_field_} must be number",
})

extend("positive", {
  validate: (value) => {
    return value >= 0
  },
  message: "{_field_} must be a positive number",
})

extend("positive", {
  validate: (value) => {
    return value >= 0
  },
  message: "{_field_} must be a positive number",
})

extend("phone", {
  validate: (value) => {
    return isValidNumber(value)
  },
  message: "{_field_} must be a valid international phone number e.g. +XXX XXXXXXX",
})

extend("internationalPhone", {
  validate: (value: PhoneInputType) => {
    return value.countryCode && value.number && value.number.length > 0
  },
  message: "{_field_} must be a valid international phone number e.g. +XXX XXXXXXX",
})

extend("is", {
  ...is,
  message: "{_field_} does not match",
})

extend("min-word", {
  validate: (value, args: any) => {
    // const regex = /\b\w{100,}\b/g
    return value.split(/\b\W+\b/).length >= args.min
  },
  message: "{_field_} cannot be shorter than {min} words",
  params: ["min"],
})
