import Vue from "vue"
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router"
import Index from "@/views/Index.vue"
import Routes from "@/router/routes"
import { can, isConsultant, isCoreTeam, isClient } from "@/mixins/Authorizer"
import store from "@/store"
import SessionHistory from "@/services/SessionHistory"
import { AuthState } from "@/store/auth"
import useConsultantRoutes from "./useConsultantRoutes"
import useTalentRoutes from "./useTalentRoutes"
import useAdminRoutes from "./useAdminRoutes"
import useCoreRoutes from "./useCoreRoutes"
import useClientRoutes from "./useClientRoutes"
import useRouter from "./useRouter"
import { AppDomain } from "@/constants"
import { PermissionEnum } from "@/gql"

const getUser = async () => {
  await store.dispatch("auth/restoreSession")
}

getUser()

const { setPostSignInRedirectRoute } = useRouter()

const routes: any[] = [
  {
    path: "/",
    component: Index,
    // Authenticated routes
    children: [
      isCoreTeamUser() && useCoreRoutes(user()),
      isConsultantUser() && useConsultantRoutes(user()),
      isClientUser() && useClientRoutes(),
      isTalentTeam() && {
        path: "/talent",
        component: { render: (h: any) => h("router-view") },
        children: useTalentRoutes(),
      },
      isAdmin() && {
        path: "/admin",
        component: { render: (h: any) => h("router-view") },
        children: useAdminRoutes(),
      },
      {
        path: "",
        name: Routes.Home,
        component: () => import("../views/Home.vue"),
        meta: {
          navigatesTo: "Home",
        },
      },
      {
        path: "profile",
        name: Routes.Profile,
        component: () => import("../views/MyProfile.vue"),
        meta: {
          navigatesTo: "My Profile",
        },
      },

      {
        path: "profile/edit",
        name: Routes.EditProfile,
        component: () => import("../views/consultant/EditProfile.vue"),
        meta: {
          navigatesTo: "Edit Profile",
        },
      },
      {
        path: "surveys/:id",
        name: Routes.SurveyDetail,
        component: () => import("../views/SurveyAssignmentDetail.vue"),
        meta: {
          navigatesTo: true,
        },
      },
      {
        path: "tasks",
        name: Routes.Tasks,
        component: () => import("../views/Tasks.vue"),
        meta: {
          navigatesTo: "Tasks",
        },
      },
      {
        path: "contracts",
        name: Routes.ConsultantContracts,
        component: () => import("../views/ConsultantContracts.vue"),
        meta: {
          navigatesTo: "Contracts",
        },
      },
      {
        path: "contracts/:id",
        name: Routes.ContractDetail,
        component: () => import("../views/consultant/ContractDetail.vue"),
        meta: {
          navigatesTo: true,
        },
      },
      {
        path: "/brand-resources",
        name: Routes.BrandResources,
        component: () => import("../views/BrandResources.vue"),
        meta: {
          navigatesTo: "Brand Resources",
        },
      },
    ]
      .flat()
      .filter(Boolean),
  },
  {
    path: "/surveys/:id/share",
    name: Routes.CompleteSurvey,
    component: () =>
      import(/* webpackChunkName: "complete-survey" */ "../views/CompleteSurvey.vue"),
  },

  {
    path: "/apply/:id",
    name: Routes.Apply,
    component: () => import(/* webpackChunkName: "apply" */ "../views/Apply.vue"),
    meta: {
      navigatesTo: true,
      domain: AppDomain.Apply,
    },
  },
  {
    path: "/apply/:id/password",
    name: Routes.ResetApplicantPassword,
    component: () => import(/* webpackChunkName: "reset" */ "../views/ApplicantResetPassword.vue"),
    beforeEnter: guardAuth,
  },
  {
    path: "/roles",
    name: Routes.Roles,
    component: () => import(/* webpackChunkName: "roles" */ "../views/Roles.vue"),
  },
  {
    path: "/password/reset",
    name: Routes.ResetPassword,
    component: () => import(/* webpackChunkName: "reset" */ "../views/ResetPassword.vue"),
    beforeEnter: guardAuth,
  },
  {
    path: "/password/forgot",
    name: Routes.ForgotPassword,
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ "../components/auth/ForgotPassword.vue"),
    beforeEnter: guardAuth,
  },

  {
    path: "/privacy-policy",
    name: Routes.PrivacyPolicy,
    component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/terms",
    name: Routes.TermsAndConditions,
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "../views/TermsAndConditionsV2.vue"),
  },
  {
    path: "/cookies",
    name: Routes.Cookies,
    component: () => import(/* webpackChunkName: "cookies" */ "../views/Cookies.vue"),
  },
  {
    path: "/login",
    name: Routes.Login,
    component: () => import(/* webpackChunkName: "login" */ "../components/auth/Login/Login.vue"),
    beforeEnter: guardAuth,
  },
  {
    path: "/become-a-client",
    name: Routes.BecomeAClient,
    component: () =>
      import(
        /* webpackChunkName: "become-a-client" */ "../views/client/onboarding/peralta/PeraltaClientSignUpFlow.vue"
      ),
    beforeEnter: guardAuth,
  },
  {
    path: "/confirm-email",
    name: Routes.ConfirmEmail,
    component: () => import(/* webpackChunkName: "confirm-email" */ "../views/ConfirmEmail.vue"),
  },
  {
    path: "/invitation/:token",
    name: Routes.Invitation,
    component: () =>
      import(/* webpackChunkName: "user-onboarding" */ "../views/Invitation/InvitationFlow.vue"),
  },
  {
    path: "/signup/ahead_business",
    component: { render: (h: any) => h("router-view") },
    children: [
      {
        path: "",
        name: Routes.AheadClientSignup,
        component: () =>
          import(
            /* webpackChunkName: "sign-up/ahead" */ "../views/client/onboarding/aheadBusiness/Signup.vue"
          ),
      },
      {
        path: "success",
        name: Routes.AheadClientSignupSuccess,

        component: () =>
          import(
            /* webpackChunkName: "sign-up/ahead/success" */ "../views/client/onboarding/aheadBusiness/SignupSuccess.vue"
          ),
      },
    ],
  },

  {
    path: "*",
    component: () => import(/* webpackChunkName: "page-not-found" */ "../views/PageNotFound.vue"),
    beforeEnter: handleNotFound,
  },
].filter(Boolean) as RouteConfig[]
// Filter out falsy routes due to conditional routing

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const sessionHistory = new SessionHistory()

router.afterEach((to: Route) => {
  if (to.meta?.navigatesTo) {
    if (to.meta.navigatesTo === true)
      return sessionHistory.pushRoute({
        text: to.params.id,
        href: to.fullPath,
        name: to.name,
        domain: to.meta.domain,
      })

    if (to.meta.navigatesTo === "Home") return sessionHistory.setHome()

    return sessionHistory.setRoute(1, {
      text: to.meta.navigatesTo,
      href: to.fullPath,
      name: to.name,
    })
  }
})

Vue.use(VueRouter)

function user(): AuthState["user"] {
  return store.getters["auth/getCurrentUser"]
}

function isAdmin() {
  return can(user(), [PermissionEnum.Admin])
}

function isCoreTeamUser() {
  return isCoreTeam(user())
}

function isTalentTeam() {
  return can(user(), [PermissionEnum.TalentTeam, PermissionEnum.Admin])
}

function isConsultantUser() {
  return isConsultant(user())
}

function isClientUser() {
  return isClient(user())
}

async function guardAuth(to: Route, from: Route, next: NavigationGuardNext) {
  if (!user()) next()
  else next("/")
}

function handleNotFound(to: Route, from: Route, next: NavigationGuardNext) {
  if (!user()) {
    setPostSignInRedirectRoute(to.fullPath)

    next("/login")
    return
  }
  next()
}

export default router
