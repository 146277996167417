import { Module, GetterTree, ActionTree, MutationTree } from "vuex"
import { RootState } from "../index"

const namespaced = true

export const state: AlertsState = {
  alerts: {},
}

const actions: ActionTree<AlertsState, RootState> = {
  addAlert({ commit }, payload: AlertPayload) {
    const id =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const alert: Alert = {
      id,
      msg: payload.msg,
      type: payload.type,
      prominent: !!payload.prominent,
      display: !!payload.display,
    }
    commit("saveAlert", alert)
    return alert
  },
  showAlert({ commit }, alert: Alert) {
    commit("saveAlert", { ...alert, display: true })
  },
  hideAlert({ commit }, alert: Alert) {
    commit("saveAlert", { ...alert, display: false })
  },
  removeAlert({ commit }, alert: Alert) {
    commit("removeAlert", alert)
  },
}

const mutations: MutationTree<AlertsState> = {
  saveAlert(state, alert: Alert) {
    const alerts = { ...state.alerts }
    alerts[alert.id] = alert
    state.alerts = alerts
  },

  removeAlert(state, alert: Alert) {
    const alerts = { ...state.alerts }
    delete alerts[alert.id]
    state.alerts = alerts
  },

  reset(state) {
    state.alerts = {}
  },
}

const getters: GetterTree<AlertsState, RootState> = {
  getAlerts(state) {
    return Object.values(state.alerts)
  },
}

export const alerts: Module<AlertsState, RootState> = {
  namespaced,
  state,
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
}

// types

export type Alert = {
  id: string
  msg: string
  type: "success" | "info" | "warning" | "error"
  prominent: boolean
  display: boolean
}

export type AlertPayload = {
  msg: string
  type: "success" | "info" | "warning" | "error"
  prominent?: boolean
  display?: boolean
}

export interface AlertsState {
  alerts: { [key: string]: Alert }
}
