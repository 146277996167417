
import { Component, Prop, Vue } from "vue-property-decorator"

export enum TagColor {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
  Orange = "#9d5e00",
  LimeGreen = "#756f00",
  Purple = "#b13cce",
  Black = "black",
}

@Component
export default class TagChip extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ default: false }) closable!: boolean
  @Prop({ default: false }) outline!: boolean
  @Prop({ default: "14" }) size?: string
  @Prop({ required: false, default: TagColor.Black }) color!: TagColor

  backgroundColorMap = {
    [TagColor.Success]: "success",
    [TagColor.Info]: "info",
    [TagColor.Warning]: "warning",
    [TagColor.Error]: "error",
    [TagColor.Orange]: "#fff1e3",
    [TagColor.LimeGreen]: "#f7f5da",
    [TagColor.Purple]: "#fcebff",
    [TagColor.Black]: "grey lighten-2",
  }

  get attrs() {
    const { label, ...attrs } = this.$attrs
    return attrs
  }

  get textColor() {
    return this.color
  }

  close() {
    //Remove chip
    this.$emit("close")
  }

  get fontSizeClass() {
    return `font-size-${this.size}`
  }

  get backgroundColor() {
    return this.backgroundColorMap[this.color]
  }
}
