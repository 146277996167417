import { render, staticRenderFns } from "./ClientNav.vue?vue&type=template&id=56aa2554&"
import script from "./ClientNav.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ClientNav.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports