
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class OutlinedTextarea extends Vue {
  @Prop()
  readonly label?: string

  @Prop()
  readonly required?: boolean

  @Prop()
  readonly labelEndAdornment?: string

  get attrs() {
    const { label, prependIcon, ...attrs } = this.$attrs
    return attrs
  }
}
