
import { Component, Prop, Vue, Emit } from "vue-property-decorator"
import { Theme } from "@/types"
import { THEMES } from "@/constants"
@Component
export default class ConfirmDialog extends Vue {
  @Prop() value!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop({ default: false }) readonly disableConfirmIf?: boolean
  @Prop({ default: true }) readonly confirmButtonVisible?: boolean
  @Prop({ default: true }) readonly showActionButtons?: boolean
  @Prop({ default: false }) readonly persistent?: boolean

  @Prop({ default: "Confirm" })
  confirmText?: string

  @Prop({ default: "Are you sure?" }) title?: string

  @Prop({ default: "Cancel" })
  cancelText?: string

  @Prop({
    default: () => ({
      primaryColor: THEMES.PERALTA_PRIMARY,
    }),
  })
  theme?: Theme

  @Emit("action")
  @Emit("confirm")
  onConfirm() {
    return true
  }

  @Emit("action")
  @Emit("cancel")
  onCancel() {
    return false
  }

  @Emit("input")
  onInput(value: any) {
    if (!value) this.onCancel()

    return value
  }
}
