import { OperationVariables } from "apollo-client"
import { DocumentNode } from "graphql"
import { MutationUpdaterFn, RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { ErrorV2 } from "@/gql"
import { FetchResult } from "apollo-link"
import { useNotification } from "./useNotification"
import { useMutation as _useMutation } from "@vue/apollo-composable"
import { provideApolloClient } from "@vue/apollo-composable"
import { apolloClient } from "@/main"

type Mutation = {
  mutate: <
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(
    options: MutationOptions<T, Q>
  ) => Promise<FetchResult<T> | null | undefined>
}

type MutationOptions<
  T extends Record<string, any> = Record<string, any>,
  Q extends OperationVariables = OperationVariables
> = {
  mutation: DocumentNode
  variables?: Q
  refetchQueries?: RefetchQueryDescription
  done?: (data: FetchResult<T, Record<string, any>, Record<string, any>> | null) => any
  error?: (error: ErrorV2) => any
  success?: (data: T) => void
  update?: MutationUpdaterFn<T>
  handlePayloadErrors?: boolean
}

export const useMutation = (): Mutation => {
  async function mutate<
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(options: MutationOptions<T, Q>): Promise<FetchResult<T> | null | undefined> {
    const { addGraphQLError, addMutationError } = useNotification()

    const handlePayloadErrors = options.handlePayloadErrors ?? true
    // returns Promise of T
    const { mutate, onError, onDone } = provideApolloClient(apolloClient)(() =>
      _useMutation<T>(options.mutation, {
        variables: options.variables,
        refetchQueries: options.refetchQueries,
      })
    )

    onError((error) => {
      handlePayloadErrors && addGraphQLError(error as Error)
    })

    onDone((result) => {
      // Handle mutation errors
      if (!result.data) return

      const mutationKey = Object.keys(result.data)[0],
        errorResponse = (result.data[mutationKey as keyof T] as T[keyof T] & { error: ErrorV2 })
          .error

      if (errorResponse) {
        if (options.error) options.error.call(result, errorResponse)
        else addMutationError(errorResponse)
      } else {
        if (options.success) options.success.call(result, result.data)
      }

      return result
    })

    const result = await mutate()

    options.done?.(result)

    return result
  }

  return { mutate }
}
