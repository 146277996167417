
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { InstitutionsSearchQuery } from "@/gql"

@Component
export default class InstitutionSelect extends Vue {
  @Prop()
  readonly required?: boolean

  readonly InstitutionsSearchQuery = InstitutionsSearchQuery

  search: string | null = null
  searchInput: string | null = null
  timeout: ReturnType<typeof setTimeout> | null = null

  updateSearch(func: () => void) {
    if (this.timeout) clearTimeout(this.timeout)

    let timeout = this.timeout

    // Set new timeout
    this.timeout = setTimeout(function () {
      timeout = null

      func.call(timeout)
    }, 1000)
  }

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, ...attrs } = this.$attrs
      return attrs
    } else return this.$attrs
  }

  @Watch("searchInput")
  onChange() {
    const searchInput = this.searchInput

    this.updateSearch(() => {
      this.search = searchInput
    })
  }
}
