
import { Component, Vue, Prop } from "vue-property-decorator"
import { CountriesQuery } from "@/gql"

@Component
export default class CountrySelect extends Vue {
  readonly countriesQuery = CountriesQuery

  @Prop({ default: true })
  readonly returnObject!: boolean

  @Prop({ default: "id" })
  readonly itemValue!: boolean

  @Prop()
  readonly required?: boolean

  @Prop()
  readonly errorMessages?: string[]

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, ...attrs } = this.$attrs
      return attrs
    } else return this.$attrs
  }
}
