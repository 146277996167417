import { REDIRECT_ROUTE_KEY } from "@/constants"
import VueRouter, { RawLocation, Route } from "vue-router"
import router from "../router/index"
import routes from "@/router/routes"

export interface UseRouterValue {
  postSignInRedirectRoute: () => string | null
  setPostSignInRedirectRoute: (route: string) => void
  unsetPostSignInRedirectRoute: () => void
  navigate: (route: string) => void
  router: VueRouter
  routes: typeof routes
  navigateTo: (route: RawLocation) => Promise<Route>
}

function setPostSignInRedirectRoute(route: string) {
  localStorage.setItem(REDIRECT_ROUTE_KEY, route)
}

function unsetPostSignInRedirectRoute() {
  localStorage.removeItem(REDIRECT_ROUTE_KEY)
}

function postSignInRedirectRoute(): string | null {
  return localStorage.getItem(REDIRECT_ROUTE_KEY)
}

function navigate(route: string) {
  window.location.assign(route)
}

function navigateTo(route: RawLocation) {
  return router.push(route)
}

const useRouter = (): UseRouterValue => {
  return {
    postSignInRedirectRoute,
    setPostSignInRedirectRoute,
    unsetPostSignInRedirectRoute,
    navigate,
    router,
    routes,
    navigateTo,
  }
}

export default useRouter
