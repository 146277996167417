
import { ActivityLog } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import ActivityItem from "@/components/widgets/timeline/ActivityItem.vue"

@Component({
  components: {
    ActivityItem,
  },
})
export default class ActivityTimeline extends Vue {
  @Prop({ default: () => [] }) readonly logs!: ActivityLog[]
  @Prop({ default: true }) readonly dense?: boolean

  attributes(action: string): { icon: string; color?: string } {
    switch (action.split("_")[0]) {
      case "create":
      case "add":
        return { icon: "plus", color: "blue" }

      case "edit":
        return { icon: "plus" }

      case "update":
        return { icon: "pencil-alt" }

      case "forward":
        return { icon: "angle-right" }

      case "backward":
        return { icon: "angle-left" }

      case "start":
      case "commence":
        return { icon: "play" }

      case "publish":
        return { icon: "share" }

      case "unpublish":
        return { icon: "eye-slash" }

      case "request":
        return { icon: "user-check" }

      case "upload":
        return { icon: "cloud-upload-alt" }

      case "fill":
        return { icon: "cloud-upload-alt" }

      case "accept":
      case "approve":
      case "grant":
        return { icon: "check", color: "green" }

      case "reject":
        return { icon: "times", color: "red" }

      case "close":
        return { icon: "power-off", color: "red" }

      case "user":
        return { icon: "user" }

      case "archive":
        return { icon: "archive", color: "orange" }

      case "send":
        return { icon: "share" }

      default:
        return { icon: "circle" }
    }
  }
}
