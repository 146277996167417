
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ClientsSearchQuery } from "@/gql"

@Component
export default class ClientSelect extends Vue {
  readonly Query = ClientsSearchQuery

  readonly search: string | null = null
  selectedId: number | null = null

  @Prop({ default: true })
  readonly returnObject!: boolean

  @Prop({ required: true }) readonly value!: any

  @Watch("value", { immediate: true })
  onValueChange() {
    if (this.value) {
      this.selectedId = !this.returnObject ? this.value : this.value.id
    }
  }
}
