
import { Component, Vue, Prop } from "vue-property-decorator"
import { UserAdvanced } from "@/gql"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    UserAvatar,
  },
})
export default class UserAvatarGroup extends Vue {
  @Prop() readonly users!: UserAdvanced[]
  @Prop({ default: 32 }) readonly size?: string
  @Prop({ default: false }) readonly outline?: boolean
  @Prop({ required: false }) readonly label?: string
  @Prop({ required: false }) readonly color?: string

  AVATAR_GROUP_MAX_LENGTH = 3
}
