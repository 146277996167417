import store from "@/store"
import { can } from "@/mixins/Authorizer"
import { useMutation } from "@/hooks/useMutation"
import {
  LoginMutationMutation,
  LoginMutation,
  PermissionEnum,
  UserAdvancedFragmentFragment,
} from "@/gql"
import { StartSessionPayload, UpdateSessionPayload } from "@/store/auth"
import { apolloProvider } from "@/main"
import { useNotification } from "@/hooks/useNotification"

const { addMutationError } = useNotification()

export type UseAuthReturnValue = {
  isAuthenticated: boolean
  getUser: () => UserAdvancedFragmentFragment | undefined
  user: UserAdvancedFragmentFragment | undefined
  can: (...permissions: PermissionEnum[]) => boolean
  login: (payload: { email: string; password: string }) => void
  isBizDevTeam: () => boolean
  updateSession: (user: UpdateSessionPayload) => void
}

function getUser(): UserAdvancedFragmentFragment | undefined {
  return store.getters["auth/getCurrentUser"]
}

function checkUserPriveledges(...permissions: PermissionEnum[]): boolean {
  return can(store.state.auth?.user, permissions)
}

function startSession({ user, token }: StartSessionPayload) {
  store.commit("auth/startSession", { user, token, apolloProvider })
}

function updateSession(user: UpdateSessionPayload) {
  store.commit("auth/updateSession", { user })
}

function isBizDevTeam() {
  return can(store.state.auth?.user, [PermissionEnum.BizdevTeam])
}

async function login({ email, password }: { email: string; password: string }) {
  const { mutate } = useMutation()

  const result = await mutate<LoginMutationMutation>({
    mutation: LoginMutation,
    variables: {
      email,
      password,
      mode: "password",
    },
    handlePayloadErrors: true,
  })

  if (result?.data?.login.user && result.data.login.token) {
    startSession({
      user: result.data.login.user,
      token: `Bearer ${result.data.login.token}`,
    })
  } else if (result?.data?.login.error) {
    addMutationError(result.data.login.error as Error)
  }
}

export const useAuth = (): UseAuthReturnValue => {
  return {
    isAuthenticated: !!store.state.auth?.user,
    getUser,
    user: store.state.auth?.user,
    can: checkUserPriveledges,
    login,
    isBizDevTeam,
    updateSession,
  }
}
