import { render, staticRenderFns } from "./DocumentUploader.vue?vue&type=template&id=a8a0d32a&scoped=true&"
import script from "./DocumentUploader.vue?vue&type=script&lang=ts&"
export * from "./DocumentUploader.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentUploader.vue?vue&type=style&index=0&id=a8a0d32a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8a0d32a",
  null
  
)

export default component.exports