
import { VBtn } from "vuetify/lib"
import { VBtnToggle } from "vuetify/lib"

import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    "v-btn": VBtn,
    "v-btn-toggle": VBtnToggle,
  },
})
export default class Tabs extends Vue {
  @Prop() readonly tabs!: { label: string; key: string; badge?: number }[]

  tabState = 0

  updateTabState(index: number) {
    this.tabState = index
  }

  activeTab() {
    return this.$slots.default?.[this.tabState]
  }

  tools() {
    return this.$slots?.tools
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(h: any) {
    return (
      <div>
        <div class="d-flex align-center justify-space-between ">
          <div class="tab-container d-flex align-center justify-center pa-2">
            {this.tabs.map((tab, index) => (
              <button
                class={{ active: index === this.tabState }}
                onClick={() => this.updateTabState(index)}
              >
                {tab.label}
                {tab.badge && (
                  <span class="custom-badge d-flex align-center justify-center rounded-circle ml-2">
                    {tab.badge}
                  </span>
                )}
              </button>
            ))}
          </div>
          {this.tools && this.tools()}
        </div>
        {this.activeTab()}
      </div>
    )
  }
}
