import Vue from "vue"
import Vuetify from "vuetify"
import { ClickOutside } from "vuetify/es5/directives"

Vue.use(Vuetify, {
  directives: {
    ClickOutside,
  },
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        aheadPrimary: {
          base: "#FF8200",
        },
        primary: "#FF0008",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#fff",
        primaryGray: "#8C8C8C",
        primaryBlue: "#007cff",
        "custom-light-green": "#DEFFCE",
        "custom-deep-green": "#179717",
        "custom-light-red": "#FFE5E5",
        "bg-grey": "#F5F6F7",
        "custom-deep-orange": "#D87D21",
        "custom-light-orange": "#FFE8D1",
        "custom-deep-blue": "#2F80ED",
        "custom-light-blue": "#D5E6FB",
      },
    },
  },
  icons: {
    values: {
      dropdown: "la-angle-down",
      checkboxOn: "la-check-square",
      checkboxOff: "la-stop",
    },
  },
})
