import Routes from "@/router/routes"

const useAdminRoutes = (): any[] => {
  return [
    {
      path: "users",
      name: Routes.Users,
      component: () => import(/* webpackChunkName: "admin-users" */ "../views/admin/Users.vue"),
      meta: {
        navigatesTo: "Users",
      },
    },
    {
      path: "user/:id",
      name: Routes.UserDetail,
      component: () => import(/* webpackChunkName: "user-roles" */ "../views/admin/UserDetail.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "user-roles",
      name: Routes.UserRoles,
      component: () => import(/* webpackChunkName: "user-roles" */ "../views/admin/UserRoles.vue"),
      meta: {
        navigatesTo: "User Roles",
      },
    },
    {
      path: "user-roles/:id",
      name: Routes.UserRoleDetail,
      component: () =>
        import(/* webpackChunkName: "user-roles" */ "../views/admin/UserRoleDetail.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "permissions",
      name: Routes.Permissions,
      component: () =>
        import(/* webpackChunkName: "permission" */ "../views/admin/Permissions.vue"),
      meta: {
        navigatesTo: "Permissions",
      },
    },
  ]
}

export default useAdminRoutes
