import { RootState } from "../index"
import { Module, ActionTree, MutationTree, GetterTree } from "vuex"
import { SESSION_HISTORY_KEY } from "@/constants"

const namespaced = true

export const state: SessionHistoryState = {
  history: [
    {
      text: "Home",
      href: "/",
    },
  ],
}

const actions: ActionTree<SessionHistoryState, RootState> = {
  saveHistory({ commit }, payload: SessionHistoryStoreItem[]) {
    commit("saveHistory", payload)
    localStorage.setItem(SESSION_HISTORY_KEY, JSON.stringify(payload))
  },
}

const mutations: MutationTree<SessionHistoryState> = {
  saveHistory(state, payload: SessionHistoryStoreItem[]) {
    state.history = payload
  },

  reset(state) {
    state.history = []
  },
}

const getters: GetterTree<SessionHistoryState, RootState> = {
  getHistory(state) {
    return state.history
  },
}

export const sessionHistory: Module<SessionHistoryState, RootState> = {
  namespaced,
  state,
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
}

export interface SessionHistoryState {
  history: SessionHistoryStoreItem[]
}

export interface SessionHistoryStoreItem {
  text: string
  disabled?: boolean
  href: string
  name?: string | null
  domain?: string
}
