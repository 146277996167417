
import { Component, Vue } from "vue-property-decorator"
import { CompaniesSearchQuery } from "@/gql"

@Component
export default class CompanyCombo extends Vue {
  readonly CompaniesSearchQuery = CompaniesSearchQuery

  readonly search: string | null = null

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, ...attrs } = this.$attrs
      return attrs
    } else return this.$attrs
  }
}
