
import {
  DeleteUploadFileMutation,
  DeleteUploadFilePayload,
  GeneratePresignedUrlMutation,
  GeneratePresignedUrlMutationMutation,
  UploadFile,
  UploadFileInput,
} from "@/gql"
import { Component, Prop, Vue, Emit, Ref, Watch } from "vue-property-decorator"
import axios, { AxiosResponse } from "axios"

@Component
export default class Dropzone extends Vue {
  @Ref() readonly fileUpload!: HTMLInputElement
  @Ref() readonly dropzone!: HTMLDivElement

  @Prop() readonly name?: string
  @Prop() readonly folder?: string
  @Prop() readonly accept?: string
  @Prop({ default: false }) readonly public?: boolean
  @Prop({ default: true }) readonly showHeader?: boolean
  @Prop({ default: false }) readonly multiple?: boolean
  @Prop({ required: true }) readonly category!: string
  @Prop() readonly id?: string
  @Prop({ required: false }) readonly errorMessages?: string[]
  @Prop({ required: false }) readonly value?: Record<string, any>
  @Prop({ default: true }) showIcon?: boolean

  files: File[] = []
  hoveringContent: DataTransferItemList | null = null
  hoverCounter = 0

  showPreviewable = false
  isUploading = false
  dataFileNames: string[] | null = null
  dataURL: string | ArrayBuffer | null = null
  presignedUrl: string | null = null
  objectKeys: string[] | null = null
  deletingFile = false
  isUploadSuccessful = false
  uploadFailed = false
  uploadProgress = 0
  presignedFiles: UploadFileInput[] = []
  isInvalid = false
  invalidMessage = ""
  query = false
  show = true

  get uploadProgressPercentage() {
    return this.uploadProgress
  }

  get isPublic() {
    return this.public !== undefined && this.public !== false
  }

  get accepts() {
    return this.accept
  }

  get allowedExtensions() {
    return this.accepts ? this.accepts.split(",").map((ext) => ext.trim().replace(".", "")) : []
  }

  async handleUpload(files: File[]) {
    if (files.length > 0) {
      let result = await Promise.all(
        this.files.map(async (file) => {
          const url = await this.getPresignedUrl(file.name)
          return {
            url,
            file,
          }
        })
      )

      if (result) {
        // store mapped presigned key and file object
        this.presignedFiles = result.map(({ file, url }) => {
          return {
            name: file.name,
            extension: this.getFileExtension(file.name),
            key: url!.key,
            public: this.isPublic,
            category: this.category,
          }
        })

        // Upload multiple files to S3

        await Promise.all(
          result.map(async (uploaded) => {
            const { url, file } = uploaded
            return await this.uploadFileToS3(url!.url, file)
          })
        )
      }
    }
  }

  async handleSelect(e: Event) {
    const target = e.target as HTMLInputElement
    const files = Array.from(target.files as FileList)

    this.files = files
    this.$emit("onFileChange", files)
    this.handleUpload(files)
  }

  dragenter(e: DragEvent) {
    this.hoveringContent = e.dataTransfer!.items
    this.hoverCounter++
  }
  /** Counts leave events (fix for event rippling issues) */
  dragleave() {
    this.hoverCounter--
  }

  drop(e: DragEvent) {
    e.preventDefault()
    this.hoverCounter = 0

    if (e.dataTransfer!.items) {
      const rejected = []

      for (let i = 0; i < e.dataTransfer!.items.length; i++) {
        if (e.dataTransfer!.items[i].kind === "file") {
          if (e.dataTransfer!.items[i].webkitGetAsEntry()) {
            const entry = e.dataTransfer!.items[i].webkitGetAsEntry()

            if (entry!.isDirectory) {
              rejected.push(entry!.name)
              continue
            }
          }

          const file = e.dataTransfer!.items[i].getAsFile()

          if (file) {
            const canPush = this.allowedExtensions.length
              ? this.allowedExtensions.includes(this.getFileExtension(file.name))
              : true

            if (canPush) {
              this.isInvalid = false

              this.files = [...this.files, file]
              this.handleUpload(this.files)
            } else {
              // Set invalid to true and show error message
              this.isInvalid = true
              this.invalidMessage = "Invalid file type " + this.getFileExtension(file.name)
              rejected.push(file)
              continue
            }
          }
        } else continue
      }
    }
  }

  getFileExtension(filename: string) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
  }

  async getPresignedUrl(filename: string) {
    try {
      this.query = true
      this.$emit("upload")

      const result = await this.$apollo.mutate<GeneratePresignedUrlMutationMutation>({
        mutation: GeneratePresignedUrlMutation,
        variables: {
          folder: this.folder,
          filename: `${filename}`,
          public: this.isPublic,
        },
      })

      if (result && result.data) {
        const error = result.data.generatePresignedUrl.error

        if (error) this.addMutationError(error)
        else return result.data.generatePresignedUrl.presignedUrl
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.query = false
    }
  }

  async uploadFileToS3(url: string, file: File) {
    // Upload to S3

    const config = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.uploadProgress = percentCompleted
      },
    }

    try {
      this.isUploading = true
      const response = await axios.put(url, file, config)

      response && this.uploadComplete(response)
    } catch (e) {
      this.uploadFailed = true
      this.addError("Network error: File upload failed")
    } finally {
      this.isUploading = false
    }
  }

  async retryUpload(file: File) {
    let selected = this.files.filter((f) => f.name === file.name)
    this.handleUpload([selected[0]])
  }

  async onDeleteFile(file: UploadFile) {
    if (!file.id) return
    const result = await this.mutate<DeleteUploadFilePayload>({
      mutation: DeleteUploadFileMutation,
      variables: {
        id: Number(file.id),
      },
      done: () => {
        this.deletingFile = false
      },
    })

    if (result.data && !result.data.error) {
      this.addSuccess("Uploaded File deleted successfully")
    }
  }

  @Watch("hoveringContent")
  onhoveringContentChanged(items: DataTransferItemList) {
    // If a file is hovering
    if (items) {
      let shouldDim = false
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "file") {
          shouldDim = true
          break
        }
      }

      if (shouldDim) {
        this.dropzone.style.border = "1px dashed #da2024"
        this.dropzone.style.backgroundColor = "#f5f5f5"
      }
    } else {
      this.dropzone.style.border = "1px dashed #ccc"
      this.dropzone.style.backgroundColor = "#fff"
    }
  }

  @Watch("hoverCounter")
  onHoverCounterChanged(val: number) {
    if (val === 0) this.hoveringContent = null
  }

  reset() {
    this.files = []
    this.presignedFiles = []
  }

  @Emit("change")
  @Emit("input")
  uploadComplete(response: AxiosResponse) {
    if (response.status === 200) {
      this.isUploadSuccessful = true
      return this.multiple ? this.presignedFiles : this.presignedFiles[0]
    } else {
      this.isUploadSuccessful = false
      this.uploadFailed = true
      this.addError("File upload failed")
    }
    return null
  }
}
