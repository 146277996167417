
import { Component, Prop, Vue } from "vue-property-decorator"
import CommentCard from "./CommentCard.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    CommentCard,
    UserAvatar,
  },
})
export default class Commentable extends Vue {
  @Prop({ required: true }) readonly comments!: any
  @Prop({ required: false, default: "commentable-document" })
  readonly commentableDocumentClass!: string
  comment = ""
  showCommentsCard = false

  elementOffset = {
    top: 0,
    left: 0,
  }

  toggleCommentsCard() {
    this.showCommentsCard = !this.showCommentsCard
    this.$emit("focus", "left")
  }

  closeComments() {
    this.showCommentsCard = false
    this.$emit("focus", "center")
  }

  onClickOutside() {
    this.closeComments()
  }

  onAddComment() {
    const comment = {
      firstName: this.currentUser?.firstName,
      lastName: this.currentUser?.lastName,
      avatar: this.currentUser?.avatar,
      comment: this.comment,
      createdAt: new Date().toISOString(),
    }

    this.$emit("addComment", comment)
    this.comment = ""
  }

  onCommentableBlockHover() {
    const blockElement = this.$el
    const docElement = document.querySelector(`.${this.commentableDocumentClass}`) as HTMLElement

    const rect = blockElement.getBoundingClientRect()
    const documentRect = docElement.getBoundingClientRect()

    const elementOffset = {
      top: rect.top - documentRect?.top,
      left: rect.left - documentRect?.left,
    }

    this.$emit("hover", {
      comments: this.comments,
      position: elementOffset,
      isHovered: true,
    })
  }
}
