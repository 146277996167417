import { render, staticRenderFns } from "./CommentableActivator.vue?vue&type=template&id=cbab0cc8&scoped=true&"
import script from "./CommentableActivator.vue?vue&type=script&lang=ts&"
export * from "./CommentableActivator.vue?vue&type=script&lang=ts&"
import style0 from "./CommentableActivator.vue?vue&type=style&index=0&id=cbab0cc8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbab0cc8",
  null
  
)

export default component.exports