
import { Component, Prop, Vue } from "vue-property-decorator"
import { useTimeAgo } from "@/hooks/useTimeAgo"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    UserAvatar,
  },
})
export default class CommentCard extends Vue {
  @Prop({ required: true }) readonly comment!: any
  @Prop({ required: true }) readonly resolvable!: boolean

  timeAgo(date: Date) {
    return useTimeAgo(date)
  }
}
