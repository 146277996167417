
import { Component, Emit, Prop, Vue } from "vue-property-decorator"

@Component
export default class LinkDialog extends Vue {
  @Prop() readonly value!: boolean
  @Prop({ default: "" }) readonly title?: string
  @Prop({ default: "" }) readonly subTitle?: string
  @Prop({ required: true }) readonly link!: string

  error = false
  linkCopied = false

  @Emit("close")
  onClose() {
    this.linkCopied = false
    return false
  }
  @Emit("input")
  onInput(value: any) {
    if (!value) this.onClose()
    return value
  }

  copyLinkToClipboard() {
    navigator.clipboard
      .writeText(this.link)
      .then(() => {
        this.linkCopied = true
      })
      .catch(() => {
        this.error = true
      })
  }
}
