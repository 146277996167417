
import { Vue, Prop, Component } from "vue-property-decorator"
import ApprovalStateCard from "@/components/missions/ApprovalStateCard.vue"

import {
  Approval,
  GrantApprovalMutationMutation,
  GrantApprovalMutation,
  RejectApprovalMutation,
  RejectApprovalMutationMutation,
  RejectApprovalMutationMutationVariables,
  GrantApprovalMutationMutationVariables,
} from "@/gql"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({
  components: {
    ApprovalStateCard,
  },
})
export default class ApprovalActionButtons extends Vue {
  @Prop({ required: true })
  approval!: Approval

  @Prop({ required: false })
  refetchQueries!: RefetchQueryDescription

  @Prop({ required: false, default: false })
  dense?: boolean

  @Prop()
  metadata?: Record<string, any>

  @Prop()
  beforeReject?: () => boolean

  @Prop()
  hideHint?: boolean

  @Prop({ default: "Are you sure you want to grant approval?" })
  readonly confirmDialogText?: string

  approveLoading = false
  showRejectModal = false
  rejectLoading = false
  showGrantApprovalConfirm = false

  form: {
    rejectionReason: string
  } = {
    rejectionReason: "",
  }

  async onApproveConfirm(id: number) {
    try {
      this.approveLoading = true

      // Mutate
      const result = await this.$apollo.mutate<
        GrantApprovalMutationMutation,
        GrantApprovalMutationMutationVariables
      >({
        mutation: GrantApprovalMutation,
        variables: {
          id: id,
        },
        refetchQueries: this.refetchQueries,
      })

      // Done
      if (result.data) {
        if (result.data.grantApproval.error) {
          this.addMutationError(result.data.grantApproval.error)
        } else {
          this.addSuccess("Approved granted successfully!")
          this.showGrantApprovalConfirm = false
          this.$emit("approve", this.approval)
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    } finally {
      this.approveLoading = false
    }
  }

  async reject(metadata?: Record<string, any>) {
    try {
      this.rejectLoading = true
      // Mutate
      const result = await this.$apollo.mutate<
        RejectApprovalMutationMutation,
        RejectApprovalMutationMutationVariables
      >({
        mutation: RejectApprovalMutation,
        variables: {
          id: this.approval.id,
          reason: this.form.rejectionReason,
          metadata: metadata || this.metadata,
        },
        refetchQueries: this.refetchQueries,
      })

      // Done
      if (result.data) {
        if (result.data.rejectApproval.error) {
          this.addMutationError(result.data.rejectApproval.error)
        } else {
          this.addSuccess("Rejection successful!")
          this.showRejectModal = false
          this.$emit("reject", this.approval)
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    } finally {
      this.rejectLoading = false
    }
  }

  async onRejectConfirm() {
    if (this.beforeReject && typeof this.beforeReject === "function") {
      let success = this.beforeReject.call(this)
      if (!success) return
    } else {
      this.reject()
    }
  }
}
