var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    position: 'absolute',
    left: '-30px',
    top: _vm.position.top + 'px',
  })},[(!_vm.hasComments && !_vm.showCommentsCard)?_c('secondary-icon-button',{staticClass:"floating-button mr-2",attrs:{"vIcon":"la-plus","color":"#A7A9AC","iconSize":28,"size":50},on:{"click":_vm.toggleCommentsCard}}):(!_vm.showCommentsCard && _vm.hasComments)?_c('v-btn',{staticClass:"floating-button pa-0",attrs:{"outlined":"","icon":""},on:{"click":_vm.toggleCommentsCard}},[_c('v-badge',{attrs:{"content":_vm.comments.length,"color":"#FF0008","overlap":"","value":true,"offset-x":20,"offset-y":12}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"font-size":"32px","color":"#a7a9ac"},attrs:{"color":"#A7A9AC","size":28}},[_vm._v(" la-comment-alt ")])],1)],1):_vm._e(),(_vm.showCommentsCard)?_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"pa-3",staticStyle:{"width":"400px"}},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.hasComments)?_c('div',_vm._l((_vm.comments),function(comment,idx){return _c('comment-card',{key:idx,attrs:{"comment":comment,"resolvable":idx === 0}})}),1):_c('div',{staticClass:"d-flex justify-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('user-avatar',{staticClass:"mr-2",staticStyle:{"background-color":"#ffffff"},attrs:{"user":{
              firstName: _vm.currentUser.firstName,
              lastName: _vm.currentUser.lastName,
            },"size":32}}),_c('span',[_vm._v(_vm._s(_vm.currentUser.firstName)+" "+_vm._s(_vm.currentUser.lastName))])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeComments}},[_c('v-icon',[_vm._v("la-close")])],1)],1),_c('outlined-text-field',{staticClass:"mt-3 mb-3 custom-comment-input",attrs:{"placeholder":_vm.hasComments ? 'Send a reply' : 'Enter a comment',"append-icon":'la-send',"background-color":"transparent","solo":"","flat":"","hide-details":"","full-width":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onAddComment.apply(null, arguments)}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }