
import { Component, Prop, Vue, Emit } from "vue-property-decorator"

@Component
export default class Menu extends Vue {
  @Prop({ required: true }) readonly actions!: Record<string, (() => any) | boolean>
  @Prop({ default: "la-ellipsis-h" }) readonly icon?: string

  @Emit("action")
  onAction(action: string) {
    if (this.actions && typeof this.actions[action] === "function") {
      ;(this.actions[action] as () => any).call(this)
      return action
    }

    this.addError(`Unknown action: ${action}`)
  }
}
