
import { Component, Vue, Prop, Emit } from "vue-property-decorator"
import Dropzone from "@/components/widgets/input/Dropzone.vue"
import { UploadFile } from "@/gql"

@Component({
  components: {
    Dropzone,
  },
})
export default class DocumentUploader extends Vue {
  @Prop()
  readonly name?: string

  @Prop()
  readonly label?: string

  @Prop()
  readonly value?: UploadFile[] // Adds edit and download functionalities

  @Prop()
  readonly folder?: string // Add for upload functionality

  @Prop()
  readonly category?: string // Add for upload functionality

  @Prop({ default: false })
  readonly loading?: boolean

  @Prop({ default: false })
  readonly multiple?: boolean

  @Prop()
  readonly unique!: boolean

  @Prop()
  readonly accept?: string

  showUploadModal = false
  deletingFile = false
  uploadFiles: UploadFile[] | null = null

  @Emit("change")
  onUpload() {
    return this.notifyInput()
  }

  @Emit("input")
  notifyInput() {
    this.reset()
    return this.uploadFiles
  }

  @Emit("remove")
  removeFile(file: UploadFile) {
    return file
  }

  reset() {
    this.showUploadModal = false
    this.$refs.observer && (this.$refs.observer as any).reset()
    this.$refs.dropzone && (this.$refs.dropzone as any).reset()
  }
}
