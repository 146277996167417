
import { Component, Vue, Prop } from "vue-property-decorator"
import { AllJobTitlesQuery, JobTitlesFilter } from "@/gql"

@Component
export default class JobRoleSelect extends Vue {
  readonly AllJobTitlesQuery = AllJobTitlesQuery

  @Prop({ default: false })
  readonly multiple?: boolean

  @Prop({
    default: () => ({}),
  })
  filter!: { [key: string]: any }

  @Prop()
  readonly staffable?: boolean | undefined

  get useFilter(): JobTitlesFilter {
    return { staffable: this.staffable, ...this.filter }
  }
}
