
import { Component, Prop, Vue } from "vue-property-decorator"
import CommentCard from "./CommentCard.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    CommentCard,
    UserAvatar,
  },
})
export default class CommentableActivator extends Vue {
  @Prop({ required: true }) readonly comments!: any
  @Prop({ required: true }) readonly position!: {
    top: number
    left: number
  }
  @Prop() readonly contentAlignment!: string

  showCommentsCard = false
  comment = ""

  toggleCommentsCard() {
    this.showCommentsCard = !this.showCommentsCard

    this.$emit("update:contentAlignment", "left")
  }

  closeComments() {
    this.showCommentsCard = false
    this.$emit("update:contentAlignment", "center")
  }

  onClickOutside() {
    this.closeComments()
  }

  get hasComments() {
    return this.comments.length > 0
  }
}
