
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Draggable = require("@shopify/draggable")

import { Vue, Component, Prop, Provide } from "vue-property-decorator"

// This is a functional component

@Component
export default class SortableList extends Vue {
  @Prop({ required: true }) readonly value!: any[]
  @Prop({ default: "sortable-item" }) readonly itemClass!: string
  @Prop({ default: "sortable-handle" }) readonly handleClass!: string

  @Provide("itemClass") iClass = this.itemClass
  @Provide("handleClass") hClass = this.handleClass

  move(items: any[], oldIndex: number, newIndex: number) {
    const itemRemovedArray = [
      ...items.slice(0, oldIndex),
      ...items.slice(oldIndex + 1, items.length),
    ]

    return [
      ...itemRemovedArray.slice(0, newIndex),
      items[oldIndex],
      ...itemRemovedArray.slice(newIndex, itemRemovedArray.length),
    ]
  }

  render() {
    return this.$scopedSlots.default!({
      items: this.value,
    })
  }

  mounted() {
    new Draggable.Sortable(this.$el as HTMLElement, {
      draggable: `.${this.itemClass}`,
      handle: `.${this.handleClass}`,
      mirror: {
        constrainDimensions: true,
      },
      classes: {
        mirror: "sortable-item--dragging",
      },
    }).on("sortable:stop", ({ oldIndex, newIndex }: any): void => {
      this.$emit("input", this.move(this.value, oldIndex, newIndex))
      this.$emit("sort", { oldIndex, newIndex })
    })
  }
}
