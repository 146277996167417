import store from "@/store"
import { SessionHistoryStoreItem } from "@/store/routes"

interface HasSessionHistory {
  initialSession: SessionHistoryStoreItem[]
  setRoute: (index: number, route: SessionHistoryStoreItem) => void
  pushRoute: (route: SessionHistoryStoreItem) => void
  setHome: () => void
  changeRoute: (at: number | string, current: Partial<SessionHistoryStoreItem>) => Promise<void>
}

export default class SessionHistory implements HasSessionHistory {
  initialSession: SessionHistoryStoreItem[] = [{ text: "Home", href: "/" }]

  async saveHistory(payload: SessionHistoryStoreItem[]): Promise<void> {
    await store.dispatch("sessionHistory/saveHistory", payload)
  }

  async setRoute(at: number, current: SessionHistoryStoreItem): Promise<void> {
    let storeSession = [...store.getters["sessionHistory/getHistory"]]

    const history = storeSession.slice(0, at)
    storeSession = [...history, current]

    await this.saveHistory([...storeSession])
  }

  async pushRoute(current: SessionHistoryStoreItem): Promise<void> {
    const storeSession = [...store.getters["sessionHistory/getHistory"]]
    storeSession.push(current)

    await this.saveHistory([...storeSession])
  }

  async setHome(): Promise<void> {
    await this.saveHistory([...this.initialSession])
  }

  async changeRoute(at: number | string, current: Partial<SessionHistoryStoreItem>): Promise<void> {
    const session: SessionHistoryStoreItem[] = JSON.parse(
      JSON.stringify(store.getters["sessionHistory/getHistory"])
    )

    let item
    if (at.constructor.name == "String")
      item = session.find((s: SessionHistoryStoreItem) => s.name === at)
    else item = session[at as number]

    if (item) {
      item = Object.assign(item, current)
      await this.saveHistory([...session])
    }
  }
}
