import { Module, GetterTree, ActionTree, MutationTree } from "vuex"
import { RootState } from "../index"
import { UserAdvancedFragmentFragment } from "@/gql"
import { SESSION_USER_KEY, SESSION_TOKEN_KEY, APPLICANT_KEY } from "@/constants"
import { ApolloProvider } from "vue-apollo"
import EventBus from "@/components/EventBus"

const namespaced = true

export const state: AuthState = {
  user: undefined,
  token: undefined,
}

export const actions: ActionTree<AuthState, RootState> = {
  startSession({ commit }, payload: StartSessionPayload) {
    commit("startSession", payload)
  },

  endSession({ commit }) {
    commit("endSession")
  },

  updateSession({ commit }, payload: UpdateSessionPayload) {
    commit("updateSession", payload)
  },

  restoreSession({ commit }) {
    commit("restoreSession")
  },
}

const mutations: MutationTree<AuthState> = {
  // restores stored user and token from from localstorage
  restoreSession(state) {
    const userJson = localStorage.getItem(SESSION_USER_KEY)
    const token = localStorage.getItem(SESSION_TOKEN_KEY)

    if (userJson && token) {
      state.user = JSON.parse(userJson)
      state.token = token
    }
  },

  startSession(state, payload: StartSessionPayload) {
    const userJson = JSON.stringify(payload.user)
    localStorage.setItem(SESSION_USER_KEY, userJson)
    localStorage.setItem(SESSION_TOKEN_KEY, payload.token)
    payload?.apolloProvider?.defaultClient.resetStore()
    localStorage.removeItem(APPLICANT_KEY)
    state.user = payload.user
    state.token = payload.token
  },

  endSession(state) {
    state.user = undefined
    state.token = undefined

    localStorage.clear()
    EventBus.$emit("logout")
  },

  updateSession(state, payload: UpdateSessionPayload) {
    state.user = Object.assign(state.user!, {
      ...payload.user,
    })

    // update localstorage
    const userJson = JSON.stringify(state.user)
    localStorage.setItem(SESSION_USER_KEY, userJson)
  },
}

const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn(state) {
    return !!state.user
  },
  getCurrentUser(state) {
    return state.user
  },
  getSessionToken(state) {
    return state.token
  },
}

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
}

// types

export type StartSessionPayload = {
  user: UserAdvancedFragmentFragment
  token: string
  apolloProvider?: ApolloProvider
}

export interface AuthState {
  user?: UserAdvancedFragmentFragment
  token?: string
}

export interface UpdateSessionPayload {
  user: Partial<UserAdvancedFragmentFragment>
}
