
import { Component, Prop, Vue, Emit } from "vue-property-decorator"

// Todo.
// Some refactoring of this component is needed. Especially on the chip display logic
// Support key value pairs
// Support single selection

@Component
export default class TwoStepSelect extends Vue {
  @Prop({ required: true }) value!: TwoStepSelection[]
  @Prop({ required: true }) primaryItems!: string[]
  @Prop({ required: true }) secondaryItems!: string[]
  @Prop({ required: true }) secondaryLabel!: string
  @Prop({ default: "la-user-tag" }) prependIcon!: string
  @Prop() dialogHeader?: string

  primary = ""
  secondary = ""
  showDialog = false

  get primaryItemsList() {
    // this is all kinds of hackery
    const items = [...this.primaryItems]
    for (const l of this.value) {
      const index = items.indexOf(l.primary)
      if (index >= 0) items[index] = this.selection2Label(l)
    }
    return items
  }

  get primaryItemsValue() {
    return this.value.map((l) => this.selection2Label(l))
  }

  selection2Label(selection: TwoStepSelection) {
    return `${selection.primary} ~ ${selection.secondary}`
  }

  label2Selection(label: string) {
    const parts = label.split("~")
    return {
      primary: parts[0] && parts[0].trim(),
      secondary: parts[1] && parts[1].trim(),
    }
  }

  removeItem(item: string) {
    const index = this.indexOf(item)
    if (index >= 0) {
      return this.value.splice(index, 1)
    }
  }

  @Emit("change")
  @Emit("input")
  removeItemFromChip(item: string) {
    this.removeItem(item)
    return this.value
  }

  @Emit("change")
  @Emit("input")
  removeItemFromDialog() {
    this.removeItem(this.primary)
    this.primary = ""
    this.secondary = ""
    this.showDialog = false
    return this.value
  }

  indexOf(item: string) {
    let index = -1
    for (const i in this.value) {
      const l = this.value[i]
      if (this.selection2Label(l) === item) {
        index = parseInt(i)
        break
      }
    }
    return index
  }

  openDialog(primary: string) {
    const index = this.indexOf(primary)
    const secondary = index >= 0 ? this.value[index].secondary : ""
    this.primary = primary
    this.secondary = secondary
    this.showDialog = true
  }

  @Emit("change")
  @Emit("input")
  addItem() {
    this.removeItem(this.primary)
    const value = [
      ...this.value,
      {
        primary: this.label2Selection(this.primary).primary,
        secondary: this.secondary,
      },
    ]
    this.primary = ""
    this.secondary = ""
    this.showDialog = false
    return value
  }
}

export type TwoStepSelection = {
  primary: string
  secondary: string
}
