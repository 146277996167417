import { can } from "@/mixins/Authorizer"
import Routes from "@/router/routes"
import { AuthState } from "@/store/auth"
import { PermissionEnum } from "@/gql"

const useCoreRoutes = (user: AuthState["user"]): any[] => {
  return [
    {
      path: "bizdev/clients",
      name: Routes.Clients,
      component: () =>
        import(/* webpackChunkName: "bizdev-clients" */ "../views/bizdev/Clients.vue"),
      meta: {
        navigatesTo: "Clients",
      },
    },
    {
      path: "bizdev/client/:id",
      name: Routes.ClientDetail,
      component: () =>
        import(/* webpackChunkName: "bizdev-client/:id" */ "../views/bizdev/ClientDetail.vue"),
    },
    {
      path: "mission-dashboard",
      name: Routes.MissionDashboard,
      component: () => import(/* webpackChunkName: "missions" */ "../views/missions/Dashboard.vue"),
      meta: {
        navigatesTo: "Mission Dashboard",
      },
    },
    {
      path: "missions",
      name: Routes.Missions,
      component: () => import(/* webpackChunkName: "missions" */ "../views/missions/Missions.vue"),
      meta: {
        navigatesTo: "Missions",
      },
    },
    {
      path: "surveys",
      name: Routes.Surveys,
      component: () => import(/* webpackChunkName: "surveys" */ "../views/Surveys.vue"),
      meta: {
        navigatesTo: "Surveys",
      },
      children: [
        {
          path: "view/:id",
        },
      ],
    },
    {
      path: "invoices",
      name: Routes.Invoices,
      component: () => import(/* webpackChunkName: "list-invoices" */ "../views/Invoices.vue"),
      meta: {
        navigatesTo: "Invoices",
      },
    },
    {
      path: "invoices/:id",
      name: Routes.InvoiceDetail,
      component: () =>
        import(/* webpackChunkName: "invoice-detail" */ "../views/InvoiceDetail.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "missions/:id",
      name: Routes.MissionDetail,
      component: { render: (h: any) => h("router-view") },
      children: [
        {
          path: "",
          name: Routes.MissionDetail,
          component: () =>
            import(
              /* webpackChunkName: "mission-detail" */ "../views/missions/MissionDetail/MissionDetail.vue"
            ),
        },
        {
          path: "conversation",
          name: Routes.MissionDetailConversation,
          component: () =>
            import(
              /* webpackChunkName: "client-project-details" */ "../views/missions/MissionDetail/MissionDetail.vue"
            ),
        },
      ],
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "consultants/:id",
      name: Routes.ConsultantBasic,
      component: () =>
        import(/* webpackChunkName: "consultant-basic" */ "../views/consultant/BasicProfile.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "scope-library",
      component: { render: (h: any) => h("router-view") },
      children: [
        {
          path: "",
          name: Routes.ScopeLibrary,
          component: () =>
            import(
              /* webpackChunkName: "scope-library" */ "../views/ScopeLibrary/ScopeLibrary.vue"
            ),
          meta: {
            navigatesTo: "Scope Library",
          },
        },
        {
          path: "tags",
          name: Routes.ScopeLibraryTags,
          component: () =>
            import(/* webpackChunkName: "scope-library-tags" */ "../views/ScopeLibrary/Tags.vue"),
          meta: {
            navigatesTo: "Manage Tags",
          },
        },
        {
          path: "tree",
          name: Routes.ScopeLibraryTreeView,
          component: () => import("../views/ScopeLibrary/TreeView.vue"),
          meta: {
            navigatesTo: "Scope Library",
          },
        },
      ],
    },
    can(user, [PermissionEnum.ViewConsultantContracts]) && {
      path: "contracts/admin/:id",
      name: Routes.ConsultantContractAdmin,
      component: () => import("../views/contracts/ConsultantContractAdmin.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "ahead-admin",
      name: Routes.Ahead,
      component: () => import(/* webpackChunkName: "ahead" */ "../views/Ahead.vue"),
      meta: {
        navigatesTo: true,
        fullScreen: true,
      },
    },
  ]
}

export default useCoreRoutes
