
import { Namespaces } from "@/constants"
import { Action, State } from "vuex-class"
import { Component, Vue } from "vue-property-decorator"
import { SessionHistoryStoreItem } from "@/store/routes"
import UserAvatarGroup from "../widgets/common/UserAvatarGroup.vue"
import { useAccountContext } from "@/hooks/useAccountContext"
import {
  ProductSlugEnum,
  ResendEmailConfirmationMutation,
  ResendEmailConfirmationMutationMutation,
} from "@/gql"
import UserAvatar from "@/components/profile/UserAvatar.vue"
import Icon from "@/components/widgets/icons/Icon.vue"

const namespace = Namespaces.Auth

@Component({
  components: {
    UserAvatarGroup,
    UserAvatar,
    Icon,
  },
})
export default class AppHeader extends Vue {
  @Action("endSession", { namespace }) endSession!: () => void
  @Action("clearAll") clearAllStore!: () => void

  @State("history", { namespace: Namespaces.SessionHistory })
  sessionItems!: SessionHistoryStoreItem[]

  showMenu = false

  account = useAccountContext()

  get isAheadClient() {
    return this.account.clientProduct === ProductSlugEnum.AheadBusiness
  }

  get avatar() {
    return this.currentUser?.avatar
  }

  showUserAvatar = Boolean(this.avatar)
  sendingEmail = false

  async onResendEmailConfirmation() {
    this.sendingEmail = true

    const result = await this.mutate<ResendEmailConfirmationMutationMutation>({
      mutation: ResendEmailConfirmationMutation,
      done: () => {
        this.sendingEmail = false
      },
    })

    if (!result.data?.resendEmailConfirmation.error) {
      this.addSuccess("Email confirmation sent")
    }
  }

  onAvatarError() {
    this.showUserAvatar = false
  }

  logout() {
    this.clearAllStore()
    this.endSession()
    this.addSuccess("You've been logged out successfully")
    this.$router.push("/")
  }
}
