
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"

@Component
export default class FormDialog extends Vue {
  @Prop() readonly label!: string
  @Prop() readonly subLabel?: string
  @Prop({ default: "" }) readonly state?: string
  @Prop() readonly value!: boolean
  @Prop() readonly hideTools?: boolean
  @Prop() readonly sections?: string[]
  @Prop() readonly defaultSection?: string
  @Prop() readonly backTo?: string

  saved = false

  section: string | null = null
  next = "Next"
  back = "Back"

  @Emit("input")
  @Emit("close")
  onClose() {
    return false
  }

  save() {
    this.saved = true
    window.setTimeout(() => {
      this.saved = false
    }, 4000)
  }

  onChangePage(direction: string) {
    if (!this.sections) return

    const pages = this.sections,
      index = pages.findIndex((p) => p === this.section)!

    if (direction === "right") {
      if (index === this.sections.length - 1) {
        this.onClose()
        return
      } else this.section = pages[index + 1]
    } else {
      if (index === 0) {
        this.onClose()
        return
      } else this.section = pages[index - 1]
    }

    this.next = index === pages.length - 1 ? "Close" : "Next"
  }

  @Watch("page")
  onPageChange() {
    this.changePage(this.section)
  }

  @Emit("change")
  changePage(section: string | null) {
    return section
  }

  @Watch("value")
  onValueChange() {
    if (this.sections?.length) {
      this.section = this.defaultSection || this.sections[0]
    }
  }

  @Watch("defaultSection")
  ondefaultSectionChange() {
    this.section = this.defaultSection!
  }
}
