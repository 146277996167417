import { ACCOUNT_ASPECT_KEY } from "@/constants"
import { Client, ProductSlugEnum, UserAdvancedFragmentFragment, PermissionEnum } from "@/gql"
import { can } from "@/mixins/Authorizer"
import { useAuth } from "@/store/auth/useAuth"

export type UseAccountContextValue = {
  isConsultant: boolean
  isCoreTeam: boolean
  isTalentTeam: boolean
  isAdmin: boolean
  isClient: boolean
  isStaff: boolean
  availableAspects: string[]
  availableClientAspects: string[]
  setAccountAspect: (aspect: string) => void
  clientProduct: ProductSlugEnum | null
  client: Client | undefined
}

const useAccountContext = (): UseAccountContextValue => {
  const { user } = useAuth()

  return {
    isConsultant: can(user, [PermissionEnum.Foresighter]),
    isCoreTeam: can(user, [PermissionEnum.CoreTeam]),
    isStaff: can(user, [PermissionEnum.CoreTeam]),
    isTalentTeam: can(user, [PermissionEnum.TalentTeam]),
    isAdmin: can(user, [PermissionEnum.Admin]),
    isClient: !!user?.clientsAsAdmin?.length,
    availableAspects: getAvailableAspects(user),
    availableClientAspects: getAvailableClientAspects(user),
    setAccountAspect,
    clientProduct: getClientProduct(user),
    client: getAvailableClient(user),
  }
}

function getAvailableAspects(user?: UserAdvancedFragmentFragment): string[] {
  const aspects = [
    PermissionEnum.TalentTeam,
    PermissionEnum.FinanceTeam,
    PermissionEnum.BizdevTeam,
    PermissionEnum.CoreTeam,
  ]
  const availableAspects = aspects.filter((aspect) => can(user, [aspect]))
  return availableAspects
}

function getAvailableClientAspects(user?: UserAdvancedFragmentFragment): string[] {
  return user?.clientsAsAdmin?.map((c) => `Client:${c.id}`) || []
}

function getAvailableClient(user?: UserAdvancedFragmentFragment): Client | undefined {
  return user?.clientsAsAdmin?.[0] as Client
}

function getClientProduct(user?: UserAdvancedFragmentFragment): ProductSlugEnum | null {
  return user?.clientsAsAdmin?.[0]?.productSlug || null
}

// Stores the current aspect in local storage
// The current aspect is used to determine which aspect of the app to show
function setAccountAspect(aspect: string): void {
  localStorage.setItem(ACCOUNT_ASPECT_KEY, aspect)
}

export { useAccountContext }
