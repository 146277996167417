
import { Component, Prop, Vue } from "vue-property-decorator"
import CommentableActivator from "./CommentableActivator.vue"

@Component({
  components: {
    CommentableActivator,
  },
})
export default class CommentableDocument extends Vue {
  @Prop({ required: true }) readonly blockMetaData!: any
  @Prop({ required: false, default: 150 }) readonly contentOffset!: number | string
  contentAlignment = "center"
}
